import {CategoriesService, Service} from "./service.types";

export const filterServiceCategory = (service: Service, category: string): boolean => {
    return service.category ? service.category.title === category : !category;
}

export const buildCategory = (services: Service[]): CategoriesService => {
    const obj: CategoriesService = {};
    const keys = new Set(services.map(s => s.category?.title || ''));
    keys.forEach((k) => {
        const categoryServices = services.filter(fs => filterServiceCategory(fs, k));
        const minValue = (k: keyof Service) => {
            let minPositiveValue = null;
            for (const service of categoryServices) {
                const value = service[k] as number;
                if (value > 0 && (minPositiveValue === null || value < minPositiveValue)) {
                    minPositiveValue = value;
                }
            }
            return minPositiveValue === null ? 0 : minPositiveValue;
        }
        obj[k] = {
            services: categoryServices,
            maxDuration: Math.max(...categoryServices.map(s => s.duration)),
            minDuration: minValue('duration'),
            minPrice: minValue('price'),
            description: categoryServices[0]?.category?.description
        }
    })
    return obj
}