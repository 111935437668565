import React, {createContext, FC, ReactNode, useContext, useState} from 'react';
import {Button, ContainerSize, Dialog, getTypo} from '@linkeo.com/ui-lib-react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

export interface DialogConfig {
  cancelBtnText?: string;
  confirmBtnText?: string;
  /**
   * content is recommended to be static, can be dynamic if not depending of cancel or confirm button
   */
  content: string | ReactNode,
  size?: ContainerSize
  title: string,
}

interface ConfirmContextData {
  openConfirm: (config: DialogConfig) => Promise<void>;
}

const ConfirmContext = createContext<ConfirmContextData>({
  openConfirm: () => Promise.reject(new Error('not initialized')),
});
export const useConfirm = () => useContext(ConfirmContext);
const DefaultContainerDialog = styled.div`
  ${getTypo('body1')};
`;
export const ConfirmProvider: FC = props => {
  const {children} = props;
  const [getDialog, setDialog] = useState<ReactNode | null>();
  const intl = useIntl();
  const open = (config: DialogConfig): Promise<void> => {
    return new Promise((resolve, reject) => {
      const emitClose = (success?: boolean) => {
        setDialog(null);
        if (success) {
          resolve();
        } else {
          reject(new Error('Failed to close modal'))
        }
      };
      const footer = <>
        <Button colorType={'secondary'}
                onClick={() => emitClose()}>{config.cancelBtnText || intl.formatMessage({id : 'ConfirmProviderCancelOption', defaultMessage: 'Annuler'})}</Button>
        <Button colorType={'secondary'}
                onClick={() => emitClose(true)}>{config.confirmBtnText || 'ok'}</Button>
      </>;
      setDialog(
        <Dialog onClose={() => emitClose()} show={true} title={config.title} size={config.size || 'md'} footer={footer}>
          <DefaultContainerDialog>
            {config.content}
          </DefaultContainerDialog>
        </Dialog>,
      );
    });
  };

  return <ConfirmContext.Provider
    value={{openConfirm: open}}>
    {children}
    {getDialog ? getDialog : null}
  </ConfirmContext.Provider>;
};