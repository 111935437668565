import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { H6, Svg } from '@linkeo.com/ui-lib-react';
import { useTheme } from 'styled-components';
import { Loader } from '../common/components/loader';
import { Configuration, oneYear, legacy } from './configuration.types';
import { getAppConfiguration, getVersionConfiguration } from './configuration-api';
import { FormattedMessage } from 'react-intl';
import { ConfigurationAppointmentsDisabled } from "./configuration-appointments-disabled";
import { plannerLegacyBaseUri } from "../common/config/uri-api";
import { SupportedLanguage } from '../common/providers/intl.provider';
import { matchPath, useLocation } from "react-router-dom";
import { routeGiftCard } from "../routes";

export interface ConfigurationContextProps extends Configuration {
}

const defaultConfiguration: ConfigurationContextProps = {
    name: '', phone: '', website: '',
    address: { address: [''], city: '', country: '', postalCode: '' },
    currency: '',
    email: '',
    mobile: '',
    appointment: {
        active: false,
        allowAnonymous: false,
        allowSelectAgenda: false,
        delayBetweenAppointments: 0,
        intervalCalendar: 0,
        labelButton: '',
        labelSelectAgenda: '',
        waitingList: false,
        paymentActive: false
    },
    giftcard: {
        active: true,
        giftcardValidity: oneYear
    },
    publicKey: '',
    timezone: '',
    showHeader: true,
    paymentPercent: 25
};

const ConfigurationContext = createContext<ConfigurationContextProps>(defaultConfiguration);
export const useConfigurationStore = () => useContext(ConfigurationContext);

export interface ConfigurationStoreProps {
    codeBouton: string;
    locale: SupportedLanguage;
    redirectLegacy: boolean;
    showHeader: boolean
}

export const ConfigurationStore: FC<ConfigurationStoreProps> = ({ redirectLegacy, children, codeBouton, locale, showHeader }) => {
    const [getData, setData] = useState<ConfigurationContextProps>();
    const [error, setError] = useState<any>();
    const [getLoading, setLoading] = useState<boolean>(true);
    const location = useLocation();
    const theme = useTheme();

    useEffect(() => {
        getVersionConfiguration(codeBouton)
            .then(resp => {
                if (resp.versionCode === legacy && redirectLegacy) {
                    if (matchPath(routeGiftCard, location.pathname)) {
                        window.location.replace(`${plannerLegacyBaseUri}/giftcard/giftcards/?key=${resp.params.key}&c=${resp.params.codeBouton}&lang=${locale}`)
                    } else {
                        window.location.replace(`${plannerLegacyBaseUri}/?key=${resp.params.key}&c=${resp.params.codeBouton}&lang=${locale}`)
                    }
                    return;
                } else {
                    getAppConfiguration(codeBouton)
                        .then(async (response) => {
                            setData({ ...response, showHeader })
                        })
                        .catch(() => setError(true))
                        .finally(() => setLoading(false));
                }
            })
            .catch(() => setError(true))
    }, [codeBouton, locale, location.pathname, redirectLegacy, showHeader]);

    let content: ReactNode;
    if (getData && !getData?.appointment.active) {
        content = <ConfigurationAppointmentsDisabled
            phone={getData.phone}
            name={getData.name}
            address={getData.address}
            coverImageUri={getData.coverImageFile?.url ?? ''}
            logoUri={getData.logoFile?.url ?? ''}
            website={getData.website}
        />
    } else {
        content = <ConfigurationContext.Provider
            value={getData || defaultConfiguration}>
            {children}
        </ConfigurationContext.Provider>
    }

    return !error ? <Loader loading={getLoading}>
        {content}
    </Loader> : <div style={{ textAlign: 'center', paddingTop: '50px' }}>
        <div>
            <Svg icon={"calendar-outline"} fill={theme.colors.grey30} />
        </div>
        <H6>
            <FormattedMessage id='configurationProviderErrorTitle'
                defaultMessage='La prise de rendez-vous en ligne a été temporairement désactivée.' />
        </H6>
    </div>;
};
