import React, {FC} from "react";
import {Col, Row, SpanBody2, SpanSubtitle1, SpanSubtitle2} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";

interface Choice {
    id: string;
    label: string;
    price?: number;
    quantity?: number;
    total?: number;
}

interface OptionsListProps {
    name: string;
    choices: Choice[];
    currency: string;
    countable: boolean;
    index?: number
}

export const OptionsList: FC<OptionsListProps> = ({
                                                      name,
                                                      choices,
                                                      currency,
                                                      countable,
                                                      index
                                                  }) => {
    const intl = useIntl();

    const content = choices.filter(choice => choice.quantity && choice.quantity > 0).map((choice, index) => {
        return <div key={index}>
            {countable ? <SpanSubtitle2>{choice.quantity}x </SpanSubtitle2> : null}
            <SpanSubtitle1 style={{marginRight: '12px'}}>
                {choice.label + (choice.total !== 0 ? ` (+${intl.formatNumber((choice.total || 0), {
                    style: 'currency',
                    currency
                })})` : '')}</SpanSubtitle1>
        </div>
    })

    return choices.filter(choice => choice.quantity && choice.quantity > 0).length > 0 ? <Row style={{
        borderTop: index === 0 ? undefined : '1px solid #E2E6E9',
        width: '94%',
        margin: '0 26px',
        padding: '12px 0'
    }}>
        <Col columns={[12]} style={{padding: 0}}>
            <div><SpanBody2>{name}</SpanBody2></div>
            <Row style={{margin: '6px 0'}}>
                {content}
            </Row>
        </Col>
    </Row> : null
}
