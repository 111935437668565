import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {PageTemplate} from "../../common/components/page-template"
import {postValidateGiftCard} from "../gift-card-api"
import {generatePath, useNavigate} from "react-router";
import {AuthenticationPage} from "../../customer/authentification/pages/authentication-page";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {routeGiftCardSummary} from "../../routes";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {useIntl} from "react-intl";

export const GiftCardPaymentValidation = () => {
    const [paymentParams, setPaymentParams] = useState<{ payment_intent: string | null, payment_intent_client_secret: string | null }>();
    const configuration = useConfigurationStore();
    const navigate = useNavigate()
    const {authData} = useAuthStore();
    const codeBouton = useCodeBouton();
    const intl = useIntl();
    useEffect(() => {
        const search = new URLSearchParams(window.location.href)
        const payment_intent = search.get('payment_intent')
        const payment_intent_client_secret = search.get('payment_intent_client_secret')
        setPaymentParams({payment_intent, payment_intent_client_secret})
    }, [])
    const {giftCardId} = useParams<{ giftCardId: string }>()

    useEffect(() => {
        if (!giftCardId && !paymentParams) {
            return;
        }
        postValidateGiftCard().then(() => navigate(generatePath(routeGiftCardSummary,{giftCardId,codeBouton,locale:intl.locale})))
    }, [giftCardId, paymentParams, navigate, codeBouton, intl.locale])

    if (!authData) {
        return <AuthenticationPage/>
    }

    return <PageTemplate configuration={configuration} isLoading={true} codeBouton={codeBouton}/>
}
