import {
    Button,
    Card,
    Col,
    Flex,
    Input,
    PageTitle,
    Row,
    SpanBody1,
    SpanBody2,
    SpanCaption1,
    SpanCaption2,
    SpanSubtitle1,
    SpanSubtitle2
} from "@linkeo.com/ui-lib-react"
import {addDays} from "date-fns"
import React, {FC, FormEvent} from "react"
import {FormattedMessage, useIntl} from 'react-intl';
import {PriceAndTime} from "../../common/components/price-and-time"

import {useTheme} from "styled-components"
import {GiftCardPaymentSection} from "./gift-card-payment-section";
import {Option} from "../gift-card.types";
import {GiftcardChoicesList} from "./giftcard-choices-list";

const formGiftCardId = 'formGiftCard'

const threeMonths = '3 month'
const sixMonths = '6 month'
const oneYear = '1 year'

type GiftCardValidity = typeof threeMonths | typeof sixMonths | typeof oneYear

const getDaysValidity = (giftCardValidity: GiftCardValidity): number => {
    switch (giftCardValidity) {
        case threeMonths:
            return 90
        case sixMonths:
            return 180
        case oneYear:
            return 365
        default:
            throw new Error('La validité de Gift Card n’est pas connue')
    }
}

interface GiftCardFormProps {
    readOnly: boolean;
    onSubmitFormGiftCard: (e: FormEvent) => void;
    onChangeGiftedTo: (value: string) => void;
    onChangeEmail: (value: string) => void;
    onChangeMessage: (value: string) => void;
    giftedTo: string;
    message: string;
    email: string;
}

const GiftCardForm: FC<GiftCardFormProps> = ({
                                                 readOnly,
                                                 onSubmitFormGiftCard,
                                                 onChangeGiftedTo,
                                                 onChangeEmail,
                                                 onChangeMessage,
                                                 giftedTo,
                                                 message,
                                                 email
                                             }) => {

    const intl = useIntl();

    return <form id={formGiftCardId} onSubmit={onSubmitFormGiftCard}>
        <Input readOnly={readOnly}
               label={intl.formatMessage({id: 'giftCardRecipentNameLabel', defaultMessage: 'Nom du bénéficiaire : '})}
               required
               value={giftedTo}
               onChange={(giftedTo) => onChangeGiftedTo(giftedTo)}/>
        {/* <Checkbox value={sendToRecipient} onChange={()=> dispatchGiftCard({TOGGLE_SEND_TO_RECIPIENT:true})}>Envoyer au bénéficiaire</Checkbox> */}
        {/*<Input readOnly={readOnly} type={'email'}
               label={intl.formatMessage({
                   id: 'giftCardRecipentEmailLabel',
                   defaultMessage: 'Email du bénéficiaire : '
               })} required value={email}
               onChange={(email) => onChangeEmail(email)}/>
        <Textarea readOnly={readOnly} label={'Votre message : '}
                  value={message}
                  onChange={(message) => onChangeMessage(message)}></Textarea>*/}
    </form>
}


interface GiftCardValidationSectionProps {
    giftCardPaymentExists: boolean;
    currency: string;
    isLoading: boolean;
    onChangeGiftedTo: (value: string) => void;
    onChangeEmail: (value: string) => void;
    onChangeMessage: (value: string) => void;
    onSubmitFormGiftCard: (e: FormEvent) => void;
    giftcardValidity: GiftCardValidity,
    publicKey: string;
    options: Option[];
    clientSecret?: string;
    amount?: number;
    giftCardId?: string;
    duration: number;
    price: number;
    title: string;
    giftedTo: string;
    message: string;
    email: string;
    codeBouton: string;
}

export const GiftCardValidationSection: FC<GiftCardValidationSectionProps> = ({
                                                                                  giftcardValidity,
                                                                                  giftCardPaymentExists,
                                                                                  currency,
                                                                                  onChangeGiftedTo,
                                                                                  onChangeEmail,
                                                                                  onChangeMessage,
                                                                                  onSubmitFormGiftCard,
                                                                                  publicKey,
                                                                                  isLoading,
                                                                                  clientSecret,
                                                                                  amount,
                                                                                  giftCardId,
                                                                                  duration,
                                                                                  email,
                                                                                  price,
                                                                                  title,
                                                                                  giftedTo,
                                                                                  message,
                                                                                  codeBouton,
                                                                                  options
                                                                              }) => {

    const {colors} = useTheme();
    const intl = useIntl();

    return <>
        <PageTitle style={{margin: '64px 0 0 0'}}><FormattedMessage id='GiftCardConfirmationTitle2'
                                                                    defaultMessage='3. Votre panier'/></PageTitle>
        <Card style={{paddingTop: 24, paddingBottom: 24, margin: '24px 0'}}>
            <Row>
                <Col columns={[12, 12, 6, 6]}>
                    <SpanBody2><FormattedMessage id='GiftCardDisplayPageTitle' defaultMessage='Bon cadeau'/></SpanBody2><br/>
                    <SpanBody1 style={{color: colors.grey60}}>
                        <FormattedMessage id='giftcardListingValidityTitle' defaultMessage='Valable jusqu’au {date}'
                                          values={{date: intl.formatDate(addDays(new Date(), getDaysValidity(giftcardValidity)), {dateStyle: "full"})}}/>
                    </SpanBody1>
                </Col>
                <Col columns={[12, 12, 6, 6]} style={{marginTop: -18}}>
                    <GiftCardForm
                        readOnly={giftCardPaymentExists}
                        onSubmitFormGiftCard={onSubmitFormGiftCard}
                        onChangeGiftedTo={onChangeGiftedTo}
                        onChangeEmail={onChangeEmail}
                        onChangeMessage={onChangeMessage}
                        giftedTo={giftedTo}
                        message={message}
                        email={email}
                    />
                </Col>
            </Row>
            <Row>
                <Col columns={[24, 24, 12, 12]}>
                    <div style={{margin: '16px 0'}}>
                        <SpanBody2>
                            <FormattedMessage id='giftCardConfirmationPageBookingSummary' defaultMessage='Prestation'/>
                        </SpanBody2>
                    </div>

                    <Row gapColumn={[8]} alignItems={['center']} justifyContent={['space-between']}>
                        <PriceAndTime
                            duration={duration}
                            currency={currency}
                            price={amount || price}>
                            <SpanSubtitle2>{title}</SpanSubtitle2>
                        </PriceAndTime>
                    </Row>

                    <Row style={{marginTop: '6px'}}>
                        <Col columns={[12]}>
                            {options.map((option, i) => {
                                return <React.Fragment key={i}>
                                    {option.choices.filter(e => e.quantity && e.quantity > 0).length ? <>
                                        <div>
                                            <SpanCaption2 style={{fontStyle: 'initial', color: colors.grey50}}>
                                                {option.name} (x{option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0)})
                                            </SpanCaption2>
                                        </div>
                                        <Row style={{margin: '6px 0'}}>
                                            <GiftcardChoicesList currency={currency} choices={option.choices}/>
                                            <SpanCaption1 style={{
                                                fontStyle: 'initial',
                                                color: colors.grey50,
                                                marginRight: '10px'
                                            }}>{option.maxFree && option.choices.filter(e => e.quantity && e.quantity > 0).length > option.maxFree && option.choices.filter(e => e.total && e.total > 0).length === 0
                                                ? `(+${intl.formatNumber((option.total || 0), {
                                                    style: 'currency',
                                                    currency
                                                })})` : null}</SpanCaption1>
                                        </Row></> : null}
                                </React.Fragment>
                            })}
                        </Col>
                    </Row>

                </Col>

            </Row>
        </Card>

        <SpanSubtitle1 style={{color: colors.grey60}}>
            <div><FormattedMessage id='giftCardConfirmationPageDownloadingGCLabel'
                                   defaultMessage='Vous pourrez télécharger votre bon cadeau dès que votre paiement sera validé.'/>
            </div>
            <FormattedMessage id='giftCardConfirmationPageSendingGCLabel'
                              defaultMessage='Vous le recevrez également par Email à l’adresse renseignée et vous pourrez le retrouver dans votre espace client.'/></SpanSubtitle1>

        {!giftCardPaymentExists ?
            <Flex justifyContent={['center']}>
                <Button type={'submit'}
                        form={formGiftCardId}
                        style={{margin: ' 24px 0 16px'}}
                        colorType={"primary"}
                        loading={isLoading}>
                    <FormattedMessage
                        id='accountEditUpdateLabel'
                        defaultMessage='Valider'/>
                </Button>
            </Flex>
            : <GiftCardPaymentSection
                clientSecret={clientSecret}
                amount={amount}
                currency={currency}
                giftCardId={giftCardId}
                publicKey={publicKey}
                codeBouton={codeBouton}/>}
    </>
}
