import {BaseButton, Button, ColorType, Input, SpanBody2, SpanSubtitle1, useToaster} from "@linkeo.com/ui-lib-react";
import {FC, useState} from "react";
import {CardLoading} from "../../../common/components/card-loading";
import {FormattedMessage, useIntl} from "react-intl";
import {AccountCard} from "./common";

const wait = (ms: number) => new Promise<void>((resolve) => setTimeout(() => resolve(), ms));

type FormValidator<T> = {
    [k in keyof Partial<T>]: (field: T[k], form: T) => string | null
}

type FormState<T> = {
    [k in keyof Partial<T>]: string | null
}

export type AccountFormProps = {
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
}

const ReadOnlyAccountCard: FC<{ account: AccountFormProps }> = ({account}) => {
    return <>
        <div style={{marginTop: '16px'}}><SpanSubtitle1>{account.firstName}</SpanSubtitle1></div>
        <div><SpanSubtitle1>{account.lastName}</SpanSubtitle1></div>
        <SpanSubtitle1>{account.email}</SpanSubtitle1>
        <div><SpanSubtitle1>{account.phone}</SpanSubtitle1></div>
    </>
}

interface EditableAccountCardProps {
    account: AccountFormProps,
    onUpdate?: (account: AccountFormProps) => void;
    onCancel?: () => void;
    validator?: FormValidator<AccountFormProps>;
    loading: boolean,
    validateButtonColor: ColorType;
    cancelButtonColor: ColorType;
    labels: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        cancel: string,
        update: string
    }
}

const AccountForm: FC<EditableAccountCardProps> = ({
                                                       account: accountProp,
                                                       onCancel,
                                                       onUpdate,
                                                       labels,
                                                       validator,
                                                       loading,
                                                       validateButtonColor,
                                                       cancelButtonColor

                                                   }) => {
    const [formState, setFormState] = useState<FormState<AccountFormProps>>({});
    const [account, setAccount] = useState<AccountFormProps>({...accountProp});
    const reset = () => {
        setAccount({...accountProp});
        if (onCancel) {
            onCancel();
        }
    }
    const updateProp = (propName: keyof AccountFormProps, value: AccountFormProps[typeof propName]) => {
        const v = validator && validator[propName];
        if (v) {
            setFormState({...formState, [propName]: v(value, {...account, [propName]: value})});
        }
        setAccount({...account, [propName]: value});
    }
    const done = () => {
        if (onUpdate) {
            onUpdate(account);
        }
    }
    return <form style={{width: '100%'}}>
        <Input required hasError={!!formState.firstName} label={labels.firstName} readOnly={loading}
               onChange={(value) => updateProp('firstName', value)}
               value={account.firstName}/>
        <Input required hasError={!!formState.lastName} label={labels.lastName} readOnly={loading}
               onChange={(value) => updateProp('lastName', value)}
               value={account.lastName}/>
        <Input required hasError={!!formState.email} type={"email"} icon={'email-outline'} iconPosition={'right'}
               label={labels.email} readOnly={loading}
               onChange={(value) => updateProp('email', value)}
               value={account.email}/>
        <Input required hasError={!!formState.phone} label={labels.phone} icon={'smartphone-outline'}
               iconPosition={'right'} readOnly={loading}
               onChange={(value) => updateProp('phone', value)}
               value={account.phone}/>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
            <Button style={{margin: '0 5px'}} colorType={cancelButtonColor} onClick={() => reset()}
                    disabled={loading}>{labels.cancel}</Button>
            <Button style={{margin: '0 5px'}} colorType={validateButtonColor} onClick={() => done()}
                    loading={loading}>{labels.update}</Button>
        </div>
    </form>
}


interface MyAccountEditCardProps extends Omit<EditableAccountCardProps, 'onUpdate' | 'loading'> {
    onUpdate?: (account: AccountFormProps) => Promise<void>
}

const MyAccountEditCard: FC<MyAccountEditCardProps> = ({
                                                           account,
                                                           onUpdate: onUpdateProps,
                                                           onCancel: onCancelProps,
                                                           ...props
                                                       }) => {
    const intl = useIntl();
    const [readonly, setReadOnly] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const onUpdate = (account: AccountFormProps) => {
        setLoading(true);
        if (onUpdateProps) {
            onUpdateProps(account).finally(() => {
                setLoading(false);
                setReadOnly(true);
            });
        }
    };
    const onCancel = () => {
        setReadOnly(true);
        if (onCancelProps) {
            onCancelProps();
        }
    };
    return <AccountCard icon={"person-outline"} titleName={intl.formatMessage({
        id: 'accountEditFormPersonalInfoTitle',
        defaultMessage: "Informations personnelles"
    })}>
        {readonly ?
            <ReadOnlyAccountCard account={account}/> :
            <AccountForm
                loading={loading}
                account={account}
                onUpdate={onUpdate}
                onCancel={onCancel}
                {...props}
            />
        }
        {readonly ? <BaseButton onClick={() => setReadOnly(!readonly)}>
            <SpanBody2 style={{textDecorationLine: 'underline'}}><FormattedMessage
                id='AccountPageAccountEditButtonLabel'
                defaultMessage='Modifier mes informations'/></SpanBody2>
        </BaseButton> : null}
    </AccountCard>;
}


const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phoneRegex = /^\+?\d+$/
export const AccountEditForm: FC<{ account: AccountFormProps, onUpdate?: (account: AccountFormProps) => Promise<void>, onCancel?: () => void }> = ({
                                                                                                                                             account,
                                                                                                                                             onUpdate: onUpdateProp
                                                                                                                                         }) => {
    const intl = useIntl();
    const toast = useToaster()
    const onUpdate = async (account: AccountFormProps): Promise<void> => {
        const start = Date.now();
        let error: any;
        try {
            if (onUpdateProp) {
                await onUpdateProp(account);
            }
        } catch (e) {
            error = e;
        }
        //minimum 1 sec de chargement
        const done = Date.now();
        const time = done - start;
        if ((time) < 1000) {
            await wait(1000 - time);
        }
        if (error) {
            toast(intl.formatMessage({
                id: 'accountEditError',
                defaultMessage: 'Une erreur est survenue, merci de réessayer utlérieurement'
            }), {type: "error"}, 2000);
        }
    };
    const onCancel = () => {

    };
    return <>{
        account ? <MyAccountEditCard
            validateButtonColor={'primary'}
            cancelButtonColor={'outlineSecondary'}
            account={account}
            labels={{
                firstName: intl.formatMessage({
                    id: 'accountEditFirstNameLabel',
                    defaultMessage: "Prénom",
                    description: 'Intitulé du champs "Prénom" du formulaire'
                }),
                lastName: intl.formatMessage({
                    id: 'accountEditLastNameLabel',
                    defaultMessage: "Nom",
                    description: 'Intitulé du champs "Nom" du formulaire'
                }),
                email: intl.formatMessage({
                    id: 'accountEditEmailLabel',
                    defaultMessage: "E-mail",
                    description: 'Intitulé du champs "E-mail" du formulaire'
                }),
                phone: intl.formatMessage({
                    id: 'accountEditPhoneLabel',
                    defaultMessage: "Téléphone",
                    description: 'Intitulé du champs "Téléphone" du formulaire'
                }),
                cancel: intl.formatMessage({
                    id: 'accountEditCancelLabel',
                    defaultMessage: "Annuler",
                    description: 'Intitulé du champs "Annuler" du formulaire'
                }),
                update: intl.formatMessage({
                    id: 'accountEditUpdateLabel',
                    defaultMessage: "Valider",
                    description: 'Intitulé du champs "Valider" du formulaire'
                }),
            }}
            validator={{
                phone: (field) => {
                    return phoneRegex.test(field) ? null : intl.formatMessage({
                        id: 'accountEditInvalidPhone',
                        defaultMessage: "Merci de renseigner un numéro de téléphone valide"
                    });
                },
                email(field): string | null {
                    return emailRegex.test(field) ? null : intl.formatMessage({
                        id: 'accountEditInvalidMail',
                        defaultMessage: "Merci de renseigner un e-mail valide"
                    });
                },
                firstName(field): string | null {
                    return field.trim() === '' ? intl.formatMessage({
                        id: 'accountEditInvalidFirstName',
                        defaultMessage: "Merci de renseigner votre prénom"
                    }) : null;
                },
                lastName(field): string | null {
                    return field.trim() === '' ? intl.formatMessage({
                        id: 'accountEditInvalidLastName',
                        defaultMessage: "Merci de renseigner votre nom de famille"
                    }) : null;
                }
            }}
            onUpdate={onUpdate}
            onCancel={onCancel}
        /> : <CardLoading/>
    }</>;
}
