import {File} from '../common/commons.types';

export const legacy = 'legacy';
export const next = 'next';

interface Params {
    key: string;
    codeBouton: string
}

export interface VersionConfiguration {
    versionCode: typeof legacy | typeof next
    params: Params
}

export interface Address {
    address: [
        string
    ],
    postalCode: string,
    city: string,
    country: string
}

export interface Configuration {
    name: string;
    phone: string;
    logoFile?: File;
    coverImageFile?: File;
    website: string;
    email: string,
    address: Address,
    currency: string,
    mobile: string,
    appointment: AppointmentConfiguration,
    giftcard: GiftCardConfiguration,
    publicKey?: string,
    timezone: string|null,
    showHeader: boolean;
    paymentPercent: number;
}

export const threeMonths = '3 month'
export const sixMonths = '6 month'
export const oneYear = '1 year'

export type GiftCardValidity = typeof threeMonths | typeof sixMonths | typeof oneYear

export interface GiftCardConfiguration {
    active: boolean
    giftcardValidity: GiftCardValidity
}

export interface AppointmentConfiguration {
    active: boolean
    allowAnonymous: boolean
    allowSelectAgenda: boolean
    delayBetweenAppointments: number
    intervalCalendar: number
    labelButton: string
    labelSelectAgenda: string
    waitingList: boolean
    paymentActive: boolean
}

