import React, { FC, ReactElement } from "react";
import {ReactComponent as IconEUR} from "../../icons/EUR.svg"
import {ReactComponent as IconUSD} from "../../icons/USD.svg";
import {ReactComponent as IconAED} from "../../icons/AED.svg";
import {ReactComponent as IconMUR} from "../../icons/MUR.svg";
import {ReactComponent as IconDefault} from "../../icons/info-price.svg"
import { useTheme } from "styled-components";

const EUR = 'EUR';
const USD = 'USD';
const AED = 'AED';
const MUR = 'MUR';

export const InfoPrice: FC<{currency: string, style?: React.CSSProperties}> = ({currency, style}) => {
    const theme = useTheme();
    let result: ReactElement;
    
    if (currency === EUR) {
        result = <IconEUR style={style} fill={theme.colors.grey30}/>
    } else if (currency === USD || 'AUD' || 'CAD') {
        result = <IconUSD style={style}/>
    } else if (currency === AED) {
        result = <IconAED style={style}/>
    } else if (currency === MUR) {
        result = <IconMUR style={style}/>
    } else {
        result = <IconDefault style={style}/>
    }

    return result
}