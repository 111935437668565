import {BaseButton, SpanBody1, SpanBody2, SpanSubtitle1} from "@linkeo.com/ui-lib-react"
import {FC} from "react"
import {FormattedDate, FormattedNumber, useIntl} from "react-intl"
import styled from 'styled-components';
import {lighten} from "polished";
import {Discount} from "../../common/commons.types";

/**
 * this hack is for add padding too tbody
 */
const TableBody = styled.tbody`
  &:before {
    line-height: 32px;
    content: ".";
    color: transparent;
    display: block;
  }
`;

const TimeCellButton = styled(BaseButton) <{
    discount: boolean,
    active: boolean,
    waitingList: boolean,
    waitingListSpots?: number
}>`
  width: 85%;
  height: 50px;
  border: 1px solid ${({
                         discount,
                         theme,
                         active,
                         waitingList
                       }) => active ? lighten(0.30, theme.colors.primary) : waitingList ? theme.colors.danger : discount ? theme.colors.success : "transparent"};
  background: ${({
                   theme,
                   discount,
                   active,
                   waitingList
                 }) => active ? lighten(0.47, theme.colors.primary) : waitingList ? lighten(0.26, theme.colors.danger) : discount ? lighten(0.45, theme.colors.success) : 'transparent'};
  transition: ease-in-out all 200ms;
  border-radius: 3px;
  outline: none;

  &:hover, &:active, &:focus {
    border-color: ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => lighten(0.45, theme.colors.primary)};
  }
`;

const Time = styled(SpanBody1)<{waitingList: boolean|null}>`
  color: ${({theme, waitingList}) => waitingList && theme.colors.danger};
  font-weight: ${({waitingList}) => waitingList && 600};
`;

const WaitingListTag = styled(SpanSubtitle1)`
  padding: 0 5px;
  border-radius: 11px;
  color: ${({theme}) => theme.colors.light};
  border: 0.5px solid ${({theme}) => theme.colors.danger};
  background-color: ${({theme}) => theme.colors.danger};
  font-weight: 700;
`;

const DiscountTag = styled(SpanSubtitle1)<{waitingListSpots?: number}>`
  margin-right: ${({waitingListSpots}) => waitingListSpots !== undefined && '2px'};
  padding: ${({waitingListSpots}) => waitingListSpots !== undefined && '0 4px'};
  border-radius: 11px;
  color: ${({theme, waitingListSpots}) =>  waitingListSpots !== undefined ? theme.colors.light : theme.colors.success};
  border: ${({theme, waitingListSpots}) =>  waitingListSpots !== undefined && `1px solid ${theme.colors.success}`};
  background-color: ${({theme, waitingListSpots}) =>  waitingListSpots !== undefined && theme.colors.success};
  font-weight: 700;
`;

interface TimeSlot {
    date: string;
    discount?: Discount;
    waitingList: boolean | null;
    waitingListSpots?: number;
}

interface AvailabilitiesTableProps {
    headerDays: Date[];
    timeSlotAvailable: (TimeSlot | undefined)[][];
    selectedDate?: Date;
    currency: string;
    onSelectDate?: (date: string) => void;
    timezone: string | null
}

export const AvailabilitiesTable: FC<AvailabilitiesTableProps> = ({
                                                                      headerDays,
                                                                      timeSlotAvailable,
                                                                      selectedDate,
                                                                      currency,
                                                                      onSelectDate,
                                                                      timezone
                                                                  }) => {

    const {locale, ...intl} = useIntl();

    return <div style={{padding: '0 62px', position: 'relative', top: "-120px", zIndex: 1}}>
        <table width={"100%"}>
            <thead>
            <tr>
                {headerDays.map((day, i) => <th key={i} style={{textAlign: "center"}}>
                    <p><SpanBody1><FormattedDate value={day} weekday={'long'}/></SpanBody1></p>
                    <SpanBody2><FormattedDate value={day} day="numeric" month={'long'}/></SpanBody2>
                </th>)}
            </tr>
            </thead>
            <TableBody style={{marginTop: "20px"}}>
                {timeSlotAvailable.map((d, dIndex) => <tr key={dIndex}>
                    {onSelectDate && d.map((ts, hIndex) => <td key={hIndex} style={{textAlign: 'center'}}>
                        {ts ? <TimeCellButton
                            className={'date_button'}
                            active={selectedDate?.getTime() === (new Date(ts.date)).getTime()}
                            onClick={() => onSelectDate(ts.date)}
                            waitingList={!!ts.waitingList}
                            waitingListSpots={ts.waitingListSpots}
                            discount={!!ts.discount}>
                            <Time waitingList={ts.waitingList}>{intl.formatTime(ts.date, {
                                timeStyle: 'short',
                                timeZone: timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                            })}</Time>
                            {ts.discount ? <>
                                <br/><DiscountTag waitingListSpots={ts.waitingListSpots}>{ts.discount.type === 'percent' ? '-' + ts.discount.value + '%' :
                                <FormattedNumber 
                                  value={-ts.discount.value} style={`currency`} 
                                  minimumFractionDigits={ts.discount.value % 1 === 0 ? 0 : (ts.discount.value % 1).toString().length <= 3 ? 1 : 2}
                                  currency={currency}/>}
                                  </DiscountTag></> : null}
                            {ts.waitingList && ts.waitingListSpots !== undefined ? <>
                                {!ts.discount && <br/>}<WaitingListTag>{ts.waitingListSpots}</WaitingListTag>
                            </> : null}
                        </TimeCellButton> : null}
                    </td>)}
                </tr>)}
            </TableBody>
        </table>
    </div>
}
