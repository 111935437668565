import React, {FC, ReactNode} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {Container} from "@linkeo.com/ui-lib-react";
import {ReactComponent as RawPlannerLogo} from "../../../icons/planner-logo.svg";


const InternalLink = styled(Link)`
    color: ${({theme}) => theme.colors.grey20};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`
const FooterLink = styled.a`
    color: ${({theme}) => theme.colors.grey20};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

interface LinkeoWebsiteLinkProps {
    websiteUrl: string;
    label: ReactNode;
    title: string;
}

export const LinkeoWebsiteLink: FC<LinkeoWebsiteLinkProps> = ({websiteUrl, title, label}) => {
    return <FooterLink href={websiteUrl} target={'_blank'} title={title}>{label}</FooterLink>
}

const PlannerLink = styled(FooterLink)`
        display: inline-block;
        vertical-align: middle;
    `
const PlannerLogo = styled(RawPlannerLogo)`
        height: 2.4em;
        display: block;
        width: auto;
    `

interface PlannerWebsiteLinkProps {
    websiteUrl: string
    label: string
}

const PlannerWebsiteLink: FC<PlannerWebsiteLinkProps> = ({websiteUrl, label}) => {

    return <PlannerLink href={websiteUrl} target={'_blank'} title={label}><PlannerLogo/></PlannerLink>
}

interface PoweredByProps {
    plannerWebsiteUrl: string
    plannerWebsiteLinkTitle: string
    poweredByLabel: ReactNode
    className?: string
}

const Wrapper = styled.span`
        display: inline-block;
    `

const PoweredBy: FC<PoweredByProps> = ({
                                           plannerWebsiteUrl,
                                           plannerWebsiteLinkTitle,
                                           poweredByLabel,
                                           className
                                       }) => {

    return <Wrapper className={`powered-by ${className}`}>
        {poweredByLabel} <PlannerWebsiteLink websiteUrl={plannerWebsiteUrl} label={plannerWebsiteLinkTitle}/>
    </Wrapper>
}

interface CopyrightProps {
    year: ReactNode
    linkeoCopyrightLink: LinkeoWebsiteLinkProps
}

const Copyright: FC<CopyrightProps> = ({year, linkeoCopyrightLink}) => {
    return <span className="/copyright">© <LinkeoWebsiteLink {...linkeoCopyrightLink}/> {year} </span>
}

interface LegalNoticeProps {
    link: string
    title?: string
    label: ReactNode
}

const LegalNotice: FC<LegalNoticeProps> = ({link, label, title}) => {
    return <span className="legal-notice"><InternalLink to={link} title={title}>{label}</InternalLink></span>
}

export interface TermsOfSalesProps {
    link: string
    title?: string
    label: ReactNode
}

const TermsOfSales: FC<TermsOfSalesProps> = ({link, label, title}) => {
    return <span className="terms-of-sales"><InternalLink to={link} title={title}>{label}</InternalLink></span>
}

interface PrivacyPolicyProps {
    link: string
    title?: string
    label: ReactNode
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({link, label, title}) => {
    return <span className="privacy-policy"><FooterLink target={'_blank'} href={link} title={title}>{label}</FooterLink></span>
}

const Footer = styled.footer`
        color: ${({theme}) => theme.colors.grey20};
        font-family: 'Montserrat', sans-serif;
        font-size: 70%;
    `
const FixedPoweredBy = styled(PoweredBy)`
        position: fixed;
        right: 0;
        bottom: 0;
        padding: 5px;
    `

export interface PageFooterProps {
    legalNotice: LegalNoticeProps;
    termsOfSales?: TermsOfSalesProps;
    privacyPolicy: PrivacyPolicyProps;
    copyright: CopyrightProps
    poweredBy: PoweredByProps
}

export const PageFooter: FC<PageFooterProps> = ({copyright, poweredBy, legalNotice, termsOfSales, privacyPolicy}) => {

    return <Footer>
        <Container size={"lg"} style={{paddingTop: `10px`, paddingBottom: `10px`, textAlign: 'center'}}>
            <FixedPoweredBy {...poweredBy}/><Copyright {...copyright}/> — <PrivacyPolicy {...privacyPolicy} />{false && <>— <LegalNotice {...legalNotice}/>{
            termsOfSales && <> — <TermsOfSales {...termsOfSales as TermsOfSalesProps}/></>
        }</>}
        </Container>
    </Footer>

}
