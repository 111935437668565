import {FC, useEffect, useState} from "react"
import {Button, Checkbox, Col, IconName, Input, Row, Svg} from "@linkeo.com/ui-lib-react"
import {useIntl} from "react-intl";
import {useTheme} from "styled-components"
import {giftCardCodesStatus, giftCardInvalid, GiftCardStatus, giftCardValid} from "./booking-types";

interface InputCodeProps {
    readOnly: boolean,
    value: string,
    onClickValid: () => void,
    loading: boolean,
    status: GiftCardStatus | undefined,
    onRemoveCode?: () => void,
    onChange?: (value: string) => void
}

const InputCode: FC<InputCodeProps> = ({
                                           readOnly,
                                           value,
                                           onClickValid,
                                           loading,
                                           status,
                                           onRemoveCode,
                                           onChange
                                       }) => {

    const {colors} = useTheme();
    const intl = useIntl();

    let icon: IconName | undefined;
    switch (status) {
        case giftCardValid:
            icon = 'checkmark-fill';
            break;
        case giftCardInvalid:
            icon = 'close-fill'
            break;
    }

    return <Row alignItems={'flex-end'}>
        <Col style={{flex: '1'}}>
            <Input
                icon={icon}
                placeholder={intl.formatMessage({
                    id: 'giftCardNumberInputPlaceholder',
                    defaultMessage: 'Insérer un bon cadeau'
                })}
                value={value}
                onChange={onChange}
                required
                readOnly={readOnly}
                hasError={status === giftCardInvalid}
                label={intl.formatMessage({id: 'giftCardNumberInputLabel', defaultMessage: 'Numéro du bon cadeau'})}
                style={{marginBottom: 0}}/>
        </Col>

        <Col style={{marginBottom: '12px'}}>
            {status === giftCardValid ?
                <Button onClick={onRemoveCode} disabled={readOnly} loading={loading} colorType={'primary'} style={{padding: '10px'}}><Svg
                    icon={"trash-outline"} height={20} width={20} fill={colors.light}/></Button>
                : <Button onClick={onClickValid} disabled={readOnly} loading={loading} colorType={'primary'}>{intl.formatMessage({
                    id: 'InputGiftcardConfirmButtonLabel',
                    defaultMessage: 'Vérifier'
                })}</Button>}
        </Col>

    </Row>
}

interface InputGiftCardProps {
    readOnly: boolean;
    giftCards: giftCardCodesStatus[];
    onCheckingGCValidity: (value: string) => void;
    onRemovingCode: (value: giftCardCodesStatus) => void;
    loading: boolean
}

export const InputsGiftCards: FC<InputGiftCardProps> = ({
                                                            readOnly,
                                                            giftCards,
                                                            onRemovingCode,
                                                            onCheckingGCValidity,
                                                            loading
                                                        }) => {

    const [checked, setChecked] = useState<boolean>(false)
    const [lastInputCode, setLastInputCode] = useState<string>('')
    const [readOnlyCodes, setReadOnlyCodes] = useState<giftCardCodesStatus[] | undefined>([]);
    const intl = useIntl();

    const onChangeChecked = () => {
        giftCards.forEach(c => onRemovingCode(c));
        setChecked(!checked)
    }

    let lastCode: giftCardCodesStatus | undefined;
    lastCode = [...giftCards].pop();

    useEffect(() => {
        if (lastCode?.status === giftCardValid) {
            setLastInputCode('')
            setReadOnlyCodes(giftCards);
        } else {
            const c = [...giftCards];
            c.pop();
            setReadOnlyCodes(c);
            setLastInputCode(lastCode?.code || '')
        }
    }, [lastCode?.code, lastCode?.status, giftCards])

    return <div style={{marginBottom: '32px'}}>
        {readOnly ? null : <Checkbox onChange={onChangeChecked} value={checked}>{intl.formatMessage({
            id: 'GiftCardOptionCheckboxLabel',
            defaultMessage: 'Avez-vous un bon cadeau ?'
        })}</Checkbox>}

        {checked ? <>
            {readOnlyCodes ? readOnlyCodes.map((e) => <InputCode
                key={e.code}
                value={e.code}
                status={e.status}
                loading={loading}
                readOnly={readOnly}
                onClickValid={() => onCheckingGCValidity(e.code)}
                onRemoveCode={() => onRemovingCode(e)}
            />) : null}
            <InputCode
                value={lastInputCode}
                onChange={setLastInputCode}
                status={lastCode?.status === giftCardInvalid ? giftCardInvalid : undefined}
                loading={loading}
                readOnly={readOnly}
                onClickValid={() => onCheckingGCValidity(lastInputCode)}
            />

        </> : null}
    </div>
}
