import {createContext, FC, useCallback, useContext, useEffect, useState} from "react";
import {getGiftcardList} from "../../gift-card/gift-card-api";
import {getCustomerInformations, updateCustomerInformations} from "../authentification/authentification-api";
import {Appointment, CustomerAuth, CustomerUpdate, PurchasedGiftCard} from "./account.types";
import {getAppointmentList} from "./account-api";
import {StoreProps} from "../../common/commons.types";

interface GiftCardBasketContextProps {
    giftCards: PurchasedGiftCard[];
    giftCardsCount?: number;
    loadAppointments: () => Promise<void>;
    loadGiftcards: () => Promise<void>;
    appointments: Appointment[];
    appointmentsCount?: number;
    userInformations?: CustomerAuth;
    updatePersonalInformations: (infos: CustomerUpdate) => Promise<void>;
}

const messageError = 'Provider is not initialized'

const AccountContext = createContext<GiftCardBasketContextProps>({
    loadAppointments: () => Promise.reject(new Error(messageError)),
    loadGiftcards: () => Promise.reject(new Error(messageError)),
    giftCards: [],
    appointments: [],
    updatePersonalInformations: () => Promise.reject(new Error(messageError)),
});

export const useAccountStore = () => useContext(AccountContext)


interface AccountProviderProps extends StoreProps {
    onError: (err: any) => void;
}

export const AccountProvider: FC<AccountProviderProps> = ({
                                                              children,
                                                              codeBouton,
                                                              token,
                                                              onError
                                                          }) => {
    // giftcards
    const [giftCards, setGiftCards] = useState<PurchasedGiftCard[]>([]);
    const [giftCardsCount, setGiftCardsCount] = useState<number>();
    const [userInformations, setUserInformations] = useState<CustomerAuth>();

    const loadAllGiftcards = useCallback(async (limit = 20) => {
        let resp = await getGiftcardList(codeBouton, 0, limit, token);
        let giftcards = resp.result;
        while (resp.total > giftcards.length && resp.limit > resp.result.length) {
            resp = await getGiftcardList(codeBouton, giftcards.length, limit, token);
            giftcards = [...giftcards, ...resp.result];
        }
        return giftcards;
    }, [codeBouton, token])

    const loadGiftcards = useCallback(async (): Promise<void> => {
        try {
            const list = await loadAllGiftcards();
            setGiftCardsCount(list.length);
            setGiftCards(list);
        } catch (e) {
            console.error(e)
            if (onError) {
                onError(e);
            }
        }
    }, [loadAllGiftcards, onError])

    //appointments
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [appointmentsCount, setAppointmentsCount] = useState<number>();

    const loadAllAppointments = useCallback(async (limit = 20) => {
        let resp = await getAppointmentList(codeBouton, 0, limit, undefined, token);
        let appointments = resp.result;
        while (resp.total > appointments.length && resp.limit > resp.result.length) {
            resp = await getAppointmentList(codeBouton, appointments.length, limit, undefined, token);
            appointments = [...appointments, ...resp.result];
        }
        return appointments;
    }, [codeBouton, token])

    const loadAppointments = useCallback(async (): Promise<void> => {
        try {
            const list = await loadAllAppointments();
            setAppointmentsCount(list.length);
            setAppointments(list);
        } catch (e) {
            console.error(e)
            if (onError) {
                onError(e);
            }
        }
    }, [loadAllAppointments, onError])

    const updatePersonalInformations = async (info: CustomerUpdate) => {
        const infos = await updateCustomerInformations(codeBouton, info, token);
        setUserInformations(infos)
    };

    useEffect(() => {
        if (token) {
            getCustomerInformations(codeBouton, token)
                .then((infos) => setUserInformations(infos))
                .catch(err => console.error(err))
        }
    }, [codeBouton, token])

    return <AccountContext.Provider
        value={{
            userInformations,
            giftCards,
            giftCardsCount: giftCardsCount,
            loadGiftcards,
            updatePersonalInformations,
            appointments,
            loadAppointments,
            appointmentsCount
        }}>
        {children}
    </AccountContext.Provider>
}
