import { FC } from "react";
import styled, { useTheme } from "styled-components";
import { Svg } from "@linkeo.com/ui-lib-react";

interface DisplayPhoneProps {
    phone: string
}

const SpanPhone = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${({theme}) => theme.colors.grey30};
`;

export const DisplayPhone: FC<DisplayPhoneProps> = ({phone}) => {

    const theme = useTheme()

    return <span style={{whiteSpace : 'nowrap'}}>
        <Svg style={{margin: '0 5px -1px 10px', display: 'inline'}} fill={theme.colors.grey10} icon={'phone-fill'} width={14}
        height={14}></Svg>
        <SpanPhone>{phone}</SpanPhone>
    </span>
}