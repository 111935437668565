import React, {FC} from "react";
import {NavigationLarge, NavigationProps} from "./navigation/navigation-large";
import {Hide, TopSticky} from "@linkeo.com/ui-lib-react";
import {NavigationSmall} from "./navigation/navigation-small";

export const PageNavigation: FC<NavigationProps> = (props) => <>
    <Hide devices={['desktop', 'tablet', 'xl']}>
        <TopSticky>
            <NavigationSmall {...props}/>
        </TopSticky>
    </Hide>
    <Hide devices={['xs', 'small']}>
        <nav style={{paddingTop: "30px"}}>
            <NavigationLarge {...props}/>
        </nav>
    </Hide>
</>