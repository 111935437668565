import {createContext, FC, useContext, useMemo, useReducer, useRef, useState} from "react";
import {GiftCard, GiftCardPayment, PurchasedGiftCard} from "./gift-card.types";
import {Service} from "../service/service.types";
import {giftActionReducer} from "./gift-card-action";
import {getGiftcardList, postGiftcardPurchase, getGiftcard, getOptionsList, renderGiftcard} from "./gift-card-api";
import {StoreProps} from "../common/commons.types";
import {Option} from "../booking/book.types";
import {CreateDebounce} from "../common/misc-util";
import {delayBooking} from "../common/config/debounce-time";

interface GiftCardBasketContextProps {
    clearSelection: () => void
    purchaseGiftCard: () => Promise<GiftCardPayment>,
    selectGift: (service: Service) => Promise<void>
    selectedGiftCard: GiftCard | null,
    sendToRecipient: boolean,
    updateGiftOptions: (update: Partial<GiftCard>) => Promise<void>,
    updateGift: (update: Partial<GiftCard>) => void,
    fetchGiftcards: () => Promise<void>,
    giftcards: PurchasedGiftCard[] | null,
    fetchGiftCard: (giftCardId: string) => Promise<PurchasedGiftCard>,
    fetchGiftcardDisplay: (id: string) => Promise<any>
}

const messageError = 'Provider is not initialized'

const GiftCardContext = createContext<GiftCardBasketContextProps>({
    selectedGiftCard: null,
    purchaseGiftCard: () => Promise.reject(new Error(messageError)),
    sendToRecipient: true,
    selectGift: () => Promise.reject(new Error(messageError)),
    clearSelection: () => console.error(messageError),
    updateGiftOptions: () => Promise.reject(new Error(messageError)),
    updateGift: () => console.error(messageError),
    fetchGiftcards: () => Promise.reject(new Error(messageError)),
    giftcards: [],
    fetchGiftCard: () => Promise.reject(new Error(messageError)),
    fetchGiftcardDisplay: () => Promise.reject(new Error(messageError))
})

const fetchAllGiftCards = async (codeBouton: string, offset: number, token?: string): Promise<PurchasedGiftCard[]> => {
    const resp = await getGiftcardList(codeBouton, offset, 20, token);
    if ((offset + resp.count) < resp.total) {
        return [...resp.result, ...await fetchAllGiftCards(codeBouton, offset + resp.count, token)];
    }
    return resp.result;
}

export const useGiftCardStore = () => useContext(GiftCardContext)

export const GiftCardStore: FC<StoreProps> = ({children, codeBouton, token}) => {
    const [selectedGiftCard, setselectedGiftCard] = useReducer<typeof giftActionReducer>(giftActionReducer, null)
    const [sendToRecipient] = useState<boolean>(true)
    const [giftcards, setGiftcards] = useState<PurchasedGiftCard[] | null>(null)
    const abortLastCall = useRef<(() => void) | null>(null)

    const selectGift = async (service: Service) => {
        await getOptionsList(codeBouton, 0, 0, service.id, token).then((response) => {
            const options = response.result.map(option => {
                return {
                    ...option, choices: option.choices.map(choice => {
                        return {...choice, quantity: 0}
                    })
                }
            })
            setselectedGiftCard({type: 'selectGift', service, options, value: service.giftcardDiscount ? service.giftcardDiscount.price : service.price})
        })
    }

    const clearSelection = () => {
        setselectedGiftCard({type: 'clearGift'})
    }

    const purchaseGiftCard = async () => {
        if (selectedGiftCard) {
            const [promise] = postGiftcardPurchase(codeBouton, selectedGiftCard, token)
            return await promise
        }
        return Promise.reject(new Error('Giftcard hasn’t been selected'))
    }

    const debounce = useMemo(() => CreateDebounce(async (giftcard: GiftCard) => {
        const [promise, abort] = postGiftcardPurchase(codeBouton, giftcard, token,true);
        abortLastCall.current = abort;
        promise.then((response) => {
            setselectedGiftCard({type: 'updateGift', update: response.giftCard})
        }).finally(() => {
            abortLastCall.current = null;
        })
    }, delayBooking), [codeBouton, token])

    const updateGiftOptions = async (update: Partial<GiftCard>) => {
        if (!selectedGiftCard){
            return Promise.reject(new Error('Giftcard hasn’t been selected'))
        }
        const reduce = (option: Option) => {
            return option.choices.reduce((price, choice) => price + ((choice.price || 0) * (choice.quantity || 0)), 0) + (option.maxFree && option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0) > option.maxFree ? (option.maxFree && option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0) - option.maxFree) * (option.extraCharge || 0) : 0)
        }
        setselectedGiftCard({
            type: 'updateGift', update: {
                ...update,
                options: update.options?.map(option => {
                    return {...option, total: reduce(option)}
                }),
                value: (update.service?.price || 0) + (update.options?.reduce((price, option) => price + reduce(option),0) || 0)
            }
        })
        if (abortLastCall.current) {
            abortLastCall.current();
        }
        await debounce({...selectedGiftCard, ...update})
    }

    const updateGift = (update: Partial<GiftCard>) => setselectedGiftCard({type: 'updateGift', update})

    const fetchGiftcards = async () => {
        setGiftcards(await fetchAllGiftCards(codeBouton, 0, token))
    }

    const fetchGiftCard = async (giftCardId: string): Promise<PurchasedGiftCard> => {
        if (!token) {
            return Promise.reject(new Error('Token not found'))
        }
        return await getGiftcard(codeBouton, giftCardId, token)
    }

    const fetchGiftcardDisplay = async (id: string): Promise<any> => {
        return await renderGiftcard(codeBouton, id, token)
    }

    return <GiftCardContext.Provider
        value={{
            selectedGiftCard,
            purchaseGiftCard,
            sendToRecipient,
            selectGift,
            clearSelection,
            updateGift,
            updateGiftOptions,
            fetchGiftcards,
            giftcards,
            fetchGiftCard,
            fetchGiftcardDisplay
        }}>
        {children}
    </GiftCardContext.Provider>
}
