export type ErrorFields<T> = { [key in keyof T]?: boolean | string };
export class ErrorsBuilder<T> {
  constructor(private fields:ErrorFields<T>  = {}) {
  }

  appendError(key: keyof T, message?: string): void {
    this.fields[key] = message ? message : true;
  }

  hasError(key?: keyof T): boolean {
    if (key) {
      return !!this.fields[key];
    }
    return !!Object.keys(this.fields).length
  }
}