import styled, { useTheme } from "styled-components"
import React from 'react';
import { HTMLProps, ReactNode } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { LabelWrapper, LabelWrapperProps, IconName, BaseInputProps } from '@linkeo.com/ui-lib-react';
import { useIntl } from 'react-intl';

type BaseInputOmitChange = Omit<
  HTMLProps<HTMLInputElement>,
  'onChange' | 'value' | 'label' | 'children'
>;

export interface PhoneNumberInputProps extends LabelWrapperProps, BaseInputOmitChange, BaseInputProps {
  /**
   * insert react node in LabelWrapper after input
   */
  afterChild?: ReactNode;
  /**
   * insert react node in LabelWrapper before input
   */
  beforeChild?: ReactNode;
  zone: string;
  icon?: IconName;
  onChange: (phone: string) => void;
  onIconClick?: () => void;
  value?: string;
  children?: React.ReactNode;
}

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.danger}
`;

const StyledPhoneInput = styled(PhoneInput)`
`;

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = props => {
  const {
    required,
    disabled,
    zone,
    label,
    caption,
    hasError,
    small,
    value,
    onChange,
    right,
    beforeChild,
    afterChild,
    icon,
    iconPosition,
    onIconClick,
    ...rest
  } = props;

  const intl = useIntl();
  const theme = useTheme();

  // Gère le changement de numéro de téléphone
  const handlePhoneInputChange = (phone: string) => {
    onChange(phone);
  };

  const phoneNumberInputStyle = {
    // Typo
    fontFamily: theme.fonts['input'].family,
    fontWeight: theme.fonts['input'].weight,
    fontSize: theme.fonts['input'].size,
    lineHeight: theme.fonts['input'].lineHeight,
    letterSpacing: theme.fonts['input'].letterSpacing,
    fontStyle: theme.fonts['input'].style,
    textTransform: theme.fonts['input'].textTransform,
    textDecoration: theme.fonts['input'].textDecoration,

    // Input
    background: theme.input.backgroundColor,
    color: theme.input.color,
    border: `${theme.input.borderColor} 1px solid`,
    outline: 'none',
    width: '100%',
    boxSizing: 'border-box',
    margin: 0,
    transition: 'background ease-in-out 200ms, border-color ease-in-out 200ms, color ease-in-out 200ms, box-shadow ease-in-out 200ms',
  }


  return (
    <LabelWrapper {...{ required, disabled, caption, label, hasError, small, right }}>
      {beforeChild}
      <div style={{ position: 'relative' }}>
        <StyledPhoneInput
          {...(rest as any)}
          defaultCountry={zone}
          value={value}
          onChange={handlePhoneInputChange}
          inputProps={{
            required: true,
          }}
          style={{
            "--react-international-phone-background-color": theme.input.backgroundColor,
            "--react-international-phone-border-radius": "8px",
          }
          }
          inputStyle={phoneNumberInputStyle}
        />
        {hasError && <ErrorMessage>{intl.formatMessage({id: 'telephoneRequired', defaultMessage: 'Le numéro de téléphone est requis'})}</ErrorMessage>}
      </div>
      {afterChild}
    </LabelWrapper>
  );
};