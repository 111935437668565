import { PageTitle, Card, Row, Col, SpanBody2, SpanSubtitle1, BaseButton, SpanLink } from "@linkeo.com/ui-lib-react"
import { FC } from "react"
import { CircledSvg } from "../../../common/components/circled-svg"
import { useConfirm } from "../../../common/providers/confirm-provider"
import { CustomerAuth } from "../authentification.types"
import { AuthContainer } from "./auth-container"
import { useIntl } from 'react-intl';

interface CardAuthentificationProps {
    titleName: string,
    authData: CustomerAuth | null,
    subtitle : string,
    buttonLabel : string,
    onClickLogOut : ()=>void
}

export const CardAuthentification: FC<CardAuthentificationProps> = ({titleName, authData, subtitle, buttonLabel, onClickLogOut}) => {

    const confirm = useConfirm();
    const intl = useIntl();

    const confirmLogout = () => {
        confirm.openConfirm({
          title: intl.formatMessage({id: 'bookingSummaryAuthCaptionLogout', defaultMessage: 'Ce n’est pas votre compte ?'}),
          content: intl.formatMessage({id: 'bookingSummaryAuthCaptionLogout2', defaultMessage: 'Souhaitez vous vous déconnecter ?'})
        }).then(() => {
            onClickLogOut();
        })
      }

    return <>
        <PageTitle>{titleName}</PageTitle>
        {!authData ? <AuthContainer/> : <Card>
          <Row alignItems={['center']}>
            <Col>
              <CircledSvg icon={'person-outline'}/>
            </Col>
            <Col>
              <div><SpanBody2>{authData.lastName} {authData.firstName}</SpanBody2></div>
              <div><SpanSubtitle1>{subtitle}</SpanSubtitle1></div>
              <BaseButton
                onClick={confirmLogout}><SpanLink>{buttonLabel}</SpanLink></BaseButton>
            </Col>
          </Row>
        </Card>}
        </>
}
