import {FC} from 'react';
import {Col, Row, SpanSubtitle1} from '@linkeo.com/ui-lib-react';
import styled from 'styled-components';

interface SeparationProps {

}

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.colors.grey90};
`;
export const Separation: FC<SeparationProps> = ({children}) => {
  return <div style={{margin: '24px 0'}}>
    {children ? <Row alignItems={['center']} gapColumn={[4, 6, 12]}>
      <Col style={{flex: 1}}>
        <Line/>
      </Col>
      <Col>
        <SpanSubtitle1>{children}</SpanSubtitle1>
      </Col>
      <Col style={{flex: 1}}>
        <Line/>
      </Col>
    </Row> : <Line/>}
  </div>
}