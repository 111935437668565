import { ReactNode, FC } from "react"
import { IconName, Row, Col, Svg, SpanBody2, SpanBody1 } from "@linkeo.com/ui-lib-react"
import { useTheme } from "styled-components"
import { CircledCheck } from "./circled-check"

interface RowCardProps {
    caption: ReactNode | string
    icon: IconName
    subTitle?: string
    titleName: ReactNode | string
}

export const RowCard: FC<RowCardProps> = ({
                                       caption,
                                       icon,
                                       subTitle,
                                       titleName
                                   }) => {
    const {colors} = useTheme()
    return <Row gapColumn={[10]} alignItems={['center']}>
        <Col><Svg icon={icon} fill={colors.grey70} height={30} width={30}/></Col>
        <Col>
            <Row gapColumn={[4]} style={{marginBottom: '5px'}}>
                <Col>
                    <SpanBody2>{titleName}</SpanBody2>
                </Col>
                {subTitle ? <Col>
                    <SpanBody1 style={{color: colors.grey70}}>{subTitle}</SpanBody1>
                </Col> : null}
            </Row>
            <div>
                <SpanBody1 style={{color: colors.grey70}}>{caption}</SpanBody1>
            </div>
        </Col>
        <Col style={{marginLeft: 'auto'}}><CircledCheck/></Col>
    </Row>
}
