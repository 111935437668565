import React, {FC, FormEvent, Reducer, useEffect, useReducer, useState} from 'react';
import {Button, Flex, Input, ReducerObject, ReducerObjectType, useToaster} from '@linkeo.com/ui-lib-react';
import {ErrorsBuilder} from '../../../common/errors-builder';
import {ToggleReducer} from '../../../common/misc-util';
import {useAuthStore} from '../auth-store';
import {routeHome} from '../../../routes';
import {generatePath, useNavigate} from "react-router";
import { FormattedMessage, useIntl } from 'react-intl';

interface FormControl {
    password: string;
    confirm: string;
}


export const ResetPasswordForm: FC = () => {
    const [getToken, setToken] = useState<string>("");
    const navigate = useNavigate();
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (!token) {
            return navigate(generatePath(routeHome))
        }
        setToken(token)
    }, [navigate]);
    const toast = useToaster();
    const intl = useIntl();
    const {resetPassword} = useAuthStore();
    const [getFormControl, setFormControl] = useReducer<ReducerObjectType<FormControl>>(ReducerObject, {
        password: "",
        confirm: ""
    });
    const [getErrors, setErrors] = useState<ErrorsBuilder<FormControl>>(new ErrorsBuilder<FormControl>());
    const [getLoading, setLoading] = useState<boolean>(false);
    const controlErrorsResetPassword = (formControl: FormControl): ErrorsBuilder<FormControl> => {
        const builder = new ErrorsBuilder<FormControl>();
        if (!formControl.password) {
            builder.appendError("password", intl.formatMessage({id : 'requestedInputLabel', defaultMessage: 'Ce champ est requis'}));
        }
        if (!formControl.confirm) {
            builder.appendError("confirm", intl.formatMessage({id : 'requestedInputLabel', defaultMessage: 'Ce champ est requis'}));
        }
        if (formControl.confirm !== formControl.password) {
            builder.appendError("confirm", intl.formatMessage({id : 'requestedInputPasswordLabel', defaultMessage: 'Les mots de passes ne correspondent pas'}));
        }
        return builder;
    }
    const emitSubmit = (event: FormEvent) => {
        event.preventDefault();
        const errors = controlErrorsResetPassword(getFormControl);
        setErrors(errors);
        if (errors.hasError()) {
            return;
        }
        if (getLoading) {
            return;
        }
        setLoading(true);
        resetPassword(getFormControl.password, getToken).then(() => {
            toast(intl.formatMessage({id : 'resetPasswordFormToastConfirm', defaultMessage: 'Votre mot de passe a bien été réinitialisé'}), {
                type: 'success',
                outlined: true
            });
        }).finally(() => setLoading(false))
    }

    const [showPassword, toggleShowPassword] = useReducer<Reducer<boolean, void>>(ToggleReducer, false);
    const [showConfirm, toggleShowConfirm] = useReducer<Reducer<boolean, void>>(ToggleReducer, false);
    return <form onSubmit={emitSubmit}>
        <Input hasError={getErrors.hasError('password')} name={"new-password"}
               type={showPassword ? 'text' : 'password'}
               label={intl.formatMessage({id : 'resetPasswordNewOneLabel', defaultMessage: "Nouveau Mot de passe"})} required
               value={getFormControl.password} onChange={password => setFormControl({merge: {password}})}
               icon={showPassword ? "eye2-off-outline" : "eye2-outline"} onIconClick={toggleShowPassword}/>
        <Input hasError={getErrors.hasError('confirm')} name={"new-password"}
               type={showConfirm ? 'text' : 'password'}
               label={intl.formatMessage({id : 'resetPasswordNewPasswordConfirmationLabel', defaultMessage: "Confirmer le Nouveau Mot de passe"})} required
               value={getFormControl.confirm} onChange={confirm => setFormControl({merge: {confirm}})}
               icon={showConfirm ? "eye2-off-outline" : "eye2-outline"} onIconClick={toggleShowConfirm}/>
        <Flex justifyContent={['center']}><Button type={"submit"}
                                                  colorType={"primary"}>
                                                  <FormattedMessage id='resetPasswordFormButtonSubmit' defaultMessage='Réinitialiser mon mot de passe'/></Button></Flex>
    </form>
}
