import {FC, useEffect} from 'react';
import { generatePath, useNavigate } from 'react-router';
import { useBookingStore } from '../../../booking/booking-store';
import { useCodeBouton } from '../../../common/providers/code-bouton-provider';
import { routeUserAccount } from '../../../routes';
import {useAuthStore} from '../auth-store';

export const AuthCallback: FC = () => {
  const codeBouton = useCodeBouton();
  const {authenticateCustomer} = useAuthStore();
  const {updateBooking} = useBookingStore();
  const navigate = useNavigate();

  const queryParams   = new URLSearchParams(window.location.search)
  const success       = queryParams.get('success')
  const accessToken   = queryParams.get('access_token')
  const refreshToken  = queryParams.get('refresh_token')
  const error         = queryParams.get('error')

  const storedRedirectUri = sessionStorage.getItem(`redirectUri_${codeBouton}`);
  
  useEffect(() => {
    // Récupérer également les informations de la réservation en cours
    const storedBooking = sessionStorage.getItem(`storedBooking_${codeBouton}`);
    if (storedBooking) {
        const reservationData = JSON.parse(storedBooking);
        updateBooking(reservationData);
        sessionStorage.removeItem(`storedBooking_${codeBouton}`);
    }
    sessionStorage.removeItem(`redirectUri_${codeBouton}`);
    
    if(success && accessToken && refreshToken){
      // Récupérer les informations de l'utilisateur à partir du token
      authenticateCustomer(accessToken, refreshToken)
      
      // Modifier l'URL en enlevant les paramètres en queryString
      window.history.replaceState({}, document.title, window.location.pathname);

      if(storedRedirectUri)
        navigate(storedRedirectUri)
    }
    else if(success && error){
      if(storedRedirectUri){
        const params = new URLSearchParams({
          success: success,
          error: error,
        });
        navigate(`${storedRedirectUri}?${params.toString()}`)
      }
    }
    else{
      navigate(generatePath(routeUserAccount))
    }
  }, );

  return null;
};
