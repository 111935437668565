import React, {FC} from 'react';
import {Flex, H1, H6, P, Svg} from '@linkeo.com/ui-lib-react';
import {useTheme} from 'styled-components';
import { FormattedMessage } from 'react-intl';

export const ErrorPage: FC = () => {
  const theme = useTheme();

  return (
    <Flex justifyContent={['center']} style={{paddingTop: '100px'}}>
      <div style={{textAlign: 'center'}}>
        <Svg icon={'cross-circle'} width={36} height={36} fill={theme.colors.grey60}/>
        <H1>
          500
        </H1>
        <H6>
          <FormattedMessage id='ErrorTitle' defaultMessage='Internal server error' />
        </H6>
        <P>
          <FormattedMessage id='ErrorSubtitle' defaultMessage='Une erreur s’est produite alors que{br}le serveur tentait de traiter la{br}demande.' />
        </P>
      </div>
    </Flex>
  );
};
