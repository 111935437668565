import { FC } from "react";
import { Svg, H6, SpanBody1, SpanBody2 } from "@linkeo.com/ui-lib-react";
import { useTheme } from "styled-components";
import { FormattedMessage } from "react-intl";

interface DisabledContentProps {
    message: string;
    phone?: string
}

export const DisabledContent: FC<DisabledContentProps> = ({message, phone}) => {
    const theme = useTheme();

    return <div style={{ textAlign: 'center', paddingTop: '50px' }}>
        <div>
            <Svg icon={"calendar-outline"} fill={theme.colors.grey30} />
        </div>
        <H6>{message}</H6>
        {phone && <><p>
            <SpanBody1>
                <FormattedMessage id='configurationProviderErrorSubtitle'
                    defaultMessage='Vous pouvez toujours nous contacter par téléphone au :' />
            </SpanBody1>
        </p>
            <p>
                <SpanBody2>
                    <FormattedMessage id='configurationProviderErrorPhoneNumberSupport' defaultMessage='{phone}'
                        values={{ phone }} />
                </SpanBody2>
            </p></>}
    </div>
}