import {ThemeColors, ThemeType} from '@linkeo.com/ui-lib-react';

const colors: ThemeColors = {
  primary: '#0D6CF2',
  invertPrimary: '#ffffff',
  secondary: '#414D58',
  invertSecondary: '#ffffff',
  danger: '#FF6666',
  lightDanger: '#ff8181',
  success: '#33CC8C',
  lightSuccess: '#71e7b6',
  warning: '#FF7E07',
  lightWarning: '#ff8f28',
  light: '#ffffff',
  dark: '#000000',
  grey10: '#1a1a1a',
  grey20: '#333333',
  grey30: '#414D58',
  grey40: '#576675',
  grey50: '#808080',
  grey60: '#8A99A8',
  grey70: '#A7B3BE',
  grey80: '#D3D9DE',
  grey90: '#e6e6e6',
  grey95: '#F0F2F4',
};
export const Theme: ThemeType = {
  colors,
  input: {
    borderRadius: '8px',
    backgroundColor: colors.grey95,
    color: colors.grey30,
    borderColor: colors.grey80,
    placeholderColor: colors.grey70,
  },
  box: {
    borderRadius: '6px',
  },
  button: {
    small: {
      borderRadius: '3px',
      padding: '4px 6px',
    },
    default: {
      borderRadius: '6px',
      padding: '12px 24px',
    },
  },
  card: {
    padding: '24px max(10px, min(3vw, 24px))',
  },
  icons: 'front',
  fonts: {
    input: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '24px',
      size: '18px',
      weight: '400',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    h1: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '2.25rem',
      size: '1.5rem',
      weight: 'initial',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    h2: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '2.25rem',
      size: '1.875rem',
      weight: '800',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    h3: {
      color: colors.grey30,
      family: '"Montserrat", sans-serif',
      lineHeight: '22px',
      size: '16px',
      weight: '600',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 16px 0',
    },
    h4: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '1.75rem',
      size: '1.25rem',
      weight: '800',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    h5: {
      color: colors.grey30,
      family: '"Montserrat", sans-serif',
      lineHeight: '22px',
      size: '16px',
      weight: '600',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    h6: {
      color: colors.grey20,
      family: '"Montserrat", sans-serif',
      lineHeight: '23px',
      size: '19px',
      weight: '700',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    body1: {
      color: colors.grey30,
      family: '"Open Sans", sans-serif',
      lineHeight: '20px',
      size: '16px',
      weight: '400',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    body2: {
      color: colors.grey30,
      family: '"Open Sans", sans-serif',
      lineHeight: '20px',
      size: '16px',
      weight: '700',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    fontButton: {
      color: colors.grey10,
      family: '"Montserrat", sans-serif',
      lineHeight: '22px',
      size: '14px',
      weight: '600',
      letterSpacing: 'initial',
      textTransform: 'uppercase',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    smallFontButton: {
      color: colors.grey10,
      family: '"Montserrat", sans-serif',
      lineHeight: '12px',
      size: '10px',
      weight: '700',
      letterSpacing: 'initial',
      textTransform: 'uppercase',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    subtitle1: {
      color: colors.grey30,
      family: '"Open Sans", sans-serif',
      lineHeight: '22px',
      size: '14px',
      weight: '400',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    subtitle2: {
      color: colors.grey30,
      family: '"Open Sans", sans-serif',
      lineHeight: '22px',
      size: '14px',
      weight: '700',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    caption1: {
      color: colors.grey30,
      family: '"Open Sans", sans-serif',
      lineHeight: '16px',
      size: '13px',
      weight: '400',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'italic',
      textDecoration: 'initial',
      margin: '0',
    },
    caption2: {
      color: colors.grey30,
      family: '"Open Sans", sans-serif',
      lineHeight: '16px',
      size: '13px',
      weight: '700',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'italic',
      textDecoration: 'initial',
      margin: '0',
    },
    link: {
      color: colors.grey40,
      family: '"Open Sans", sans-serif',
      lineHeight: '22px',
      size: '14px',
      weight: '400',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'underline',
      margin: '0',
    },
  }
};
