import {FC, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {
    Button,
    Card,
    Checkbox,
    Col,
    Container,
    PageTitle,
    Row,
    SpanBody1,
    SpanBody2,
    SpanCaption2,
    SpanSubtitle1,
    Svg,
    useToaster
} from '@linkeo.com/ui-lib-react';
import styled, {useTheme} from 'styled-components';
import {addDays, eachDayOfInterval, endOfDay, Interval, isSameDay, startOfDay} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {Separation} from '../../common/components/separation';
import {CreateDebounce} from '../../common/misc-util';
import {FormattedMessage, useIntl} from 'react-intl';
import {routeDateSelection} from '../../routes';
import {LinkOutlined} from '../../common/components/link-outlined';
import {AvailabilitiesTable} from './availabilities-table';
import {Discount} from "../../common/commons.types";
import {AvailabilitiesLegend} from "./service/availabilities-legend";
import {generatePath} from "react-router";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";

const CircleButton = styled(Button)`
    width: 44px;
    height: 44px;
    border-radius: 50%;
    padding: 7px 0 0 2px;
`;

interface TimeSlot {
    date: string;
    discount?: Discount;
    waitingList: boolean | null;
    waitingListSpots?: number
}

interface Availabilities {
    firstAvailable: string;
    availabilities: TimeSlot[];
}

interface AvailabilitiesCalendarProps {
    selectedDate?: Date;
    onSelectDate?: (date: string) => void;
    interval?: Interval,
    onUpdateInterval: (interval: Interval) => void;
    isLoading: boolean
    availabilities?: Availabilities;
    currency: string;
    timezone: string | null
}

export const AvailabilitiesCalendar: FC<AvailabilitiesCalendarProps> = ({
                                                                            isLoading,
                                                                            availabilities,
                                                                            onSelectDate,
                                                                            onUpdateInterval,
                                                                            interval,
                                                                            selectedDate,
                                                                            currency,
                                                                            timezone
                                                                        }) => {
    const refRange = useRef<number>(-1);
    const firstDate: Date | null = availabilities && availabilities.firstAvailable ? new Date(availabilities.firstAvailable) : null;
    const refDateRowContainer = useRef<HTMLDivElement>(null);
    const [headerDays, setHeaderDays] = useState<Date[]>([]);
    const [timeSlotAvailable, setTimeSlotAvailable] = useState<(TimeSlot | undefined)[][]>([]);
    const {locale, ...intl} = useIntl();
    const theme = useTheme();

    useEffect(() => {
        if (!availabilities) {
            return
        }
        const intervalDays = interval ? eachDayOfInterval(interval) : [];
        if (!intervalDays.length) {
            return;
        }
        setHeaderDays(intervalDays);
        const classedDates: TimeSlot[][] = []
        intervalDays.forEach((intervalDay) => {
            const classedDatesDay: TimeSlot[] = [];
            availabilities.availabilities.forEach(timeSlot => {
                if (isSameDay(utcToZonedTime(new Date(timeSlot.date),
                    timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone), intervalDay)) {
                    classedDatesDay.push(timeSlot);
                }
            });
            classedDates.push(classedDatesDay)
        });

        function transpose<T>(matrix: T[][]): (T | undefined)[][] {
            const longestArr = Math.max(...matrix.map(arr => arr.length));
            const findLongest = matrix.find(m => m.length === longestArr);
            if (!findLongest) {
                return []
            }
            return findLongest.map((_, i) => matrix.map(x => x[i]))
        }

        setTimeSlotAvailable(transpose(classedDates))
    }, [availabilities, interval, timezone]);

    const onClickInterval = (interval: Interval, direction: -1 | 1) => {
        onUpdateInterval({
            start: addDays(interval.start, (refRange.current + 1) * direction),
            end: addDays(interval.end, (refRange.current + 1) * direction)
        })
    }

    useEffect(() => {
        const container = refDateRowContainer.current;
        if (!container) {
            return
        }
        const computeInterval = () => {
            const width = container.clientWidth;
            const containerPadding = 160;
            const maxCellWidth = 140;
            const maxCellToShow = 5;
            const minCellToShow = 1;
            const pickRange = Math.floor(Math.max(minCellToShow - 1, Math.min((width - containerPadding) / maxCellWidth, maxCellToShow - 1)));
            if (refRange.current === pickRange) {
                return
            }
            refRange.current = pickRange;
            const start = startOfDay(new Date())
            onUpdateInterval({
                start,
                end: endOfDay(addDays(start, pickRange))
            });
        }
        const debounce = CreateDebounce(computeInterval, 1000)
        const onScreenResize = () => {
            debounce();
        }
        window.addEventListener('resize', onScreenResize);
        if (!interval) {
            onScreenResize();
        }
        return () => {
            window.removeEventListener('resize', onScreenResize);
        }
    }, [refDateRowContainer, interval, onUpdateInterval])

    return <div ref={refDateRowContainer}>
        {isLoading ? <div style={{textAlign: 'center'}}><Svg animatedIcon={"spinner"} stroke={theme.colors.grey30}/>
            </div>
            : interval && availabilities ? <>
                <div style={{position: 'relative', zIndex: 10}}>
                    <Row justifyContent={['space-between']} style={{marginBottom: "20px", flexWrap: 'wrap-reverse'}}>
                        <Col>
                            <AvailabilitiesLegend
                                color={theme.colors.danger}
                                legend={intl.formatMessage({
                                    id: 'AvailabilityLegendWaitingListTimeSlot',
                                    defaultMessage: 'Créneau avec liste d’attente'
                                })}/>
                            <AvailabilitiesLegend
                                legend={intl.formatMessage({
                                    id: 'AvailabilityLegendWaitingListNumber',
                                    defaultMessage: 'Nombre de personnes déjà en liste d’attente'
                                })}>
                                <span style={{
                                    height: '18px',
                                    width: '18px',
                                    backgroundColor: theme.colors.danger,
                                    borderRadius: '11px',
                                    marginRight: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <SpanSubtitle1
                                        style={{color: theme.colors.light, fontWeight: 600}}>?</SpanSubtitle1>
                                </span>
                            </AvailabilitiesLegend>
                            <AvailabilitiesLegend
                                color={theme.colors.success}
                                legend={intl.formatMessage({
                                    id: 'AvailabilityLegendDiscountTimeSlot',
                                    defaultMessage: 'Créneau en promotion'
                                })}/>
                        </Col>
                        <Col style={{padding: '10px', border: `1px solid ${theme.colors.grey90}`, borderRadius: '6px'}}>
                            <div>
                                <SpanBody2><FormattedMessage id='bookingDateSelectionInfoFirstDispo'
                                                             defaultMessage='Premier créneau disponible'/></SpanBody2>
                            </div>
                            {firstDate ? <div>
                                <SpanBody1>
                                    <FormattedMessage id='dateTimeFormat' defaultMessage='Le {date} à {time} ' values={{
                                        date: intl.formatDate(firstDate, {
                                            dateStyle: "full",
                                            timeZone: timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                                        }),
                                        time: intl.formatTime(firstDate, {
                                            timeStyle: 'short',
                                            timeZone: timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                                        })
                                    }}/>
                                </SpanBody1>
                            </div> : <div>
                                <SpanBody1>
                                    <FormattedMessage id='dateTimeErrorMessage'
                                                      defaultMessage='Aucune disponibilité pour le moment '/>
                                </SpanBody1>
                            </div>}
                        </Col>
                    </Row>
                    <Row alignItems={['center']} justifyContent={['space-between']} gapColumn={[0]}>
                        <Col>
                            <CircleButton onClick={() => {
                                onClickInterval(interval, -1)
                            }}>
                                <Svg width={24} height={24} icon={"arrow-ios-back-outline"}/></CircleButton></Col>
                        <Col>
                            <CircleButton onClick={() => {
                                onClickInterval(interval, 1)
                            }}><Svg width={24} height={24} icon={"arrow-ios-forward-outline"}/></CircleButton></Col>
                    </Row>
                </div>
                <Separation/>

                <AvailabilitiesTable
                    headerDays={headerDays}
                    timeSlotAvailable={timeSlotAvailable}
                    onSelectDate={onSelectDate}
                    currency={currency}
                    selectedDate={selectedDate}
                    timezone={timezone}
                />

            </> : <div style={{textAlign: 'center'}}>
                <SpanCaption2>
                    <FormattedMessage id={'dateTimeErrorMessage'}
                                      defaultMessage={'Aucune disponibilité pour le moment'}/>
                </SpanCaption2></div>}
    </div>;
}

export interface BookingDateSectionProps {
    onDateSelected?: (date: string, waitingList?: boolean) => void;
    selectedDate?: Date;
    readOnly: boolean;
    bookingDate?: Date;
    onGettingAvailabilities?: (from: Date, to: Date, waitingList?: boolean) => Promise<Availabilities>;
    currency: string;
    disabled?: boolean;
    timezone: string | null;
    allowWaitingList: boolean;
}

export const BookingDateSection: FC<BookingDateSectionProps> = ({
                                                                    onDateSelected,
                                                                    selectedDate,
                                                                    readOnly,
                                                                    bookingDate,
                                                                    onGettingAvailabilities,
                                                                    currency,
                                                                    disabled,
                                                                    timezone,
                                                                    allowWaitingList
                                                                }) => {
    const [interval, setInterval] = useState<Interval>();
    const [availabilities, setAvailabilities] = useState<Availabilities>();
    const [loading, setLoading] = useState<boolean>(true);
    const [showWaitingList, setShowWaitingList] = useState<boolean>(false);
    const intl = useIntl();
    const codeBouton = useCodeBouton();
    const toast = useToaster();

    const onUpdateInterval = useCallback((interval: Interval, showWaitList?: boolean) => {
        setInterval(interval);
        setLoading(true);
        setAvailabilities(undefined);
        if (!onGettingAvailabilities) {
            return;
        }
        onGettingAvailabilities(
            new Date(interval.start),
            new Date(interval.end),
            showWaitList !== undefined ? showWaitList : showWaitingList
        )
            .then(availabilities => setAvailabilities(availabilities))
            .catch(err => {
                console.error(err);
                toast(intl.formatMessage({
                    id: 'accountEditError',
                    defaultMessage: 'Une erreur est survenue, merci de réessayer utlérieurement'
                }))
            }).finally(() => {
            setLoading(false);
        })
    }, [intl, onGettingAvailabilities, showWaitingList, toast])

    let content: ReactNode;

    if (readOnly) {
        content = <Row justifyContent={['space-between']} alignItems={['center']}>
            <Col>
                <SpanBody2>
                    <FormattedMessage id='dateTimeFormat' defaultMessage='Le {date} à {time} ' values={{
                        date: intl.formatDate(bookingDate, {
                            dateStyle: "full",
                            timeZone: timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                        }),
                        time: intl.formatTime(bookingDate, {
                            timeStyle: 'short',
                            timeZone: timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                        })
                    }}/>
                </SpanBody2>
            </Col>
            <Col>
                {disabled ? <Button disabled={true}><FormattedMessage id='bookingSummaryButtonUpdateDate'
                                                                      defaultMessage='Modifier'/></Button> :
                    <LinkOutlined to={generatePath(routeDateSelection, {codeBouton, locale: intl.locale})}>
                        <FormattedMessage id='bookingSummaryButtonUpdateDate' defaultMessage='Modifier'/>
                    </LinkOutlined>}
            </Col>
        </Row>
    } else {
        content = <AvailabilitiesCalendar
            selectedDate={selectedDate}
            onSelectDate={(date) => onDateSelected && onDateSelected(date, showWaitingList)}
            onUpdateInterval={onUpdateInterval}
            availabilities={availabilities}
            isLoading={loading}
            interval={interval}
            currency={currency}
            timezone={timezone}
        />
    }

    return <Container size={'lg'}>
        <PageTitle>
            <FormattedMessage id='bookingDateSelectionTitle' defaultMessage='2. Choix de la date et heure'/>
            {timezone && timezone !== Intl.DateTimeFormat().resolvedOptions().timeZone &&
                <FormattedMessage id={'bookingDateSelectionTimezoneTitle'} defaultMessage={' - Heure de {timezone}'}
                                  values={{timezone: timezone}}/>}
        </PageTitle>
        {!readOnly && <div style={{marginBottom: '24px'}}>
            <SpanSubtitle1>
                <FormattedMessage
                    id={'bookingDateSelectionSubtitle'}
                    defaultMessage={'Sélectionnez le créneau de votre choix :'}
                />
            </SpanSubtitle1>
            {allowWaitingList && <>
                <div style={{marginTop: '18px'}}>
                    <SpanBody2>
                        <FormattedMessage
                            id={'bookingDateSelectionWaitingListTitle'}
                            defaultMessage={'Vous ne trouvez pas le créneau qui vous correspond ?'}
                        />
                    </SpanBody2>
                </div>
                <Checkbox value={showWaitingList} onChange={(v) => {
                    setShowWaitingList(v);
                    interval && onUpdateInterval(interval, v)
                }}>
                    <FormattedMessage
                        id={'bookingDateSectionWaitingListSwitchLabel'}
                        defaultMessage={'Afficher la liste d\'attente'}
                    />
                </Checkbox>
                <SpanSubtitle1>
                    <FormattedMessage
                        id={'bookingDateSectionWaitingListCaption'}
                        defaultMessage={'En sélectionnant un créneau sur liste d\'attente, vous n\'êtes pas assuré d\'obtenir votre rendez-vous. Quoiqu\'il arrive, vous serez prévenu au plus tard 1 heure(s) avant le début de votre rendez-vous.'}
                    />
                </SpanSubtitle1>
            </>}
        </div>}
        <Card>
            {content}
        </Card>
    </Container>
}
