import React, {FC, FormEvent, useState} from 'react';
import {Button, Flex, Input, useToaster} from '@linkeo.com/ui-lib-react';
import {useAuthStore} from '../auth-store';
import { useIntl } from 'react-intl';

interface ReInitPasswordFormProps {
}

export const ForgotPasswordForm: FC<ReInitPasswordFormProps> = () => {
  const {forgotPassword} = useAuthStore();
  const toast = useToaster();
  const intl = useIntl();
  const [getLoading, setLoading] = useState<boolean>(false);
  const [getEmail, setEmail] = useState<string>("");
  const emitSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (getLoading) {
      return;
    }
    setLoading(true)
    if (!getEmail) {
      return
    }
    forgotPassword(getEmail).then(() => {
      toast(intl.formatMessage({id: 'forgotPasswordFormConfirmMail', defaultMessage: 'Un email vous a été envoyé !'}));
    }).finally(() => {
      setLoading(false);
    })
  }

  return <form onSubmit={emitSubmit}>
    <Input name={"email"} type={'email'}
           label={intl.formatMessage({id : 'PasswordFormEmailLabel', defaultMessage: 'Adresse e-mail'})} required
           value={getEmail}
           onChange={setEmail} icon={"email-outline"}/>
    <Flex justifyContent={['center']}>
      <Button colorType={'primary'} type={'submit'}
              loading={getLoading}>{intl.formatMessage({ id : 'forgotPasswordFormButtonConfirm', defaultMessage: 'Envoyer mon mot de passe'})}</Button>
    </Flex>
  </form>
}