import React, {FC, FormEvent, Reducer, useReducer, useState} from 'react';
import {
  BaseButton,
  Button,
  Checkbox,
  Col,
  Flex,
  Input,
  ReducerObject,
  ReducerObjectType,
  Row,
  SpanLink,
  useToaster
} from '@linkeo.com/ui-lib-react';
import {ErrorsBuilder} from '../../../common/errors-builder';
import {GetErrorsLoginForm} from '../authentification.validator';
import {useAuthStore} from '../auth-store';
import { FormattedMessage, useIntl } from 'react-intl';

interface AuthCustomerRequest {
  email: string;
  password: string;
  stayConnected: boolean;
}
interface LoginFormProps {
  onForgotPasswordClick: () => void;
}

export const LoginForm: FC<LoginFormProps> = ({onForgotPasswordClick}) => {
  const {logIn} = useAuthStore();
  const intl = useIntl();
  const toast = useToaster();
  const [getLoading, setLoading] = useState<boolean>(false);
  const [getFormControl, setFormControl] = useReducer<ReducerObjectType<AuthCustomerRequest>>(ReducerObject, {
    email: '',
    password: '',
    stayConnected: false
  });
  const [showPassword, toggleShowPassword] = useReducer<Reducer<boolean, void>>((prev) => !prev, false);
  const [getErrors, setErrors] = useState<ErrorsBuilder<AuthCustomerRequest>>(new ErrorsBuilder<AuthCustomerRequest>());
  const emitSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (getLoading) {
      return;
    }
    const errors = GetErrorsLoginForm(getFormControl);
    setErrors(errors);
    setLoading(true)
    if (errors.hasError()) {
      return
    }
    logIn(getFormControl.email, getFormControl.password, getFormControl.stayConnected)
        .catch(err => {
          if (err.response?.status === 400) {
            toast(intl.formatMessage({id: 'logInError', defaultMessage: 'L\'email ou le mot de passe est invalide'}))
          } else {
            toast(intl.formatMessage({id: 'toastMessageError', defaultMessage: 'Une erreur est survenue'}))
          }
        })
        .finally(() => setLoading(false))
  }

  return <form onSubmit={emitSubmit}>
    <Input hasError={getErrors.hasError('email')} name={"email"} type={'email'}
           label={intl.formatMessage({id : 'PasswordFormEmailLabel', defaultMessage: 'Adresse e-mail'})} required
           value={getFormControl.email}
           onChange={email => setFormControl({merge: {email}})} icon={"email-outline"}/>
    <Input hasError={getErrors.hasError('password')} name={"current-password"}
           type={showPassword ? 'text' : 'password'}
           label={intl.formatMessage({id : 'PasswordFormPasswordLabel', defaultMessage: 'Mot de passe'})} required
           value={getFormControl.password} onChange={password => setFormControl({merge: {password}})}
           icon={showPassword ? "eye2-off-outline" : "eye2-outline"} onIconClick={toggleShowPassword}/>
    <Row justifyContent={['center']}>
        <Col style={{marginTop: '15px'}}>
          <BaseButton
            onClick={onForgotPasswordClick}>
            <SpanLink>
              <FormattedMessage id='loginFormForgotLink' defaultMessage='Mot de passe oublié ?'/>
            </SpanLink>
          </BaseButton>
        </Col>
        <Col style={{marginBottom: "10px"}}> 
          <Checkbox
            onChange={stayConnected => setFormControl({merge: {stayConnected}})} 
            value={getFormControl.stayConnected}>
            <SpanLink style={{textDecorationLine: 'none'}}>
              <FormattedMessage id='loginFormStayConnected' defaultMessage='Rester connecté'/>
            </SpanLink>
          </Checkbox>
        </Col>
    </Row>
    <Flex justifyContent={['center']}>
      <Button className={'login_button'} colorType={'primary'} type={'submit'}
              loading={getLoading}>
              <FormattedMessage id='loginFormButtonConfirm' defaultMessage='Me connecter' />
      </Button>
    </Flex>
  </form>
}