import axios, {AxiosRequestConfig, AxiosRequestHeaders} from "axios";
import { Pagination } from "../common/commons.types";
import {appointmentsApiUri, servicesApiUri} from '../common/config/uri-api';
import { SetHeaderToken } from "../common/misc-util";
import { GiftCard, GiftCardPayment, PurchasedGiftCard } from "./gift-card.types";
import {Option} from "../booking/book.types";

export const postGiftcardPurchase = (codeBouton: string, body: GiftCard, token?: string, dryRun: boolean=false): [Promise<GiftCardPayment>, ()=>void] => {
  const controller = new AbortController();
  const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`}
  if (dryRun) {
    headers['Dry-Run'] = 'true'
  }
  const promise = axios.post(`${appointmentsApiUri}/${codeBouton}/me/giftcard`, body, {headers, signal: controller.signal});
  return [promise.then(resp => resp.data), () => controller.abort()];
}

export const getGiftcard = async (codeBouton : string, giftCardId : string, token : string) : Promise<PurchasedGiftCard>=> {
  const response = await axios.get(`${appointmentsApiUri}/${codeBouton}/me/giftcard/${giftCardId}`, SetHeaderToken(token));
  return response.data;
}

export const getGiftcardList = async (codeBouton: string, offset: number, limit: number, token?: string): Promise<Pagination<PurchasedGiftCard>> => {
  const response = await axios.get<Pagination<PurchasedGiftCard>>(`${appointmentsApiUri}/${codeBouton}/me/giftcard?offset=${offset}&limit=${limit}`, SetHeaderToken(token));
  return response.data;
}

export const postValidateGiftCard = async () : Promise<void>=> {
  // const response = await axios.post(`${appointmentsApiUri}/${codeBouton}/me/giftcard/${giftCardId}`, SetHeaderToken(token));
  // return response.data;
  return Promise.resolve()
}

export const getOptionsList = async (codeBouton: string, limit: number, offset: number, serviceId: string, token?: string): Promise<Pagination<Option>> => {
  const response = await axios.get(`${servicesApiUri}/${codeBouton}/options?offset=${offset}&limit=${limit}&serviceId=${serviceId}&type=service`, SetHeaderToken(token))
  return response.data
}

export const renderGiftcard = async (codeBouton: string, id: string, token?: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }
  const response = await axios.get(`${appointmentsApiUri}/${codeBouton}/me/giftcard/${id}/render`, config);
  return response.data
}
