import {FC, ReactNode} from "react";
import {
    BaseButton,
    Checkbox,
    Col,
    LabelWrapper,
    RadioGroup, RadioOption,
    Row,
    SpanBody1,
    SpanSubtitle2,
    Svg, ThemeType
} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {useTheme} from "styled-components";

interface Choice {
    id: string;
    label: string;
    price?: number;
    quantity?: number;
    total?: number;
}

interface OptionsFormContentProps {
    max: number | null;
    choices: Choice[];
    min: number;
    countable: boolean;
    readOnly?: boolean;
    currency: string;
    maxFree?: number;
    extraCharge?: number;
    radioOptions: RadioOption<Choice>[];
    getRadio?: RadioOption<Choice>;
    onRadioChange: (value: RadioOption<Choice>) => void;
    onIncrementClick: (choice: Choice) => void;
    onDecrementClick: (id: string) => void;
    onCheckboxClick: (choice: Choice, index: number) => void
}

export const OptionsFormContent: FC<OptionsFormContentProps> = ({
                                                                    min,
                                                                    countable,
                                                                    max,
                                                                    choices,
                                                                    readOnly,
                                                                    currency,
                                                                    maxFree,
                                                                    radioOptions,
                                                                    getRadio,
                                                                    onRadioChange,
                                                                    onIncrementClick,
                                                                    onDecrementClick,
                                                                    onCheckboxClick,
                                                                    extraCharge
                                                                }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;

    let content: ReactNode
    if (max !== null && max <= 1) {
        content = <>
            <RadioGroup small
                        hasError={choices.filter(e => (e.quantity || 0) === 1).length < 1}
                        label={min > 0 ? intl.formatMessage({
                            id: 'OptionsFormRadioLabel',
                            defaultMessage: 'Vous devez choisir une option'
                        }) : null} groupName={'Ambiance'}
                        value={getRadio}
                        onChange={onRadioChange}
                        options={radioOptions}
                        disabled={readOnly}
                        required={min > 0}/>
        </>
    } else if (max && max > 1) {
        if (countable) {
            content = <>
                <LabelWrapper required={min > 0}
                              hasError={choices.reduce((prev, curr) => prev + (curr.quantity || 0), 0) < min || choices.reduce((prev, curr) => prev + (curr.quantity || 0), 0) > max}
                              small
                              disabled={readOnly}
                              label={min > 0 ? intl.formatMessage({
                                  id: 'OptionsFormCountableLabel',
                                  defaultMessage: 'Combien en voulez-vous?'
                              }) : null}/>
                <Row style={{paddingTop: '16px'}}>
                    {choices.map((choice, index) => {
                        return <Col key={index} columns={[4]}>
                            <Row style={{margin: '0 0 30px 0'}} alignItems={'center'}>
                                {choice.quantity && choice.quantity > 0 ? <>
                                    <BaseButton onClick={() => onDecrementClick(choice.id)}><Svg
                                        icon={'minus-circle-outline'} fill={theme.colors.primary} height={24}
                                        width={24}/></BaseButton>
                                    <SpanSubtitle2 style={{margin: '0 5px'}}>{choice.quantity}</SpanSubtitle2>
                                </> : null}
                                <BaseButton onClick={() => onIncrementClick(choice)}><Svg
                                    icon={'plus-circle-outline'}
                                    fill={choice.quantity && choice.quantity > 0 ? theme.colors.primary : theme.colors.grey60}
                                    height={24}
                                    width={24}/></BaseButton>
                                <SpanBody1
                                    style={{
                                        marginLeft: '10px',
                                        color: choice.quantity && choice.quantity > 0 ? theme.colors.grey30 : theme.colors.grey60
                                    }}>{choice.label + (choice.price !== 0 ? ` (+${intl.formatNumber(choice.price || 0, {
                                    style: 'currency',
                                    currency
                                })})` : maxFree && choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0) >= maxFree ? ` (+${intl.formatNumber(extraCharge || 0, {
                                        style: 'currency',
                                        currency
                                    })})`
                                   : '')}</SpanBody1>
                            </Row>
                        </Col>
                    })}
                </Row>
            </>
        } else {
            content = <>
                <LabelWrapper required={min > 0}
                              hasError={choices.filter(e => (e.quantity || 0) === 1).length < min || choices.filter(e => (e.quantity || 0) === 1).length > max}
                              small
                              label={min > 0 ? intl.formatMessage({
                                  id: 'OptionsFormCheckboxLabel',
                                  defaultMessage: 'Vous devez choisir entre 1 et {max} option(s)'
                              }, {max}) : null}/>
                <Row style={{paddingBottom: '16px'}}>
                    {choices.map((choice, index) => {
                        return <Col key={index} columns={[4]}>
                            <Checkbox
                                onChange={() => onCheckboxClick(choice, index)}
                                readOnly={readOnly}
                                value={!!choice.quantity && choice.quantity === 1}>
                                {choice.label + (choice.price && choice.price !== 0 ? ` (+${intl.formatNumber(choice.price ? choice.price : 0, {
                                    style: 'currency',
                                    currency
                                })})` : '')}</Checkbox>
                        </Col>
                    })}
                </Row>
            </>
        }
    }

    return <>{content}</>
}