import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SpanButton, IconName, Svg } from '@linkeo.com/ui-lib-react';
import { FC } from 'react';
import { useTheme } from 'styled-components';

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 12px 24px;
    width: 134px;
    height: 46px;
    border: 1px solid ${({ theme }) => theme.colors.grey40};
    border-radius: 6px;
    text-decoration: inherit;
`
interface LinkOutlinedProps {
    to: string,
    icon?: IconName
}

export const LinkOutlined: FC<LinkOutlinedProps> = ({ children, to, icon }) => {

    const { colors } = useTheme();

    return <StyledLink to={to}>
        {icon ? <Svg width={20} height={20} icon={icon} fill={colors.grey30}></Svg> : null}
        <SpanButton>{children}</SpanButton>
    </StyledLink>
}

