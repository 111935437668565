import {FC, useState} from "react";
import {RadioOption, SpanBody2} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {updateItemInArray} from "../common/misc-util";
import {OptionsFormContent} from "./options-form-content";

interface Choice {
    id: string;
    label: string;
    price?: number;
    quantity?: number;
}

interface OptionsFormProps {
    name: string;
    choices: Choice[];
    countable: boolean;
    currency: string;
    max: number | null;
    min: number;
    maxFree?: number;
    onChoicesChange?: (choices: Choice[]) => void;
    readOnly?: boolean;
    extraCharge?: number;
}

export const OptionForm: FC<OptionsFormProps> = ({
                                                     name,
                                                     choices,
                                                     currency,
                                                     max,
                                                     min,
                                                     countable,
                                                     maxFree,
                                                     onChoicesChange,
                                                     readOnly,
                                                     extraCharge
                                                 }) => {
    const intl = useIntl();

    const radioOptions = choices.map(choice => {
        return {
            name: choice.label + (choice.price && choice.price !== 0 ? ` (+${intl.formatNumber(choice.price ? choice.price : 0, {
                style: 'currency',
                currency
            })})` : ''),
            data: {id: choice.id, label: choice.label, price: choice.price, quantity: choice.quantity},
            columns: [4],
        }
    })

    const [getRadio, setRadio] = useState<RadioOption<Choice> | undefined>(radioOptions.find(e => e.data.quantity === 1))

    const onCheckboxClick = (choice: Choice, index: number) => {
        if (!onChoicesChange || readOnly) {
            return
        }
        if (!choice.quantity || choice.quantity < 1) {
            onChoicesChange(updateItemInArray(choices, index, {...choice, quantity: 1}))
        } else {
            onChoicesChange(updateItemInArray(choices, index, {...choice, quantity: 0}))
        }
    }

    const onIncrementClick = (choice: Choice) => {
        if (!onChoicesChange || readOnly) {
            return
        }
        let index = choices.findIndex(el => el.id === choice.id)
        if (!choice.quantity || choice.quantity < 1) {
            onChoicesChange(updateItemInArray(choices, index, {...choice, quantity: 1}))
        } else {
            onChoicesChange(updateItemInArray(choices, index, {...choice, quantity: choice.quantity + 1}))
        }
    }

    const onDecrementClick = (id: string) => {
        const choice: Choice | undefined = choices.find(el => el.id === id && el.quantity && el.quantity > 0)
        if (!choice || !choice.quantity || !onChoicesChange || readOnly) {
            return
        }
        let index = choices.findIndex(el => el === choice)
        onChoicesChange(updateItemInArray(choices, index, {...choice, quantity: choice.quantity - 1}))
    }

    const onRadioChange = (value: RadioOption<Choice>) => {
        if (!readOnly) {
            setRadio(value)
            if (onChoicesChange) {
                let newChoices = choices.map(choice => {
                    if (value.data && value.data.id === choice.id) {
                        choice.quantity = 1
                    } else {
                        choice.quantity = 0
                    }
                    return choice
                })
                onChoicesChange(newChoices)
            }
        }
    }

    return <form style={{width: '100%'}}>
        <fieldset style={{border: 0, padding: 0}}>
            <legend><SpanBody2>{name}</SpanBody2></legend>
            <OptionsFormContent
                choices={choices}
                max={max}
                currency={currency}
                min={min}
                countable={countable}
                readOnly={readOnly}
                maxFree={maxFree}
                extraCharge={extraCharge}
                radioOptions={radioOptions}
                getRadio={getRadio}
                onRadioChange={onRadioChange}
                onIncrementClick={onIncrementClick}
                onDecrementClick={onDecrementClick}
                onCheckboxClick={onCheckboxClick}
            />
        </fieldset>
    </form>
}
