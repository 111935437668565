import {FC} from "react";
import {SpanSubtitle1} from "@linkeo.com/ui-lib-react";
import {lighten} from "polished";
import {useTheme} from "styled-components";

interface AvailabilitiesLegendProps {
    legend: string;
    color?: string;
}

export const AvailabilitiesLegend: FC<AvailabilitiesLegendProps> = ({legend, color, children}) => {
    const theme = useTheme();

    return <div style={{display: 'flex', alignItems: 'center'}}>
        {color ? <span style={{
            display: 'inline-block',
            width: '18px',
            height: '18px',
            borderRadius: '3px',
            border: `1px solid ${color}`,
            backgroundColor: lighten(color === theme.colors.success ? 0.45 : 0.26, color),
            marginRight: '5px'
        }}></span> : children}
        <SpanSubtitle1>{legend}</SpanSubtitle1>
    </div>
}