import React, {FC} from "react";
import {Col, Container, Row, SpanSubtitle1, SpanSubtitle2} from "@linkeo.com/ui-lib-react";
import {MenuItem} from "./menu-item";
import {Link} from "react-router-dom";
import {CircledSvg} from "../../circled-svg";

export interface NavItem {
    label: string;
    path: string;
    isActive: boolean;
}

export interface NavigationProps {
    show: boolean,
    authData?: {
        lastName: string
        firstName: string
        email: string
    };
    navItems: NavItem[];
    onNavItemClick?: (item: NavItem) => void;
    onAuthClick?: () => void;
    authButtonTitle?: string;
    accountUrl: string;
}

export const NavigationLarge: FC<NavigationProps> = ({
                                                         show,
                                                         authData,
                                                         navItems,
                                                         authButtonTitle,
                                                         accountUrl
                                                     }) => {
    return show ? <Container size={"lg"} style={{paddingBottom: "0", paddingTop: "0"}}>
        <Row justifyContent={['space-between']} alignItems={['center']}>
            <Col>
                <Row>
                    {
                        navItems.map(nav => {
                            return <MenuItem
                                key={nav.path}
                                active={nav.isActive}
                                label={nav.label}
                                path={nav.path}
                            />

                        })
                    }
                </Row>
            </Col>
            <Col>
                <Row gapColumn={[5]} alignItems={'center'}>
                    {authData ? <Col style={{textAlign: 'right'}}>
                        <div><SpanSubtitle2>{authData.lastName} {authData.firstName}</SpanSubtitle2></div>
                        <SpanSubtitle1>{authData.email}</SpanSubtitle1>
                    </Col> : null}
                    <Col>
                        <Link to={accountUrl} title={authButtonTitle}>
                            <CircledSvg icon={'person-outline'}/>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container> : null;
};