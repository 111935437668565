import React, {FC, useState} from 'react';
import {useBookingStore} from '../booking-store';
import {Container, useToaster} from '@linkeo.com/ui-lib-react';
import {useAuthStore} from '../../customer/authentification/auth-store';
import {useConfigurationStore} from '../../configuration/configuration-store';
import {BookEntry, Booking, giftCardCodesStatus} from '../book.types';
import {routeDetails, routeHome, routeSummary} from '../../routes';
import {AxiosError} from 'axios';
import {generatePath, Navigate, useNavigate} from "react-router";
import {useIntl} from "react-intl";
import {BookEntriesSection} from "../components/book-entries-editor";
import {CardAuthentification} from "../../customer/authentification/components/card-authentification";
import {BookConfirmSection} from '../components/book-confirm-editor';
import {BookPaymentCard} from '../components/book-payment-card';
import {BookingDateSection} from '../components/booking-date-section';
import {PageTemplate} from '../../common/components/page-template';
import {useCodeBouton} from "../../common/providers/code-bouton-provider";

export const BookingConfirmationPage: FC = () => {
    const {
        booking,
        persistBooking,
        finalize,
        bookingPayment,
        checkGiftCardsValidity,
        removeCode,
    } = useBookingStore();
    const {authData, logOut} = useAuthStore();
    const config = useConfigurationStore();
    const codeBouton = useCodeBouton();
    const intl = useIntl();
    const toast = useToaster()
    const navigate = useNavigate();
    const [isPayment, setIsPayment] = useState<boolean>(false)
    const stripePrice = bookingPayment?.stripe
    const giftCards = bookingPayment?.giftcards || []
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const optionsTotal = (booking as Booking).appointments.reduce((price, appointments) =>
        price + (appointments.optionsTotal || 0), 0) + (booking.optionsTotal || 0)
    const optionsCount = (booking as Booking).appointments.reduce((quantity, appointment) =>
        quantity + (appointment.options?.reduce((quantity, option) => quantity + (option.choices.reduce((quantity, choice) =>
            quantity + (choice.quantity || 0), 0) || 0), 0) || 0), 0) + (booking.options?.reduce((quantity, option) =>
        quantity + option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0), 0) || 0)

    let buttonLabel;
    switch (config.appointment.labelButton) {
        case 'Demander un RDV':
            buttonLabel = intl.formatMessage({id: 'bookingButtonLabelRequestAnAppt'})
            break;
        case 'Réserver':
        default:
            buttonLabel = intl.formatMessage({id: 'bookingButtonLabelBook'});
    }

    if (booking.appointments.length === 0) {
        return <Navigate to={generatePath(routeHome, {codeBouton, locale: intl.locale})}/>;
    }

    const onValid = async (notes: string, sms: boolean, email: boolean) => {
        setIsLoading(true)
        try {
            await persistBooking({notes, reminder: {email, sms}});
        } catch (error) {
            setIsLoading(false)
            if ((error as AxiosError).response?.status === 401) {
                toast(intl.formatMessage({
                    id: 'toastMessageSessionInvalide',
                    defaultMessage: 'Votre connexion est expirée, veuillez vous reconnecter'
                }), {type: 'error', outlined: true})
                logOut()
                return;
            }
            toast(intl.formatMessage({id: 'toastMessageBookingFailed', defaultMessage: 'Impossible de réserver'}))
        }
        setIsLoading(false)
    }

    const onCheckingGCValidity = (value: string) => {
        setLoading(true);
        checkGiftCardsValidity(value)
            .catch((error: AxiosError) => {
                if (error.response?.status === 401) {
                    toast(intl.formatMessage({
                        id: 'toastMessageSessionInvalide',
                        defaultMessage: 'Votre connexion est expirée, veuillez vous reconnecter'
                    }), {type: 'error', outlined: true})
                    logOut()
                    return;
                }
                toast(intl.formatMessage({id: 'toastMessageBookingFailed', defaultMessage: 'Impossible de réserver'}))
            }).finally(() => setLoading(false))
    }

    const onRemovingCode = (value: giftCardCodesStatus) => {
        setLoading(true);
        removeCode(value)
            .catch((error: AxiosError) => {
                if (error.response?.status === 401) {
                    toast(intl.formatMessage({
                        id: 'toastMessageSessionInvalide',
                        defaultMessage: 'Votre connexion est expirée, veuillez vous reconnecter'
                    }), {type: 'error', outlined: true})
                    logOut()
                    return;
                }
                toast(intl.formatMessage({id: 'toastMessageError', defaultMessage: 'Une erreur est survenue'}))
            })
            .finally(() => setLoading(false))
    }

    const onPaymentClick = () => {
        if (!(booking as Booking).id) {
            return;
        }
        setLoader(true)
        finalize(giftCards.map(e => e.code))
            .then(() => {
                setIsPayment(true)
                if ((bookingPayment && bookingPayment?.total.toPay <= 0) || !stripePrice) {
                    navigate(generatePath(routeSummary, {
                        bookingId: (booking as Booking).id,
                        codeBouton,
                        locale: intl.locale
                    }));
                }
            })
            .catch((error: AxiosError) => {
                if (error.response?.status === 401) {
                    toast(intl.formatMessage({
                        id: 'toastMessageSessionInvalide',
                        defaultMessage: 'Votre connexion est expirée, veuillez vous reconnecter'
                    }), {type: 'error', outlined: true})
                    logOut()
                    return;
                }
                toast(intl.formatMessage({id: 'toastMessageError', defaultMessage: 'Une erreur est survenue'}))
            })
            .finally(() => setLoader(false))
    }

    return <PageTemplate isLoading={!booking} configuration={config}
                         codeBouton={codeBouton}
                         authData={authData === null ? undefined : authData}>
        {booking ? <>
            <BookEntriesSection
                appointmentDetailsPath={generatePath(routeDetails, {codeBouton, locale: intl.locale})}
                readOnly={true}
                appointments={booking.appointments as BookEntry[]}
                currency={config.currency}
                optionsCount={optionsCount}
                optionsTotal={optionsTotal}
                selectedAppointmentsCount={booking.appointments.length}
                totalPrice={(booking?.total || 0) - optionsTotal}
                allowAgendaSelection={false}
                totalToPay={config.appointment.paymentActive ? booking.totalToPay : undefined}
            />

            <BookingDateSection
                readOnly={true}
                disabled={!!bookingPayment}
                currency={config.currency}
                bookingDate={new Date(booking?.date || '')}
                timezone={config.timezone}
                allowWaitingList={config.appointment.waitingList}
            />

            <Container size={'lg'}>
                <CardAuthentification
                    authData={authData}
                    titleName={intl.formatMessage({id: 'bookingSummaryTitle3', defaultMessage: '3. Identification'})}
                    subtitle={intl.formatMessage({id: 'bookingSummaryAuthSubtitle', defaultMessage: 'Bienvenue !'})}
                    buttonLabel={intl.formatMessage({
                        id: 'bookingSummaryAuthCaptionLogout',
                        defaultMessage: 'Ce n’est pas votre compte ?'
                    })}
                    onClickLogOut={logOut}/>
            </Container>

            {authData ? <>

                <BookConfirmSection
                    authData={authData}
                    sms={booking.reminder.sms}
                    email={booking.reminder.email}
                    notes={booking.notes || ''}
                    count={booking.appointments.length}
                    total={intl.formatNumber((booking as Booking).total || 0, {
                        style: 'currency',
                        currency: config.currency
                    })}
                    onClickConfirmingBooking={onValid}
                    isLoading={isLoading}
                    readOnly={!!bookingPayment}
                    labelButton={buttonLabel}
                />

                {bookingPayment ?

                    <BookPaymentCard
                        bookingId={(booking as Booking).id || ''}
                        count={booking.appointments.length}
                        total={bookingPayment.total.amount || 0}
                        stripePrice={stripePrice}
                        giftCards={giftCards}
                        onCheckingGCValidity={onCheckingGCValidity}
                        onRemovingCode={onRemovingCode}
                        loading={loading}
                        isPayment={isPayment}
                        onPaymentClick={onPaymentClick}
                        loader={loader}
                        isLoading={isLoading}
                        publicKey={config.publicKey || ''}
                        codeBouton={codeBouton}
                        currency={config.currency}
                        details={bookingPayment.total.details}
                        onsitePayment={bookingPayment.onsite?.total?.toPay}
                        paymentActive={config.appointment.paymentActive}
                    />
                    : null}

            </> : null}

        </> : null}

        <div style={{height: '150px'}}></div>
    </PageTemplate>
}
