import {FC, useState} from 'react';
import {CategoryTitle} from './category-title';
import {Card, InOut} from '@linkeo.com/ui-lib-react';
import {ServiceCard} from './service-card';

const percent = 'percent';
const amount = 'amount';
type GiftcardDiscountType = typeof percent | typeof amount;

interface Thumbnails {
    small: string | null;
    medium: string | null;
    large: string | null;
    [`x-large`]: string | null;
    [`xx-large`]: string | null;
}

export interface Attachment {
    id: string | null;
    fileSize: number | null;
    url: string;
    mimeType: string;
    thumbnails: Thumbnails
}

interface Agenda {
    id: string;
    name: string;
}

interface GiftcardDiscount {
    type: GiftcardDiscountType;
    value: number;
    from: string;
    to: string;
    price: number;
}

interface Service {
    agendas: Agenda[];
    description: string;
    duration: number;
    id: string;
    price: number;
    spots: number;
    title: string;
    file?: Attachment;
    giftcardDiscount?: GiftcardDiscount | null,
    downPayment?: number
}

interface BlockCategoryProps {
    categoryTitle: string;
    services: Service[];
    isUniqueSelection?: boolean
    onSelectedService: (service: Service, value: boolean) => void
    selectedCount: number
    selectedServices: Service[];
    currency: string;
    maxDuration: number;
    minDuration: number;
    minPrice: number;
    description?: string;
    paymentActive: boolean
}

export const BlockCategory: FC<BlockCategoryProps> = ({
                                                          categoryTitle,
                                                          services,
                                                          selectedCount,
                                                          onSelectedService,
                                                          selectedServices,
                                                          isUniqueSelection,
                                                          currency,
                                                          maxDuration,
                                                          minDuration,
                                                          minPrice,
                                                          description,
                                                          paymentActive
                                                      }) => {

    const [showCards, setShowCards] = useState<boolean>(!categoryTitle);
    return <div style={{margin: "48px 0"}}>
        {categoryTitle ? <CategoryTitle
            selectedServices={selectedCount}
            title={categoryTitle}
            onChangeOpen={setShowCards}
            open={showCards}
            minDuration={minDuration}
            maxDuration={maxDuration}
            minPrice={minPrice}
            currency={currency}
            description={description}
        /> : null}
        <InOut show={showCards}><Card style={{padding: 0}}>
            {services.map((service) => <ServiceCard
                isUniqueSelection={isUniqueSelection}
                onSelected={(v) => onSelectedService(service, v)}
                selected={selectedServices.findIndex(f => f.id === service.id) >= 0}
                key={service.id}
                uri={service.file?.url}
                title={service.title}
                price={service.price}
                description={service.description}
                duration={service.duration}
                currency={currency}
                downPayment={!isUniqueSelection && paymentActive ? service.downPayment : undefined}
                discountPrice={service.giftcardDiscount ? service.giftcardDiscount.price : undefined}
            />)}
        </Card></InOut>
    </div>
}
