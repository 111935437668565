import {FormattedMessage} from "react-intl";
import {PageTemplate} from "../components/page-template";
import {Container, H2} from "@linkeo.com/ui-lib-react";
import {FC} from "react";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {useCodeBouton} from "../providers/code-bouton-provider";
import {useAuthStore} from "../../customer/authentification/auth-store";

export const TermsOfSalesPage: FC = () => {
    const config = useConfigurationStore();
    const codeBouton = useCodeBouton();
    const authData = useAuthStore()
    return <PageTemplate codeBouton={codeBouton} configuration={config} showNavigation={true} authData={authData.authData||undefined}>
        <Container size={'lg'}>
            <H2><FormattedMessage id={"termsOfSalesPageTitle"}/></H2>
            <FormattedMessage id={"termsOfSales"} />
        </Container>
    </PageTemplate>
}
