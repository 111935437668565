import React, {FC} from 'react';
import {IconName, Svg} from '@linkeo.com/ui-lib-react';
import {useTheme} from 'styled-components';

interface CircledSvgProps {
  icon: IconName
}

export const CircledSvg:FC<CircledSvgProps> = ({icon})=>{
  const theme = useTheme();
  return          <div style={{
    width: 46,
    height: 46,
    border: `${theme.colors.grey30} solid 2px`,
    borderRadius: "50%",
    padding: '4px 0 0 1.5px',
    textAlign:'center'
  }}>
    <Svg icon={icon} fill={theme.colors.grey30} width={32} height={32}/>
  </div>
}