import React, {FC, useEffect} from 'react';
import {Container, Row, SpanH5, useToaster} from '@linkeo.com/ui-lib-react';
import {BookingDateSection} from '../components/booking-date-section';
import {BookEntriesSection} from '../components/book-entries-editor';
import {BookingOptionsCard} from '../../options/booking-options-card';
import {useBookingStore} from '../booking-store';
import {routeAppointment, routeConfirmation, routeDetails, routeHome} from '../../routes';
import {generatePath, Navigate, useNavigate} from "react-router";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {useIntl} from 'react-intl';
import {LinkPrimary} from '../../common/components/link-primary';
import {PageTemplate} from '../../common/components/page-template';
import {BookEntry, Option} from "../book.types";
import {AxiosError} from "axios";
import {reduceChoices, updateItemInArray} from "../../common/misc-util";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";

export const DateSelectionPage: FC = () => {
    const {booking, getAvailabilities, updateBooking} = useBookingStore();
    const intl = useIntl();
    const config = useConfigurationStore();
    const {appointment: {allowAnonymous, waitingList}, currency, timezone} = config
    const {authData} = useAuthStore();
    const navigate = useNavigate();
    const toast = useToaster();
    const codeBouton = useCodeBouton();

    useEffect(() => {
        if (booking.appointments.filter(f => f.selected).length === 0) {
            navigate(generatePath(routeAppointment, {codeBouton, locale: intl.locale}));
        }
    }, [booking, navigate, codeBouton, intl.locale])

    if (!allowAnonymous && !authData) {
        return <Navigate to={generatePath(routeHome, {codeBouton, locale: intl.locale})}/>;
    }

    const onSelectedDate = (date: string, waitingList?: boolean) => {
        const filteredAppointments = booking.appointments.filter(f => f.selected);
        updateBooking({appointments: filteredAppointments, date, waitingList})
            .then(() => navigate(generatePath(routeConfirmation, {codeBouton, locale: intl.locale})))
            .catch((err: AxiosError) => {
                if (err.response?.status === 400) {
                    toast(intl.formatMessage({
                        id: 'DateSelectionPageOptionsErrorMessage',
                        defaultMessage: 'Vous devez sélectionner des options'
                    }))
                } else if (err.response?.status === 409) {
                    toast(intl.formatMessage({
                        id: 'DateSelectionPageErrorMessage',
                        defaultMessage: 'Impossible de réserver à l\'horaire choisi'
                    }))
                } else {
                    toast(intl.formatMessage({id: 'toastMessageError', defaultMessage: 'Une erreur est survenue'}))
                }
            })
    }

    const onOptionChange = (option: Option, index: number) => {
        if (option.max && option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0) > option.max) {
            toast(intl.formatMessage({
                id: 'bookEntryCardMaxToastMessage',
                defaultMessage: 'Vous ne pouvez pas faire plus de {quantity} choix pour cette option'
            }, {quantity: option.max}))
        } else if (option.maxFree && option.choices.reduce((quantity, choice) => quantity + (choice.quantity || 0), 0) - 1 === option.maxFree) {
            toast(intl.formatMessage({
                id: 'bookEntryCardMaxFreeToastMessage',
                defaultMessage: 'Attention, à partir de {quantity} choix, chaque choix supplémentaire vous sera facturé {price} pour cette option'
            }, {
                quantity: option.maxFree, price: intl.formatNumber(option.extraCharge || 0, {
                    style: 'currency',
                    currency
                })
            }))
            updateBooking({
                options: updateItemInArray((booking.options || []), index, option)
            });
        } else {
            updateBooking({
                options: updateItemInArray((booking.options || []), index, option)
            });
        }
    }

    return <PageTemplate configuration={config} isLoading={!booking.options} codeBouton={codeBouton}>
        <BookEntriesSection
            appointmentDetailsPath={generatePath(routeDetails, {codeBouton, locale: intl.locale})}
            totalPrice={(booking.appointments as BookEntry[]).reduce((price, appointment) => price + (appointment.service.price * appointment.spots), 0)}
            readOnly={false}
            selectedAppointmentsCount={booking.appointments.filter((f) => f.selected).length}
            onAppointmentsChange={(appointments) => updateBooking({appointments})}
            appointments={booking.appointments.map(ap => {
                return {
                    ...ap, options: ap.options?.map(option => {
                        return {...option, choices: reduceChoices(option.choices)}
                    })
                }
            })}
            currency={currency}
            enableSwitch={booking.appointments.length > 1}
            allowAgendaSelection={config.appointment.allowSelectAgenda}
        />
        <Container size={"lg"} style={{paddingTop: 0}}>
            <LinkPrimary to={generatePath(routeAppointment, {codeBouton, locale: intl.locale})}
                         icon='plus-circle-fill'>{intl.formatMessage({
                id: 'appointmentPageButtonAddServices',
                defaultMessage: 'Rajouter des prestations'
            })}
            </LinkPrimary>

            {booking.options && booking.options.length > 0 &&
                <BookingOptionsCard options={booking.options.map(option => {
                    return {...option, choices: reduceChoices(option.choices)}
                })}
                                    total={booking.optionsTotal}
                                    currency={currency}
                                    onOptionChange={(choices, index) => {
                                        if (booking.options) {
                                            onOptionChange({...booking.options[index], choices}, index)
                                        }
                                    }}/>}

            <Row justifyContent={'flex-end'} style={{padding: '36px 16px'}}>
                <SpanH5>{intl.formatMessage({
                    id: 'bookingSummaryTotal',
                    defaultMessage: 'Total: {total}'
                }, {
                    total: intl.formatNumber((booking.total || booking.appointments.filter(f => f.selected).reduce((price, appointment) => price + (appointment.service.price * appointment.spots), 0)), {
                        style: 'currency',
                        currency
                    })
                })}</SpanH5>
            </Row>
        </Container>

        <BookingDateSection
            readOnly={false}
            onDateSelected={onSelectedDate}
            selectedDate={booking && booking.date ? new Date(booking.date) : undefined}
            allowWaitingList={waitingList}
            onGettingAvailabilities={getAvailabilities}
            currency={currency}
            timezone={timezone}
        />
    </PageTemplate>
}
