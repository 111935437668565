export const DiscountTypePercent = 'percent'
export const DiscountTypeNumber = 'amount'

export interface StoreProps {
  codeBouton : string,
  token?: string
}

export interface Pagination<T>{
  count: number
  limit: number
  offset: number,
  result: T[],
  total: number
}

export interface Discount {
  type: typeof DiscountTypePercent | typeof DiscountTypeNumber;
  value: number;
}

export interface TotalDetailItem {
  name: string;
  quantity?: number;
  price?: number;
  children?: TotalDetailItem[],
  totalPrice?: number;
  currency?: number;
  toPay?: number;
  downPayment?: number;
  discountAmount?: number;
  discount?: Discount
}

export interface Total {
  amount: number,
  currency: string
  toPay: number,
  details?: TotalDetailItem[]
}

export interface Payment {
  stripe: {
    clientSecret: string,
    total: Total
  },
    onsite: {
    total: Total
  },
    total: Total
}

interface Thumbnails {
  small: string | null;
  medium: string | null;
  large: string | null;
  [`x-large`]: string | null;
  [`xx-large`]: string | null;
}

export interface File {
  id: string | null;
  fileSize: number | null;
  url: string;
  mimeType: string;
  thumbnails: Thumbnails
}