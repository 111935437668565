import React, {createContext, FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {CustomerAuth, CustomerRegister, CustomerUpdate} from './authentification.types';
import {
    customerLogin,
    customerSignup,
    getCustomerInformations,
    refreshCustomerToken,
    resetCustomerPassword,
    sendPasswordResetEmail,
    updateCustomerInformations
} from './authentification-api';
import {StoreProps} from '../../common/commons.types';
import {AxiosError} from "axios";


export interface AuthStorage {
    getAuthData: (codeBouton: string) => CustomerAuth | null;
    setAuthData: (codeBouton: string, data: CustomerAuth | null, stayConnected?: boolean) => void;
    clearAuthData: (codeBouton: string) => void;
}

const STORAGE_KEY = 'authData';
export const defaultAuthStorage: AuthStorage = {
    clearAuthData(codeBouton: string): void {
        sessionStorage.removeItem(`${STORAGE_KEY}_${codeBouton}`);
        localStorage.removeItem(`${STORAGE_KEY}_${codeBouton}`);
    },
    getAuthData(codeBouton: string): CustomerAuth | null {
        const data = sessionStorage.getItem(`${STORAGE_KEY}_${codeBouton}`) || localStorage.getItem(`${STORAGE_KEY}_${codeBouton}`);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    },
    setAuthData(codeBouton: string, data: CustomerAuth | null, stayConnected: boolean | undefined): void {
        if (stayConnected) {
            localStorage.setItem(`${STORAGE_KEY}_${codeBouton}`, JSON.stringify(data));
        } else {
            sessionStorage.setItem(`${STORAGE_KEY}_${codeBouton}`, JSON.stringify(data));
        }
    }
}

interface AuthStoreProps extends StoreProps {
    onTokenChange: (token: string) => void;
    storage?: AuthStorage
}

export interface AuthContextProp {
    logIn: (email: string, password: string, stayConnected: boolean) => Promise<void>;
    register: (customer: CustomerRegister) => Promise<void>;
    resetPassword: (newPassword: string, token: string) => Promise<void>;
    forgotPassword: (email: string) => Promise<void>;
    logOut: () => void;
    authData: CustomerAuth | null;
    updatePersonalInformations: (customer: CustomerUpdate) => Promise<void>;
    authenticateCustomer: (accessToken: string, refreshToken: string)=> Promise<void>;
    updateAdditionalInformation: (birthDate: string, phone: string) => Promise<void>;
}

const notInit = () => {
    console.warn('not init auth context')
}
const notInitPromise = async () => Promise.reject(new Error('not init auth context'))
const AuthContext = createContext<AuthContextProp>(
    {
        authData: {
            email: "",
            accessToken: {token: "", expires: new Date()},
            refreshToken: {token: "", expires: new Date()},
            firstName: "",
            lastName: "",
            phone: "",
            birthDate: "",
            id: ""
        },
        logOut: notInit,
        logIn: notInitPromise,
        register: notInitPromise,
        forgotPassword: notInitPromise,
        resetPassword: notInitPromise,
        updatePersonalInformations: notInitPromise,
        authenticateCustomer: notInitPromise,
        updateAdditionalInformation: notInitPromise
    });
export const useAuthStore = () => useContext(AuthContext);

export const AuthStore: FC<AuthStoreProps> = ({children, codeBouton, onTokenChange, storage = defaultAuthStorage}) => {
    const [authData, setData] = useState<CustomerAuth | null>(null);
    const timeout = useRef<number>()

    const setAuthData = useCallback((data: CustomerAuth | null, stayConnected?: boolean) => {
        setData(data);
        storage.setAuthData(codeBouton, data, stayConnected);
        
        if (data) {
            onTokenChange(data.accessToken.token);
        }

        if (timeout.current) {
            clearInterval(timeout.current)
        }

        if (data) {
            timeout.current = window.setTimeout(() => {
                if (data?.refreshToken) {
                    refreshCustomerToken(codeBouton, data.refreshToken.token).then((response) => {
                        setAuthData(response);
                    }).catch((err: AxiosError) => console.error(err))
                }
            }, new Date(data.accessToken.expires).getTime() - Date.now() - 10000)
        }
    }, [setData, onTokenChange, codeBouton, storage])

    useEffect(() => {
        const data = storage.getAuthData(codeBouton);
        if (!data) {
            return;
        }
        setAuthData(data)
        getCustomerInformations(codeBouton, data.accessToken.token).catch(() => setData(null));
        return () => clearInterval(timeout.current)
    }, [codeBouton, setAuthData, storage])

    const logIn = async (email: string, password: string, stayConnected: boolean) => {
        return customerLogin(codeBouton, email, password).then((response) => {
            setAuthData(response, stayConnected);
        })
    }

    const register = async (customer: CustomerRegister) => {
        return customerSignup(codeBouton, customer).then((response) => {
            setAuthData(response);
        })
    }

    const forgotPassword = async (email: string) => {
        return sendPasswordResetEmail(codeBouton, email)
    }

    const resetPassword = async (newPassword: string, token: string) => {
        return resetCustomerPassword(codeBouton, token, newPassword).then((response) => {
            setAuthData(response)
        })
    }

    const updatePersonalInformations = async (infos: CustomerUpdate): Promise<void> => {
        if (!authData?.accessToken.token) {
            return Promise.reject(new Error('Token not found'));
        }
        await updateCustomerInformations(codeBouton, infos, authData?.accessToken.token);
    }
    const logOut = () => {
        storage.clearAuthData(codeBouton);
        setAuthData(null)
    }
    const authenticateCustomer = async (accessToken: string, refreshToken: string) => {    
        const payloadAccessToken = accessToken?.split('.')[1];
        const decodedPayloadAccessToken = JSON.parse(atob(payloadAccessToken));
        const expirationTimeAccessToken = decodedPayloadAccessToken.exp;
        
        const payloadRefreshToken = refreshToken?.split('.')[1];
        const decodedPayloadRefreshToken = JSON.parse(atob(payloadRefreshToken));
        const expirationTimeRefreshToken = decodedPayloadRefreshToken.exp;

        return getCustomerInformations(codeBouton, accessToken).then((response) => {
            const authDataProvider = {
                email: response.email,
                accessToken: { token: accessToken, expires: new Date(expirationTimeAccessToken * 1000) },
                refreshToken: { token: refreshToken, expires: new Date(expirationTimeRefreshToken * 1000) },
                firstName: response.firstName,
                lastName: response.lastName,
                phone: response.phone,
                birthDate: response.birthDate,
                id: response.id
            };
            setAuthData(authDataProvider, false);
        })
    }
    const updateAdditionalInformation = async (birthDate: string, phone: string) => {
        if (!authData?.accessToken.token) {
            return Promise.reject(new Error('Token not found'));
        }
        const updateAuthData = {...authData, birthDate, phone}

        return updateCustomerInformations(codeBouton, updateAuthData, updateAuthData?.accessToken.token).then((response) => {
            setAuthData(response, false)
        })
    }
    return <AuthContext.Provider
        value={{resetPassword, forgotPassword, logIn, register, authData, logOut, updatePersonalInformations, authenticateCustomer, updateAdditionalInformation}}>
        {children}
    </AuthContext.Provider>;
};
