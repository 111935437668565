import {FC, ReactNode} from 'react';
import {BaseButton, Col, Flex, Hide, InOut, Row, SpanButton, SpanH6, SpanSubtitle1} from '@linkeo.com/ui-lib-react';
import {SmallSvg} from '../../../common/components/small-svg';
import {FormatTime} from '../../../common/components/format-time';
import {Circle} from '../../../common/components/misc';
import {FormattedMessage, useIntl} from 'react-intl';
import {InfoPrice} from '../../../common/components/info-price';

interface CategoryTitleProps {
    onChangeOpen: (v: boolean) => void;
    open: boolean;
    selectedServices: number;
    title: string;
    maxDuration: number;
    minDuration: number;
    minPrice: number;
    currency: string;
    description?: string
}

export const CategoryTitle: FC<CategoryTitleProps> = ({
                                                          open,
                                                          onChangeOpen,
                                                          selectedServices,
                                                          title,
                                                          maxDuration,
                                                          minDuration,
                                                          minPrice,
                                                          currency,
                                                          description
                                                      }) => {
    const getDurationText = (): ReactNode => {
        if (minDuration === 0 && maxDuration === 0) {
            return <></>;
        }
        if (minDuration > 0 && maxDuration > 0 && minDuration !== maxDuration) {
            return <FormattedMessage id={'durationBetween'} values={{
                minDuration: <FormatTime minutes={minDuration}/>,
                maxDuration: <FormatTime minutes={maxDuration}/>
            }}/>
        }
        return <FormatTime minutes={maxDuration || minDuration}/>;
    }

    const intl = useIntl();

    return <>
        <Flex justifyContent={['space-between']} alignItems={['center']} style={{margin: description ? 0 : '24px 0'}}>
            <BaseButton className={'category_button'} onClick={() => onChangeOpen(!open)}>
                <Row gapColumn={[5]}>
                    <Col>
                        <SmallSvg icon={open ? "arrow-ios-downward-outline" : "arrow-ios-forward-outline"}/>
                    </Col>
                    <Col>
                        <SpanH6>{title}</SpanH6>
                    </Col>
                    <Col>
                        <InOut keepContent={true} show={selectedServices > 0}>
                            <Circle>{selectedServices}</Circle>
                        </InOut>
                    </Col>
                </Row>
            </BaseButton>
            <Hide devices={['small', 'xs']}>
                <Row gapColumn={[5]} alignItems={['center']}>
                    {
                        getDurationText()
                            ? <>
                                <Col>
                                    <SmallSvg
                                        icon={"clock-outline"}/>
                                </Col>
                                <Col>
                                    <SpanButton> {getDurationText()}</SpanButton>
                                </Col>
                            </>
                            : null
                    }
                    {
                        minPrice > 0 ? <>
                            <Col>
                                <InfoPrice currency={currency}/>
                            </Col>
                            <Col><SpanButton>
                                <FormattedMessage
                                    id='categoryTitlePriceStart'
                                    defaultMessage='à partir de {minPrice}'
                                    values={{
                                        minPrice: intl.formatNumber(minPrice, {
                                            style: 'currency',
                                            currency: currency
                                        })
                                    }}/>
                            </SpanButton></Col></> : null
                    }
                </Row>
            </Hide>
        </Flex>
        {description
            ? <Flex alignItems={['center']} style={{margin: '12px 0 24px'}}>
                <Col style={{padding: 0}}><SpanSubtitle1>{description}</SpanSubtitle1></Col>
            </Flex>
            : null}
    </>
}
