import {createIntl, createIntlCache, IntlShape, RawIntlProvider} from 'react-intl';
import {createContext, FC, useContext} from 'react';
import frFR from "../lang/fr-FR.json";
import enAU from "../lang/en-AU.json";
import enCA from "../lang/en-CA.json";
import enUS from "../lang/en-US.json";
import frCA from "../lang/fr-CA.json";

const localeRegex = /^([a-z]{2})-([A-Z]{2})$/;

class Locale {
    constructor(
        public readonly language: string,
        public readonly localization: string
    ) {
    }

    public static fromString(locale: string): Locale {
        if (!localeRegex.test(locale)) {
            throw new Error('Invalid locale');
        }
        const [language, localization] = locale.split('-');
        return new Locale(language, localization);
    }

    public toString(): string {
        return `${this.language}-${this.localization}`;
    }

    public toJSON(): string {
        return this.toString();
    }
}

const cache = createIntlCache();

const supportedLanguages = ['fr-FR', 'fr-CA', 'en-US', 'en-AU', 'en-CA'] as const;
type SupportedLocalizedLanguage = typeof supportedLanguages[number];
const LocalizedLanguageMapping: Record<string, typeof supportedLanguages[number]> = {
    'fr': 'fr-FR',
    'en': 'en-US'
} as const

function isLocalizedLanguage(lang: string): lang is SupportedLocalizedLanguage {
    try {
        Locale.fromString(lang);
        return true;
    } catch (e) {
        return false;
    }
}


export type SupportedLanguage = typeof supportedLanguages[number] | keyof typeof LocalizedLanguageMapping;


const getMessage = (language: SupportedLanguage) => {
    const lang: SupportedLocalizedLanguage = isLocalizedLanguage(language) ? language : LocalizedLanguageMapping[language] || 'en-US';
    switch (lang) {
        case 'fr-FR':
            return frFR;
        case 'en-AU':
            return enAU;
        case 'en-CA':
            return enCA;
        case 'fr-CA':
            return frCA;
        default:
        case 'en-US':
            return enUS;

    }
};

interface IntlProviderProps {
    locale?: SupportedLanguage
}

interface IntlContext {
    intl: IntlShape;
    locale: string;
}

const LocalContext = createContext<IntlContext>(
    {
        locale: 'en-US',
        intl: createIntl({
            locale: 'en-US',
            messages: {},
        }, cache),
    },
);

function isSupportedLanguage(locale: string): locale is SupportedLanguage {
    return ((supportedLanguages as unknown) as string[]).includes(locale) || !!(LocalizedLanguageMapping as {
        [k: string]: string
    })[locale]
}

function delocalizeLanguage(lang: SupportedLanguage): string {
    if (isLocalizedLanguage(lang)) {
        return lang.split('-')[0];
    }
    return lang;
}


const detectLanguage = (acceptedLanguages: readonly string[]): SupportedLanguage => {
    let lang: string | undefined = acceptedLanguages.find(isSupportedLanguage);
    if (lang && isSupportedLanguage(lang)) {
        return lang;
    }
    lang = acceptedLanguages.map((lang) => lang.split('-')[0]).find(isSupportedLanguage);
    if (lang && isSupportedLanguage(lang)) {
        return lang;
    }
    return 'en-US';
}

const detectLocalization = (lang: string): string => {
    try {
        const locale = Locale.fromString(lang);
        return locale.localization;
    } catch (e) {
        if (window.Intl) {
            return Intl.NumberFormat().resolvedOptions().locale.split('-')[1];
        }
        return 'FR'
    }
}

export const useLocale = () => useContext(LocalContext);
export const IntlProvider: FC<IntlProviderProps> = ({children, locale: localeProps}) => {
    const detectedLanguage: SupportedLanguage = localeProps ? detectLanguage([localeProps]) : detectLanguage(window.navigator.languages);
    const localization = localeProps ? detectLocalization(localeProps) : detectLocalization(detectedLanguage);
    const locale = new Locale(delocalizeLanguage(detectedLanguage), localization);
    const intl = createIntl({
        locale: locale.toString(),
        messages: getMessage(detectedLanguage),
    }, cache);

    return <LocalContext.Provider value={{locale: locale.toString(), intl}}>
        <RawIntlProvider value={intl}>
            {children}
        </RawIntlProvider>
    </LocalContext.Provider>;
};
