import { Button, Flex, useToaster } from "@linkeo.com/ui-lib-react";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {FC, FormEvent, useMemo} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {useIntl} from "react-intl";

interface PaymentFormProps {
    clientSecret?: string,
    labelButton: string,
    redirectUrl: string,
    publicKey: string,
}

const StripeForm: FC<Omit<PaymentFormProps, 'clientSecret' | 'publicKey'>> = ({ labelButton, redirectUrl }) => {

    const stripe = useStripe();
    const elements = useElements();
    const intl = useIntl();
    const toast = useToaster()

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: redirectUrl
            },
        });

        if (result.error) {
            toast(intl.formatMessage({id: 'paymentErrorMessage', defaultMessage: 'Echec du paiement'}), { type: 'error' })
            console.error(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Flex justifyContent={'center'} style={{marginTop: '32px'}}>
            <Button disabled={!stripe} type={'submit'} colorType={"primary"}>{labelButton}</Button>
        </Flex>
    </form>
}

export const PaymentForm: FC<PaymentFormProps> = ({ clientSecret, labelButton, redirectUrl, publicKey}) => {

    const stripePromise = useMemo(() => loadStripe(publicKey), [publicKey])

    return <Elements options={{ clientSecret, appearance: {theme: 'flat'} }} stripe={stripePromise} >
        <StripeForm labelButton={labelButton} redirectUrl={redirectUrl} />
    </Elements>
}

