import React, {FC} from "react";
import {SpanCaption1} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";
import {useIntl} from "react-intl";
import {Choice} from "../gift-card.types";

interface GiftcardChoicesListProps {
    choices: Choice[]
    currency: string
}

export const GiftcardChoicesList: FC<GiftcardChoicesListProps> = ({
                                                                      choices,
                                                                      currency
                                                                  }) => {

    const {colors} = useTheme();
    const intl = useIntl();

    const content = choices.filter(choice => choice.quantity && choice.quantity > 0).map((choice, index) => {
        return <React.Fragment key={index}>
            {choice.quantity && choice.quantity > 0 ? <SpanCaption1
                style={{
                    fontStyle: 'initial',
                    color: colors.grey50,
                    marginRight: '10px'
                }}>{choice.quantity && choice.quantity > 1 && `x${choice.quantity}`} {choice.label} {choice.total && choice.total > 0 ? `(+${
                intl.formatNumber(choice.total || 0, {
                    style: 'currency',
                    currency
                })
            })` : choice.price && choice.price > 0 ? `(+${
                intl.formatNumber(choice.price || 0, {
                    style: 'currency',
                    currency
                })
            })` : null}</SpanCaption1> : null}
        </React.Fragment>
    })

    return <>{content}</>
}