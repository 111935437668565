import {FC} from 'react';

export const FormatTime: FC<{ minutes: number }> = ({minutes}) => {

  const minuteOrHoursToString = (time: number): string => {
    if (time < 60) {
      return `${time}min`;
    }
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours}h${minutes.toString(10).padStart(2, "0")}`
  }
  return <>
    {minuteOrHoursToString(minutes)}
  </>
}