import React, {FC} from "react";
import {Button} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";
import {ReactComponent as IconFacebook} from "../../icons/facebook.svg"
import {ReactComponent as IconGoogle} from "../../icons/google.svg"


type ColorType = 'primary' | 'outlineSecondary';

interface IconContainerProps {
    marginSide: string;
}

const IconContainer = styled.div<IconContainerProps>`
    display: inline-block;
    line-height: 0.83;
    vertical-align: middle;
    ${({marginSide}) => 'margin-' + marginSide + ': 4px'};
    margin-top: -2px;
    margin-bottom: -1px;
`;

interface ProviderLogoProps {
    provider: string,
    style?: React.CSSProperties
}

const ProviderLogo: FC<ProviderLogoProps> = ({provider, style}) => {
    const theme = useTheme();
    if (provider === "facebook") {
        return <IconFacebook style={style} fill={theme.colors.grey30}/>
    }
    return <IconGoogle style={style} fill={theme.colors.grey30}/>
}

interface BoutonProviderProps {
    provider: string;
    colorType: ColorType;
    color?: string;
    handleClickLogin: (provider: string) => void;
}
export const ProviderButton: FC<BoutonProviderProps> = ({children, provider, colorType, color, handleClickLogin}) => {
    const iconSize = '24px';
    return (
        <Button className={'login_button'} onClick={() => handleClickLogin(provider)} colorType={colorType}
                style={{backgroundColor: color}}>
            <IconContainer marginSide={'right'}>
                <ProviderLogo provider={provider} style={{width: iconSize, height: iconSize}}/>
            </IconContainer>
            {children}
        </Button>
    );
}