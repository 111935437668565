import {FC} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

export const Bar = styled.div<{ active: boolean }>`
  width: 100%;
  height: 2px;
  background: ${({theme, active}) => active ? theme.colors.grey30 : 'transparent'};
  transition: 200ms ease-in-out background;
`;
export const ButtonLink = styled(Link)<{ active?: string }>`
  display: block;
  text-decoration: none;
  margin:0 10px;

  &:hover ${Bar} {
    background: ${({theme, active}) => active ? theme.colors.grey30 : theme.colors.grey60};
  }

  &:active, &:visited {
    color: initial;
  }
`;
const H2 = styled.h2<{ active: boolean }>`
  color : ${({active, theme}) => active ? theme.colors.grey30 : theme.colors.grey60};
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 16px;
`
export const MenuItem: FC<{ active: boolean, label: string, path: string }> = ({active, label, path}) => {

    return <ButtonLink to={path} active={active ? 'true' : undefined}>
        <H2 active={active}>{label}</H2>
        <Bar active={active}/>
    </ButtonLink>
}