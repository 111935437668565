import {FC, ReactNode} from "react";
import {Col, SpanH5} from "@linkeo.com/ui-lib-react";

interface OptionsHeaderProps {
    title: string;
    total: number | ReactNode
}

export const OptionHeader: FC<OptionsHeaderProps> = ({title, total}) => {

    return <>
        <Col>
            <SpanH5>{title}</SpanH5>
        </Col>
        <Col>
            <SpanH5>+{total}</SpanH5>
        </Col>
    </>
}