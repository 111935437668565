import axios from "axios";
import {customerApiUri} from '../../common/config/uri-api';
import {CustomerAuth, CustomerRegister, CustomerUpdate} from './authentification.types';


export const resetCustomerPassword = async (codeBouton: string, resetPasswordToken: string, newPassword: string): Promise<CustomerAuth> => {
    const response = await axios.put<CustomerAuth>(`${customerApiUri}/${codeBouton}/customers/password?token=${resetPasswordToken}`, {
        password: newPassword
    });
    return response.data;
}

export const sendPasswordResetEmail = async (codeBouton: string, email: string): Promise<void> => {
    return axios.post(`${customerApiUri}/${codeBouton}/customers/resetpassword`, {
            email
    });
}

export const customerSignup = async (codeBouton: string, body: CustomerRegister): Promise<CustomerAuth> => {
    const response = await axios.post<CustomerAuth>(`${customerApiUri}/${codeBouton}/customers`, body)
    return response.data;

}

export const customerLogin = async (codeBouton: string, email: string, password: string): Promise<CustomerAuth> => {
    const response = await axios.post<CustomerAuth>(`${customerApiUri}/${codeBouton}/customers/authenticate`, {
        email,
        password
    });
    return response.data;
}

export const updateCustomerInformations = async (codeBouton: string, customer: CustomerUpdate, token?: string): Promise<CustomerAuth> => {
    const response = await axios.put(`${customerApiUri}/${codeBouton}/customers/me`, {...customer}, {headers: {Authorization: `Bearer ${token}`}});
    return response.data;
}


export const getCustomerInformations = async (codeBouton: string, token?: string): Promise<CustomerAuth> => {
    const response = await axios.get(`${customerApiUri}/${codeBouton}/customers/me`, {headers: {Authorization: `Bearer ${token}`}});
    return response.data;
}

export const refreshCustomerToken = async (codeBouton : string, refreshToken: string) : Promise<CustomerAuth> => {
    const response = await axios.post(`${customerApiUri}/${codeBouton}/customers/refresh`, {refreshToken});
    return response.data;
}
