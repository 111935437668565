import {
    Card,
    Container,
    Row,
    useToaster
} from '@linkeo.com/ui-lib-react';
import {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {FormatTime} from '../../common/components/format-time';
import {PageTitleWithCircleCount} from '../../common/components/page-title-with-circle-count';
import {Separation} from '../../common/components/separation';
import {routeAppointment, routeErrorNotFound} from '../../routes';
import {useAuthStore} from '../../customer/authentification/auth-store';
import {AxiosError} from 'axios';
import {useBookingStore} from '../booking-store';
import {generatePath, useNavigate} from "react-router";
import {FormattedMessage, useIntl} from 'react-intl';
import {LinkPrimary} from '../../common/components/link-primary';
import {PageTemplate} from '../../common/components/page-template';
import {useConfigurationStore} from '../../configuration/configuration-store';
import {BookingResponse} from "../booking.api";
import { RowCard } from '../../common/components/row-card';
import {useCodeBouton} from "../../common/providers/code-bouton-provider";

export const BookingSummaryPage: FC = () => {
    const navigate = useNavigate();
    const {bookingId} = useParams<{ bookingId: string }>()
    const [customerBooking, setCustomerBooking] = useState<BookingResponse>()
    const {fetchBooking, clearBooking} = useBookingStore()
    const {authData, logOut} = useAuthStore()
    const config = useConfigurationStore()
    const toast = useToaster()
    const intl = useIntl();
    const codeBouton = useCodeBouton();

    useEffect(() => {
        clearBooking();
        fetchBooking(bookingId || '')
            .then((result) => setCustomerBooking(result))
            .catch((error: AxiosError) => {
                if (error.response?.status === 401) {
                    toast(intl.formatMessage({
                        id: 'toastMessageSessionInvalide',
                        defaultMessage: 'Votre connexion est expirée, veuillez vous reconnecter'
                    }), {type: 'error', outlined: true});
                    logOut();
                    return;
                }
                navigate(generatePath(routeErrorNotFound));
            })
    }, [bookingId, intl, navigate, toast, fetchBooking, clearBooking, logOut])

    /*  const clickCancelAppointment = async (idAppointment: string) => {
        await openConfirm({title: 'Annuler le RDV', content: 'Etes-vous sûr(e) de vouloir annuler ce RDV ?'})
        await cancelAppointment(idAppointment)
      }*/
    if (!authData) {
        return null
    }
    return <PageTemplate configuration={config} isLoading={!customerBooking} codeBouton={codeBouton}
                         authData={authData}>
        {customerBooking ? <Container size={'lg'}>
            <PageTitleWithCircleCount count={customerBooking.appointments.length}>
                <FormattedMessage id='BookingSynthesisTitle' defaultMessage='Synthèse de vos préstations'/>
            </PageTitleWithCircleCount>
            {customerBooking.appointments.map((appointement) =>
                <div key={appointement.service.id}>
                    <Card style={{padding: 24}}>
                        <RowCard
                            caption={<><FormattedMessage id='durationServiceLabel' defaultMessage='Durée: '/><FormatTime
                                minutes={appointement.service.duration}/></>}
                            icon={'pricetags-fill'}
                            titleName={appointement.service.title}
                            subTitle={appointement.agenda ?
                                intl.formatMessage({
                                    id: 'bookEntryCardOptionAgendaName',
                                    defaultMessage: 'avec {name}'
                                }, {name: appointement.agenda.name}) :
                                intl.formatMessage({
                                    id: 'bookEntryCardOptionAgendaNone',
                                    defaultMessage: 'Pas de préférence'
                                })}/>
                        <Separation/>
                        <RowCard
                            caption={intl.formatMessage({
                                    id: 'bookEntryAgendaSummary',
                                    defaultMessage: 'Heure du RDV : {hours} - {number, plural, =1 {1 personne} other {# personnes}}'
                                },
                                {
                                    hours: intl.formatTime(customerBooking.date, {timeStyle: 'short', timeZone: config.timezone ? config.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone}),
                                    number: appointement.spots
                                })}
                            icon={'calendar-fill'}
                            titleName={intl.formatDate(customerBooking.date, {dateStyle: "full", timeZone: config.timezone ? config.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone})}
                        />
                    </Card>
                    <Row justifyContent={['flex-end']} gapColumn={[8]}
                         style={{marginTop: '24px', marginBottom: '24px'}}>
                        {/* <Col>
              <Button small colorType={"outlineSecondary"} icon={'close-outline'} onClick={() => clickCancelAppointment(book.id)}>Annuler ce RDV</Button>
            </Col> */}
                        {/**<Col>
                         <Button small colorType={"outlineSecondary"} icon={'settings-outline'}>Modifier ce RDV</Button>
                         </Col> */}

                    </Row>
                </div>
            )}

            <LinkPrimary to={generatePath(routeAppointment,{codeBouton,locale:intl.locale})} icon={'plus-circle-fill'}>{intl.formatMessage({
                id: 'BookingSummaryAddBookingButton',
                defaultMessage: 'Prendre un nouveau RDV'
            })}</LinkPrimary>
            <div style={{height: '80px'}}></div>

        </Container> : null}
    </PageTemplate>
}
