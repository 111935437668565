import {FC, useState} from "react";
import {BaseButton, Button, Col, Row} from "@linkeo.com/ui-lib-react";
import {FormattedDate, FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import styled from "styled-components";
import {useAccountStore} from "../account-store";
import {useConfigurationStore} from "../../../configuration/configuration-store";
import {AccountCard, AccountPageWrapper, LoadMore} from "../components/common";
import {generatePath, useNavigate} from 'react-router';
import {routeGiftCardDisplay, routeUserAccount} from '../../../routes';
import {useConfirm} from "../../../common/providers/confirm-provider";
import {useAuthStore} from "../../authentification/auth-store";
import {PurchasedGiftCard} from "../account.types";
import {useCodeBouton} from "../../../common/providers/code-bouton-provider";

const Hr = styled.hr`
    border: 1px solid #E2E5E9;
    border-bottom: none;
`
const Bold = styled.span`
    font-weight: 700;
    font-family: 'Open sans', sans-serif;
    font-size: 16px;
    color: #414D58;
`

const Light = styled.span`
    font-weight: 400;
    font-family: 'Open sans', sans-serif;
    font-size: 16px;
    color: #576675;
`
const LightSmall = styled(Light)`
    font-size: 13px;
`;

const RightCol = styled(Col)`
    text-align: right;
`

const MarginRow = styled(Row)`
    padding: 10px 0;
`

const ToggleButton = styled(BaseButton)`
    font-weight: 700;
    font-family: 'Open sans', sans-serif;
    font-size: 13px;
    color: #414D58;
`

interface GiftcardDisplayCardProps {
    expirationDate: Date;
    amountValue: number;
    currency: string
    purchaseDate: Date;
    serviceName: string
    expanded?: boolean;
    onPrintClick?: () => void

}

const GiftcardDisplayCard: FC<GiftcardDisplayCardProps> = ({
                                                               expirationDate,
                                                               amountValue,
                                                               currency,
                                                               purchaseDate,
                                                               serviceName,
                                                               expanded: expandedProps,
                                                               onPrintClick
                                                           }) => {
    const intl = useIntl();
    const [expanded, setExpanded] = useState(expandedProps || false);
    const expandedContent = <>
        <MarginRow style={{justifyContent: "space-between"}}>
            <Col columns={8}>
                <div className="purchase-date">
                    <Light className="label"><FormattedMessage id='giftcardListingCardPurchaseDate'
                                                               defaultMessage='Date d’achat :'/></Light>&nbsp;
                    <Bold className="value"><FormattedDate value={purchaseDate} year="numeric"
                                                           month="2-digit"
                                                           day="2-digit"/></Bold>
                </div>
            </Col>
            <RightCol columns={4}>
                <div className="expiration-date">
                    <Light className="label"><FormattedMessage id='giftcardListingCardExpirationDate'
                                                               defaultMessage={'Date d’expiration :'}/></Light>&nbsp;
                    <Bold className="value"><FormattedDate value={expirationDate} year="numeric"
                                                           month="2-digit"
                                                           day="2-digit"/></Bold>
                </div>
            </RightCol>

        </MarginRow>
        <Hr/>
        <MarginRow style={{justifyContent: 'space-between'}}>
            <Col style={{display: 'flex', justifyContent: 'center', flexGrow: 0}}>
                <Bold><FormattedMessage id='giftcardListingCardStatusLabel'
                                        defaultMessage='Statut du bon cadeau :'/> </Bold>
            </Col>
            <RightCol>
                <Button onClick={() => onPrintClick ? onPrintClick() : void (0)} colorType='primary'><FormattedMessage
                    id='giftcardListingCardEmailLabel'
                    defaultMessage={'Imprimer le bon cadeau'}/> </Button>
            </RightCol>
        </MarginRow>
    </>

    return <AccountCard icon={'gift-outline'}
                        titleName={intl.formatMessage({id: 'giftcardListingCardTitle', defaultMessage: "Bon Cadeau"})}>
        <Hr/>
        <MarginRow>
            <Col columns={10}>
                <div className="title"><Bold><FormattedMessage id='giftcardListingCardServiceName'
                                                               defaultMessage='Bon cadeau - {serviceName}'
                                                               values={{serviceName}}/></Bold></div>
                <div className="validity"><Light><FormattedMessage id='giftcardListingValidityTitle'
                                                                   defaultMessage="Valable jusqu'au {date}"
                                                                   values={{date: intl.formatDate(expirationDate, {dateStyle: "full"})}}/></Light>
                </div>
            </Col>
            <RightCol columns={2}>
                <div className="amount-label">
                    <LightSmall><FormattedMessage id={'giftcardListingCardAmountLabel'} defaultMessage='Valeur du bon'/></LightSmall>
                </div>
                <div className="amount-value">
                    <Bold>
                        <FormattedNumber value={amountValue} style={`currency`} currency={currency}/>
                    </Bold>
                </div>
            </RightCol>
        </MarginRow>
        {expanded ? expandedContent : null}<ToggleButton onClick={() => setExpanded(!expanded)}>
        {expanded ? <FormattedMessage id='giftcardListingCardSeeLessButton' defaultMessage='- Voir moins'/> :
            <FormattedMessage id='giftcardListingCardSeeMoreButton' defaultMessage='+ Voir plus'/>}
    </ToggleButton>
    </AccountCard>
}

export const GiftCardsListingPage: FC = () => {

    const {giftCards, giftCardsCount, loadGiftcards} = useAccountStore();
    const {logOut} = useAuthStore();
    const config = useConfigurationStore();
    const navigate = useNavigate();
    const intl = useIntl();
    const {openConfirm} = useConfirm();
    const codeBouton = useCodeBouton();


    const print = (giftCard: PurchasedGiftCard) => {
        navigate(generatePath(routeGiftCardDisplay, {giftCardId: giftCard.id, codeBouton, locale: intl.locale}));
    }

    const onLogoutClick = async () => {
        await openConfirm({
            content: intl.formatMessage({
                id: 'accountPageLogoutConfirmation',
                defaultMessage: "Êtes-vous sûr(e) ?"
            }), title: intl.formatMessage({id: 'accountPageLogoutText', defaultMessage: "Déconnexion"})
        });
        logOut();
    }

    return <AccountPageWrapper
        backLinkUrl={generatePath(routeUserAccount, {codeBouton, locale: intl.locale})}
        onLogoutClick={onLogoutClick}
        pageTitle={intl.formatMessage({
        id: "accountGiftCardPageTitle",
        defaultMessage: 'Vos bons cadeaux'
    })}>
        <LoadMore total={giftCardsCount || 0} count={giftCards.length} loadMore={loadGiftcards}>

            {giftCards.map((gc) => {
                return <GiftcardDisplayCard expirationDate={new Date(gc.expirationDate)} amountValue={gc.value}
                                            currency={config.currency} purchaseDate={new Date(gc.purchaseDate)}
                                            serviceName={gc.service.title} key={gc.id}
                                            onPrintClick={() => print(gc)}/>
            })}
        </LoadMore>
    </AccountPageWrapper>
}
