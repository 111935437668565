import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Svg, IconName } from '@linkeo.com/ui-lib-react';
import { FC } from 'react';
import { useTheme } from 'styled-components';

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    min-width: 291px;
    max-width: 350px;
    min-height: 46px;
    padding: 12px 24px;
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 6px;
    text-decoration: inherit;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.light};
`

interface LinkPrimaryProps {
    to : string,
    icon? : IconName
}

export const LinkPrimary: FC<LinkPrimaryProps> = ({children, to, icon}) => {

    const {colors} = useTheme();

    return <StyledLink to={to}>
        {icon ? <Svg width={20} height={20} icon={icon} fill={colors.light}></Svg> : null}
        {children}
    </StyledLink>
}