import React, { FC, useEffect, useState } from "react";
import {
    Col,
    Container,
    Flex,
    Row,
    SpanH6,
    SpanLink,
    Svg
} from "@linkeo.com/ui-lib-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { DisplayAddress } from "../common/components/display-address";
import { DisplayPhone } from "../common/components/display-phone-number";
import { Address } from "./configuration.types";
import { imageExists as checkImageExists } from "../common/misc-util";
import { DisabledContent } from "../common/components/disabled-content";

import {Header, ImageHeaderBackground, ImageHeaderLogo, ImageHeaderLogoWrapper} from "../common/components/page/header";

interface ConfigurationAppointmentsDisabledProps {
    phone?: string;
    coverImageUri: string;
    logoUri: string;
    name: string;
    website: string;
    address: Address;
}

export const ConfigurationAppointmentsDisabled: FC<ConfigurationAppointmentsDisabledProps> = ({
                                                                                                phone,
                                                                                                logoUri,
                                                                                                coverImageUri,
                                                                                                name,
                                                                                                website,
                                                                                                address
                                                                                            }) => {
    const theme = useTheme();
    const intl = useIntl();
    const [imageExists, setImageExists] = useState<boolean>(false);

    useEffect(() => {
        if (logoUri) {
            checkImageExists(logoUri).then(exists => setImageExists(exists))
        }
    }, [logoUri])

    return <>
        <Header>
            <ImageHeaderBackground uri={coverImageUri} />
            <ImageHeaderLogoWrapper>
                <ImageHeaderLogo src={imageExists ? logoUri : '/img/no-logo.jpg'} alt={"logo"} />
            </ImageHeaderLogoWrapper>
            <div>
                <Container size={"lg"} style={{ paddingTop: `10px`, paddingBottom: `10px`, textAlign: 'center' }}>
                    <Row justifyContent={['center', 'center', 'space-between']} gapColumn={[4, 6, 12]}>
                        <Col style={{ marginBottom: "10px" }}>
                            <Flex alignItems={['center']} justifyContent={['center']}>
                                <div>
                                    <SpanH6>{name}</SpanH6>
                                </div>
                                <Flex alignItems={['center']}>
                                    <Svg style={{ marginLeft: '10px' }} fill={theme.colors.grey60}
                                        icon={'external-link-outline'}
                                        width={16}
                                        height={16}></Svg>
                                    <a href={website}
                                        target={'_blank'} rel="noreferrer"><SpanLink>
                                            <FormattedMessage id='pageWrapperWebsiteLink'
                                                defaultMessage='Visiter notre site' />
                                        </SpanLink>
                                    </a>
                                </Flex>
                            </Flex>
                        </Col>
                        <Col style={{ marginTop: "5px" }}>
                            <DisplayAddress value={address} />&#32;<DisplayPhone phone={phone || ''} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Header>
        <DisabledContent phone={phone}
                         message={intl.formatMessage({
                                id: 'configurationProviderErrorTitle',
                                defaultMessage: 'La prise de rendez-vous en ligne a été temporairement désactivée.'
                        })} />
    </>
}
