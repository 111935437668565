import {FC} from "react";
import {Col, Row, SpanBody2, SpanSubtitle1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {DiscountTypeNumber, TotalDetailItem} from "../../common/commons.types";
import styled from "styled-components";
import {giftCardCodesStatus, giftCardValid} from "./booking-types";

const booking = 'booking';
const service = 'service';

const Line = styled(Row)`
    width: 100%;
    flex-wrap: nowrap;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey60};
`

interface DetailLineProps {
    title: string;
    additionalTitleInfos?: string;
    price?: number;
    currency: string;
    lighter?: boolean;
    optionsLighter?: boolean;
}

const DetailLine: FC<DetailLineProps> = ({
                                             title,
                                             price,
                                             currency,
                                             additionalTitleInfos,
                                             lighter,
                                             optionsLighter
                                         }) => {

    return <Row>
        <Col style={{flex: 2}}>
            <SpanSubtitle1 style={{fontWeight: lighter ? 'normal' : '600'}}>
                {title}
            </SpanSubtitle1>
            <SpanSubtitle1 style={{fontWeight: '600'}}> {additionalTitleInfos}
            </SpanSubtitle1>
        </Col>
        <Col columns={[2]} style={{textAlign: 'end'}}>
            {price ? <SpanSubtitle1 style={{fontWeight: lighter || optionsLighter ? 'normal' : '600'}}>
                <FormattedNumber value={price} style={'currency'} currency={currency}/>
            </SpanSubtitle1> : null}
        </Col>
    </Row>
}

interface DetailListProps {
    detail: TotalDetailItem,
    currency: string;
    paymentActive: boolean;
}

const DetailList: FC<DetailListProps> = ({detail, currency, paymentActive}) => {
    const intl = useIntl();

    return <Line>
        <Col style={{flex: 2}}>
            {detail.quantity
                ? <DetailLine title={`${detail.quantity}x ${detail.name}`}
                              additionalTitleInfos={detail.downPayment && paymentActive ? `(${intl.formatMessage({
                                  id: 'serviceCardDownPaymentTag',
                                  defaultMessage: 'acompte {value}%'
                              }, {value: detail.downPayment * 100})})` : undefined}
                              currency={currency}
                              price={detail.totalPrice}/>
                : null}
            {detail.children && detail.children.length > 0
                ? <div style={{marginLeft: '10px'}}>
                    <DetailLine lighter
                                title={intl.formatMessage({
                                    id: 'bookingDetailsServiceOnly',
                                    defaultMessage: 'Prestation seule'
                                })} currency={currency}
                                additionalTitleInfos={detail.discountAmount && detail.discount ? intl.formatMessage({
                                    id: 'bookingDetailsSectionDiscount',
                                    defaultMessage: 'Remise -{value}'
                                }, {
                                    value: <FormattedNumber
                                        value={detail.discount.type === DiscountTypeNumber ? detail.discount.value : detail.discount.value / 100}
                                        style={detail.discount.type === DiscountTypeNumber ? "currency" : "percent"}
                                        currency={currency}/>
                                }) as string : undefined}
                                price={detail.price ? detail.price * (detail.quantity || 1) - (detail.discountAmount || 0) : undefined}/>
                    {detail.children.map((el, index) => <OptionDetailList key={index}
                                                                          detail={el} currency={currency}
                                                                          paymentActive={paymentActive}
                                                                          type={service}/>)}
                </div>
                : null}
        </Col>
        {paymentActive && <Col columns={[2]} style={{textAlign: 'end'}}>
            {detail.toPay ? <SpanSubtitle1 style={{fontWeight: 600}}>
                (<FormattedNumber value={detail.toPay} style={'currency'} currency={currency}/>)
            </SpanSubtitle1> : null}
        </Col>}
    </Line>
}

interface OptionDetailListProps extends DetailListProps {
    type: typeof booking | typeof service
}

const OptionDetailList: FC<OptionDetailListProps> = ({detail, currency, type, paymentActive}) => {
    const intl = useIntl();

    return <>
        <DetailLine
            title={detail.name} currency={currency}
            additionalTitleInfos={type === booking && detail.downPayment && detail.totalPrice && detail.totalPrice > 0 && paymentActive ? `(${intl.formatMessage({
                id: 'serviceCardDownPaymentTag',
                defaultMessage: 'acompte {value}%'
            }, {value: detail.downPayment * 100})})` : undefined}
            optionsLighter={type === service}
            price={detail.totalPrice}
        />
        {detail.children && detail.children.map((el, index) => {
            return <DetailLine key={index} lighter
                               title={`${el.quantity}x ${el.name}`}
                               currency={currency}/>
        })}
    </>
}


interface BookingDetailsSectionProps {
    details: TotalDetailItem[];
    currency: string;
    total: number;
    deposit?: number;
    giftCards: giftCardCodesStatus[];
    paymentActive: boolean;
}

export const BookingDetailsSection: FC<BookingDetailsSectionProps> = ({
                                                                          details,
                                                                          currency,
                                                                          total,
                                                                          deposit,
                                                                          giftCards,
                                                                          paymentActive
                                                                      }) => {

    return <Row style={{marginBottom: '24px'}}>
        <Col columns={[12, 12, 4, 4]}>
        </Col>
        <Col columns={[12, 12, 8, 8]}>
            <Row style={{width: '100%', flexWrap: 'nowrap'}}>
                <Col style={{flex: 2}}>
                    <SpanBody2>
                        <FormattedMessage id={'bookingDetailsSectionTitle'}
                                          defaultMessage={'Détails de votre commande'}/>
                    </SpanBody2>
                </Col>
                {paymentActive && <Col columns={[2]} style={{textAlign: 'end'}}>
                    <SpanSubtitle1 style={{fontWeight: 600}}>
                        <FormattedMessage id={'bookingDetailSectionDepositTitle'} defaultMessage={'Acompte'}/>
                    </SpanSubtitle1>
                </Col>}
            </Row>

            <div style={{marginTop: '12px'}}>
                {details.map((el, index) => {
                    if (el.quantity) {
                        return <DetailList detail={el} key={index} currency={currency} paymentActive={paymentActive}/>
                    } else {
                        return <Line key={index}>
                            <Col style={{flex: 2}}>
                                <OptionDetailList detail={el} currency={currency} type={booking}
                                                  paymentActive={paymentActive}/>
                            </Col>
                            {paymentActive && <Col columns={[2]} style={{textAlign: 'end'}}>
                                {el.toPay ? <SpanSubtitle1 style={{fontWeight: 600}}>
                                    (<FormattedNumber value={el.toPay} style={'currency'} currency={currency}/>)
                                </SpanSubtitle1> : null}
                            </Col>}
                        </Line>
                    }
                })}
                {giftCards.filter(c => c.status === giftCardValid).map((giftCard, index) => <Line key={index}>
                    <Col style={{flex: 2}}><SpanSubtitle1>
                        <FormattedMessage id={"bookPaymentCardGiftcardItemLabel"}
                                          defaultMessage={"Bon cadeau {code}"}
                                          values={{code: giftCard.code}}/>
                    </SpanSubtitle1>
                    </Col>
                    <Col columns={[2]} style={{textAlign: 'end'}}>
                        <SpanSubtitle1>
                            <FormattedNumber value={-(giftCard.total?.amount || 0)}
                                             currency={giftCard.total?.currency}
                                             style={"currency"}>

                            </FormattedNumber>
                        </SpanSubtitle1>
                    </Col>
                    <Col columns={[2]}></Col>
                </Line>)}
                <Row style={{width: '100%', flexWrap: 'nowrap'}}>
                    <Col style={{flex: 2, textAlign: 'end'}}>
                        <SpanSubtitle1 style={{fontWeight: 600}}>
                            <FormattedMessage id={'bookPaymentCardTotal'} defaultMessage={"Total"}/> :
                        </SpanSubtitle1>
                    </Col>
                    <Col columns={[2]} style={{textAlign: 'end'}}>
                        <SpanSubtitle1 style={{fontWeight: 600}}>
                            <FormattedNumber value={total} style={'currency'} currency={currency}/>
                        </SpanSubtitle1>
                    </Col>
                    {paymentActive && <Col columns={[2]} style={{textAlign: 'end'}}>
                        {deposit && <SpanSubtitle1 style={{fontWeight: 600}}>
                            (<FormattedNumber value={deposit} style={'currency'} currency={currency}/>)
                        </SpanSubtitle1>}
                    </Col>}
                </Row>
            </div>
        </Col>
    </Row>
}
