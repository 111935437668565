import React, {FC} from "react";
import {Choice, Option} from "./options-types";
import {Card, Row, SpanLink} from "@linkeo.com/ui-lib-react";
import {OptionHeader} from "./options-header";
import {OptionForm} from "./options-form";
import {FormattedMessage, useIntl} from "react-intl";
import {OptionsList} from "./options-list";
import {routeDateSelection} from "../routes";
import {Link} from "react-router-dom";
import {generatePath} from "react-router";
import {useCodeBouton} from "../common/providers/code-bouton-provider";

interface BookingOptionsCardProps {
    options?: Option[];
    currency: string;
    onOptionChange?: (choices: Choice[], index: number) => void;
    total?: number;
    readOnly?: boolean
}

export const BookingOptionsCard: FC<BookingOptionsCardProps> = ({
                                                                    options,
                                                                    currency,
                                                                    onOptionChange,
                                                                    total,
                                                                    readOnly
                                                                }) => {
    const intl = useIntl();
    const codeBouton = useCodeBouton();

    return <>
        <Row justifyContent={'space-between'} style={{padding: '64px 0 24px 0'}}>
            <OptionHeader title={intl.formatMessage({
                id: 'BookingOptionsTitle',
                defaultMessage: 'Options complémentaires'
            })} total={intl.formatNumber(total || 0, {
                style: 'currency',
                currency
            })}
            />
        </Row>
        {options && <Card style={{padding: 0}}>
            {onOptionChange ? options.map((option, index) => {
                return <Row key={index} style={{
                    borderTop: index === 0 ? undefined : '1px solid #E2E6E9',
                    width: '94%',
                    marginLeft: '24px',
                    paddingTop: '24px'
                }}><OptionForm {...option} currency={currency}
                               onChoicesChange={(choices) => onOptionChange(choices, index)}/>
                </Row>
            }) : options.map((option, index) => <OptionsList {...option} key={index} currency={currency}
                                                             index={index}/>)}
        </Card>}

        {!readOnly && !onOptionChange &&
            <Row justifyContent={'right'} style={{width: '100%', margin: '16px 0 0'}}><Link to={(generatePath(routeDateSelection,{codeBouton,locale:intl.locale}))}>
                <SpanLink>
                    <FormattedMessage id='bookingOptionsCardLinkTitle' defaultMessage='Modifier mes options'/>
                </SpanLink>
            </Link></Row>}

    </>
}
