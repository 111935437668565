import { Flex, H6, SpanH1, Svg } from '@linkeo.com/ui-lib-react';
import { useTheme } from 'styled-components';
import React, { FC } from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import { LinkOutlined } from '../components/link-outlined';
import { useConfigurationStore } from '../../configuration/configuration-store';
import { PageTemplate } from '../components/page-template';
import {generatePath} from "react-router";
import {routeHome} from "../../routes";
import {useCodeBouton} from "../providers/code-bouton-provider";

export const Page404: FC = () => {
  const theme = useTheme();
  const config = useConfigurationStore();
  const codeBouton = useCodeBouton();
  const intl = useIntl();
  
  return (
    <PageTemplate configuration={config} codeBouton={codeBouton}>
      <Flex justifyContent={['center']} style={{ paddingTop: '100px' }}>
        <div style={{ textAlign: 'center' }}>
          <Svg icon={'unhappy'} width={36} height={36} fill={theme.colors.grey60} />
          <h1>
            <SpanH1>404</SpanH1>
          </h1>
          <H6 style={{margin: '0 0 32px 0'}}>
            <FormattedMessage id='404Subtitle' defaultMessage='La page que vous recherchez{br}semble introuvable.' values={{br: <br />}}/>
          </H6>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <LinkOutlined to={generatePath(routeHome, {codeBouton, locale: intl.locale})}><FormattedMessage id='404ButtonReturn' defaultMessage='Retour à l’accueil' /></LinkOutlined>
          </div>
        </div>
      </Flex>
    </PageTemplate>
  );
};
