import {Pagination} from '../common/commons.types';
import {Service} from './service.types';
import axios, {AxiosRequestConfig} from 'axios';
import {appointmentsApiUri} from '../common/config/uri-api';

export const getServiceList = async (codeBouton: string, offset: number, limit: number, token?: string, giftable?: boolean): Promise<Pagination<Service>> => {
  let config: AxiosRequestConfig | undefined;
  if (token) {
    config = {headers: {Authorization: `Bearer ${token}`}};
  }
  const response = await axios.get<Pagination<Service>>(`${appointmentsApiUri}/${codeBouton}/services?offset=${offset}&limit=${limit}${giftable ? '&giftable=true' : ''}`, config);
  return response.data;
}