import { Discount } from "../../common/commons.types";

export const statusValid = 'VALIDE'
export const statusToValid = 'A_VALIDER'
export const statusWaiting = 'ATTENTE'

export type AppointmentStatus = typeof statusValid | typeof statusToValid | typeof statusWaiting

interface Choice {
    id: string;
    label: string;
    price?: number;
    quantity?: number;
}

interface Option {
    id: string;
    name: string;
    type: string;
    min: number;
    max: number | null;
    countable: boolean;
    extraCharge?: number;
    maxExtra?: number;
    choices: Choice[]
}

interface Agenda {
    id: string;
    name: string;
}

export interface Service {
    agendas: Agenda[];
    description: string;
    duration: number;
    id: string;
    price: number;
    spots: number;
    title: string;
    options?: Option[];
    downPayment?: number
}

type ContactMediaType = "sms" | "email";
type BookingReminder = {
    [key in ContactMediaType] : boolean
}

export interface Appointment {
    id: string;
    service: Service;
    spots: number;
    agenda?: Agenda | null;
    options?: Option[]
    date: string,
    price: number,
    priceToPay: number,
    reminder: BookingReminder,
    discount: Discount,
    status: AppointmentStatus
}

export interface CustomerUpdate{
    email: string,
    firstName: string,
    lastName: string,
    phone: string
}

export interface Token {
    token: string,
    expires : Date
}

export interface CustomerAuth{
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    accessToken: Token,
    refreshToken: Token,
    id: string
}

export interface GiftCard {
    email: string
    message: string
    service: Service
    giftedTo: string
}

export interface PurchasedGiftCard extends GiftCard {
    id: string,
    code: string,
    name: string,
    value: number,
    expirationDate: string,
    purchaseDate: string
}