import {Configuration, VersionConfiguration} from './configuration.types';
import axios from 'axios';
import {configurationApiUri} from '../common/config/uri-api';

export const  getAppConfiguration = async (codeBouton: string): Promise<Configuration> =>{
  const response = await axios.get(`${configurationApiUri}/${codeBouton}/configuration`);
  return response.data;
}

export const getVersionConfiguration = async (codeBouton: string): Promise<VersionConfiguration> => {
  const response = await axios.get(`${configurationApiUri}/${codeBouton}/configuration/info`);
  return response.data
}