import {Card, Flex, H5, PageTitle, Row, SpanBody2, SpanLink} from "@linkeo.com/ui-lib-react"
import {FC} from "react"
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from "react-router-dom"
import {PriceAndTime} from "../../common/components/price-and-time"
import {OptionHeader} from "../../options/options-header";
import styled from "styled-components";
import {Option} from "../../options/options-types";
import {OptionForm} from "../../options/options-form";

const OptionsElement = styled(Row)`
    border-top: 1px solid #E2E6E9;
    background: rgba(0, 0, 0, 0.02);
`

interface GiftCardEntrySectionProps {
    currency: string;
    duration: number;
    price: number;
    title: string;
    optionsTotal?: number;
    options: Option[];
    onOptionsChange: (option: Option, index: number) => void;
    total: number;
    readOnly: boolean;
    discountPrice?: number,
    editServicesPath: string
}

export const GiftCardEntrySection: FC<GiftCardEntrySectionProps> = ({
                                                                        currency,
                                                                        duration,
                                                                        price,
                                                                        title,
                                                                        optionsTotal,
                                                                        options,
                                                                        onOptionsChange,
                                                                        total,
                                                                        readOnly,
                                                                        discountPrice, editServicesPath
                                                                    }) => {

    const intl = useIntl();

    return <>
        <PageTitle style={{margin: 0}}>
            <FormattedMessage id='bookingSummaryTitle' defaultMessage='1. Vos préstations'/>
        </PageTitle>
        <Card style={{padding: '14px 14px 0 14px', margin: '24px 0'}}>
            <Row gapColumn={[8]} alignItems={['center']} style={{padding: '10px 10px 24px 10px'}}>
                <PriceAndTime
                    duration={duration}
                    currency={currency}
                    discountPrice={discountPrice}
                    price={price}>
                    <SpanBody2>{title}</SpanBody2>
                </PriceAndTime>
            </Row>
            {options.length > 0 && <OptionsElement>
                <Row justifyContent={'space-between'} style={{padding: '26px'}}>
                    <OptionHeader
                        title={intl.formatMessage({id: 'BookEntryCardOptionsTitle', defaultMessage: 'Options'})}
                        total={intl.formatNumber((optionsTotal || 0), {
                            style: 'currency',
                            currency
                        })}
                    />
                </Row>
                {options.map((option, i) => {
                        return <Row key={i}
                                    style={{
                                        borderTop: '1px solid #E2E6E9',
                                        width: '94%',
                                        margin: '0 26px',
                                        paddingTop: '24px'
                                    }}>
                            <OptionForm {...option} currency={currency} readOnly={readOnly}
                                        onChoicesChange={(choices) => onOptionsChange({...option, choices}, i)}/>
                        </Row>
                    }
                )}
            </OptionsElement>}
        </Card>
        <Flex justifyContent={['flex-end']}>
            <div style={{textAlign: 'right'}}>

                <Link to={editServicesPath}><SpanLink>
                    <FormattedMessage id='giftCardConfirmationPageUpdateBookingLabel'
                                      defaultMessage='Modifier mes prestations'/>
                </SpanLink></Link>

                <H5 style={{marginTop: '24px'}}>
                    <FormattedMessage id='bookingSummaryTotal' defaultMessage='Total: {total}' values={{
                        total: intl.formatNumber(total, {
                            style: 'currency',
                            currency: currency
                        })
                    }}/>
                </H5>
            </div>
        </Flex>
    </>
}