import React, {FC, useEffect, useState} from 'react';
import {Flex, InOut, Svg} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";
import {BlockCategory} from "../components/service/block-category";
import {useBookingStore} from '../booking-store';
import {useServicesStore} from '../../service/services-store';
import {routeDateSelection} from '../../routes';
import {useConfigurationStore} from "../../configuration/configuration-store";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {AuthenticationPage} from "../../customer/authentification/pages/authentication-page";
import {FormattedMessage, useIntl} from 'react-intl';
import {LinkPrimary} from '../../common/components/link-primary';
import {ServiceSelectionSection} from '../components/service/service-selection-section';
import {PageTemplate} from '../../common/components/page-template';
import {Service} from '../book.types';
import { CreateDebounce } from '../../common/misc-util';
import { delayService } from '../../common/config/debounce-time';
import {generatePath} from "react-router";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";

const BarFix = styled.div`
  position: fixed;
  bottom: 0;
  background: rgba(247, 247, 247, 0.8);
  padding: 30px 0;
  width: 100%;
`;
export const ServiceSelectionPage: FC = () => {
    const {booking, calculSelectedCount, setServices, cancelPaymentBooking} = useBookingStore();
    const config = useConfigurationStore();
    const {appointment: {allowAnonymous, paymentActive}} = config
    const {authData} = useAuthStore();
    const intl = useIntl();
    const theme = useTheme();
    const {categories, fetchData} = useServicesStore();
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedServices, setSelectedServices] = useState<Service[]>(booking.appointments.filter(f=>f.selected).map(ap => ap.service) || [])
    const codeBouton = useCodeBouton();

    useEffect(() => {
        cancelPaymentBooking();
        fetchData().catch((err) => console.error(err))
    }, [fetchData, cancelPaymentBooking])


    if (!allowAnonymous && !authData) {
        return <AuthenticationPage/>
    }

    const debounceSetSelectedServices = CreateDebounce((services:Service[])=> {
        setServices(services).finally(()=>setLoading(false));
    }, delayService)

    const selectService=(services:Service[])=>{
        setSelectedServices(services);
            debounceSetSelectedServices(services).catch(err => console.error(err))
    };
    const onServiceSelection = (service: Service) => {
        setLoading(true);
        const find = selectedServices.find(el => el.id === service.id)
        if (find) {
            selectService(selectedServices.filter(el => el.id !== find.id))
        } else {
            selectService([...selectedServices, service])
        }
    }

    return <PageTemplate configuration={config} isLoading={!categories} codeBouton={codeBouton}>
        {categories ? <ServiceSelectionSection title={intl.formatMessage({
            id: 'servicesSelectionSubtitle',
            defaultMessage: 'Choisissez et sélectionnez la(les) prestation(s) pour lesquelle(s) vous souhaitez prendre RDV'
        })}>
            {Object.keys(categories).sort().map((cat) => <BlockCategory
                onSelectedService={onServiceSelection}
                selectedServices={selectedServices}
                key={cat} categoryTitle={cat}
                services={categories[cat].services}
                minPrice={categories[cat].minPrice}
                maxDuration={categories[cat].maxDuration}
                minDuration={categories[cat].minDuration}
                selectedCount={calculSelectedCount(categories[cat].services)}
                description={categories[cat].description}
                currency={config.currency}
                paymentActive={paymentActive}
            />)}
        </ServiceSelectionSection> : null}
        <div style={{marginBottom: 90}}/>
        <InOut show={selectedServices.length > 0}>
            <BarFix>
                <Flex justifyContent={['center']} className={'service_selection_button'}>
                    <LinkPrimary to={generatePath(routeDateSelection,{codeBouton,locale:intl.locale})}>
                        {loading ? <Svg animatedIcon={"spinner"} height={'24px'} stroke={theme.colors.light}/>
                            : <FormattedMessage
                                id='servicesSelectionButtonConfirmTake'
                                defaultMessage={'Prendre RDV ({count,plural, =1 {1 prestation} other {# prestations}})'}
                                values={{count: selectedServices.length}}/>}
                    </LinkPrimary>
                </Flex>
            </BarFix>
        </InOut>
    </PageTemplate>

}
