import styled, {useTheme} from "styled-components";
import React, {FC, useState} from "react";
import {NavigationProps} from "./navigation-large";
import {BaseButton, Col, Container, InOut, Keyframes, Row, SpanSubtitle2, Svg} from "@linkeo.com/ui-lib-react";
import {MenuItem} from "./menu-item";

const Wrapper = styled.div`
  position: relative;
  border-top: 1px solid #F0F2F4;
  border-bottom: 1px solid #F0F2F4;
`;
const LineVertical = styled.div`
  background-color: ${({theme: {colors}}) => colors.grey95};
  width: 1px;
  height: 48px;
  display: inline-block;
  margin: 0 8px;
`
export const NavigationSmall: FC<NavigationProps> = ({
                                                         authData,
                                                         onAuthClick,
                                                         navItems,
                                                     }) => {

    const [show, setShow] = useState<boolean>(false)
    const {colors} = useTheme();

    return <Wrapper>
        <Container size={'lg'} style={{
            paddingTop: 0,
            paddingBottom: 0,
            borderBottom: `1px solid ${colors.grey95}`,
            backgroundColor: colors.light
        }}>
            <Row gapColumn={3} justifyContent={'space-between'}>
                <Col>
                    <BaseButton onClick={() => setShow(!show)} title={'Ouvrir le menu'}><Svg icon='menu-outline'
                                                                                             width={'32px'}
                                                                                             fill={colors.grey30}></Svg></BaseButton>
                    <LineVertical/>
                </Col>
                <Col>
                    <Row gapColumn={3} alignItems={'center'}>
                        {authData ? <Col>
                            <SpanSubtitle2>{authData.lastName} {authData.firstName}</SpanSubtitle2>
                        </Col> : null}
                        <Col>
                            <LineVertical/>
                            <BaseButton onClick={() => onAuthClick ? onAuthClick() : null} title={'Mon compte'}>
                                <Svg icon='person-outline' width={'32px'} fill={colors.grey30}></Svg>
                            </BaseButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        <InOut show={show} enter={{keyframes: Keyframes.slideInFrom('0', '-50px')}}
               exit={{keyframes: Keyframes.slideOutFrom('0', '-50px')}}>
            <Container size={'lg'} style={{height: '100vh', backgroundColor: colors.light}}>
                {
                    navItems.map(nav => {
                        return <MenuItem
                            key={nav.path}
                            active={nav.isActive}
                            label={nav.label}
                            path={nav.path}
                        />
                    })
                }
            </Container>
        </InOut>

    </Wrapper>
}