import React, {FC} from 'react';
import {useBookingStore} from '../booking-store';
import {Col, Container, H4, Row} from '@linkeo.com/ui-lib-react';
import {PageTitleWithCircleCount} from '../../common/components/page-title-with-circle-count';
import {BookEntryCard} from '../components/book-entry-card';
import {routeAppointment, routeConfirmation, routeHome} from '../../routes';
import {generatePath, Navigate} from "react-router";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {FormattedMessage, useIntl} from 'react-intl';
import {LinkPrimary} from '../../common/components/link-primary';
import {LinkOutlined} from '../../common/components/link-outlined';
import {PageTemplate} from '../../common/components/page-template';
import {Booking} from "../book.types";
import {BookingOptionsCard} from "../../options/booking-options-card";
import {reduceChoices} from "../../common/misc-util";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";

export const BookingDetailPage: FC = () => {
    const {booking, bookingPayment} = useBookingStore();
    const {authData} = useAuthStore();
    const intl = useIntl();
    const config = useConfigurationStore();
    const {appointment: {allowAnonymous}, currency} = config
    const codeBouton = useCodeBouton();

    if ((!allowAnonymous && !authData) || booking.appointments.length === 0) {
        return <Navigate to={generatePath(routeHome, {codeBouton, locale: intl.locale})}/>;
    }

    return <PageTemplate configuration={config} isLoading={!booking}
                         codeBouton={codeBouton}
                         authData={authData === null ? undefined : authData}>
        <Container size={'lg'}>
            <LinkOutlined to={generatePath(routeConfirmation, {codeBouton, locale: intl.locale})}
                          icon={"arrow-ios-back-outline"}
            >{intl.formatMessage({
                id: 'goBackButtonLabel',
                defaultMessage: 'Retour'
            })}</LinkOutlined>
            <PageTitleWithCircleCount count={booking.appointments.length}>
                <FormattedMessage id='BookingDetailsTitle' defaultMessage='Détail de vos préstations'/>
            </PageTitleWithCircleCount>
            {booking ? (booking as Booking).appointments.map((bookEntry, i) => <BookEntryCard
                key={i}
                agendas={bookEntry.service.agendas}
                serviceTitle={bookEntry.service.title}
                servicePrice={bookEntry.service.price}
                serviceDuration={bookEntry.service.duration}
                maxSpots={bookEntry.service.spots}
                enableSwitch={booking.appointments.length > 1}
                bookEntry={bookEntry}
                currency={currency}
                total={bookEntry.optionsTotal || 0}
            />) : null}

            {!bookingPayment ? <LinkPrimary
                to={generatePath(routeAppointment, {codeBouton, locale: intl.locale})}
                icon={'plus-circle-fill'}>
                {intl.formatMessage({
                    id: 'appointmentPageButtonAddServices',
                    defaultMessage: 'Rajouter des prestations'
                })}
            </LinkPrimary> : null}

            {booking.options && booking.options.length > 0 &&
                <BookingOptionsCard options={booking.options.map(option => {
                    return {...option, choices: reduceChoices(option.choices)}
                })} total={booking.optionsTotal} currency={currency} readOnly={!!bookingPayment}/>}

            <Row justifyContent={['flex-end']} style={{marginTop: '64px'}}>
                <Col>
                    <H4>
                        <FormattedMessage id='bookingSummaryTotal' defaultMessage='Total: {total}' values={{
                            total: intl.formatNumber((booking as Booking).total || 0, {
                                style: 'currency',
                                currency: currency
                            })
                        }}/>
                    </H4>
                </Col>
            </Row>
        </Container>
    </PageTemplate>
}
