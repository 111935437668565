import {FC, useCallback, useEffect, useState} from "react";
import {LoadingContent} from "@linkeo.com/ui-lib-react";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {useParams} from "react-router-dom";
import {useGiftCardStore} from "../gift-card-store";
import {AuthenticationPage} from "../../customer/authentification/pages/authentication-page";
import {ErrorPage} from "../../common/pages/error-page";

export const GiftCardDisplayPage: FC = () => {
    const {giftCardId} = useParams<{ giftCardId: string }>();
    const {authData} = useAuthStore()
    const {fetchGiftcardDisplay} = useGiftCardStore();
    const [dataUri, setDataUri] = useState<string | null>(null);
    const [error, setError] = useState<boolean>(false);

    const fetchGiftcard = useCallback(async (giftCardId: string) => {
        try {
            const blob = await fetchGiftcardDisplay(giftCardId);
            setDataUri(await convertBlobToDataUri(blob));
        } catch (e) {
            console.error(e);
            setError(true);
        }
    }, [fetchGiftcardDisplay]);

    useEffect(() => {
        if (giftCardId) {
            fetchGiftcard(giftCardId)
        }
    }, [fetchGiftcard, giftCardId])

    const convertBlobToDataUri = (blob: Blob): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result) {
                    resolve(reader.result as string);
                } else {
                    reject(new Error('Failed to convert blob to data URI'));
                }
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    if (giftCardId === undefined) {
        return <ErrorPage/>
    }

    if (!authData) {
        return <AuthenticationPage/>
    }

    return !error ? <LoadingContent isLoading={!dataUri}>
        <div style={{height: '100vh', overflow: 'hidden'}}>
        {dataUri && <iframe id={'iframe'} src={dataUri} width="100%" height="100%"
                            title="Giftcard Display"></iframe>}
        </div>
    </LoadingContent> : <ErrorPage/>
}
