import React, {createContext, FC, ReactNode, useContext} from 'react';

const CodeBoutonContext = createContext<string>('');
export const useCodeBouton = () => useContext(CodeBoutonContext);
export const CodeboutonProvider: FC<{ children: ReactNode, codeBouton: string }> = ({children, codeBouton}) => {
    return <CodeBoutonContext.Provider
        value={codeBouton}>
        {children}
    </CodeBoutonContext.Provider>;
};
