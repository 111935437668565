import React, {FC} from 'react';
import {Svg} from '@linkeo.com/ui-lib-react';
import {useTheme} from 'styled-components';


export const CircledCheck: FC = () => {
  const theme = useTheme();
  return <div style={{
    width: 30,
    height: 30,
    backgroundColor: theme.colors.primary,
    borderRadius: "50%",
    textAlign: 'center',
    paddingTop: '2px'
  }}>
    <Svg icon={'checkmark-outline'} fill={theme.colors.light} width={25} height={25}/>
  </div>
}