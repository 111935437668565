import React from 'react';
import {ErrorPage} from './pages/error-page';

export class ErrorBoundary extends React.PureComponent<{}, { hasError: boolean }> {

  componentDidCatch(): void {
    this.setState({hasError: true});
  }

  render(): any {
    if (this.state.hasError) {
      return <ErrorPage/>;
    }
    return this.props.children;
  }

  state = {hasError: false};
}