import { GiftCard } from "./gift-card.types";
import { Service } from "../service/service.types";
import {Option} from "../options/options-types";

const SelectGiftActionType = "selectGift";

interface SelectGiftAction {
    type: typeof SelectGiftActionType;
    service: Service;
    options: Option[];
    value: number;
}

const UpdateGiftActionType = "updateGift";

interface UpdateGiftAction {
    type: typeof UpdateGiftActionType;
    update: Partial<GiftCard>;
}

const ClearGiftActionType = 'clearGift';

interface ClearGiftAction {
    type: typeof ClearGiftActionType;
}

// type GiftActionType = typeof UpdateGiftActionType | typeof SelectGiftActionType;
export type GiftAction = UpdateGiftAction | SelectGiftAction | ClearGiftAction;

export const giftActionReducer = (state: GiftCard | null, action: GiftAction): GiftCard | null => {
    switch (action.type) {
        case UpdateGiftActionType:
            return state? {...state,...action.update} : null;
        case SelectGiftActionType:
            return  {email: '', giftedTo: '', message: '', service:action.service, options:action.options, value: action.value};
        case ClearGiftActionType:
            return null;
        default:
            throw new Error("unexpected action");
    }
}