import {Card, InOut, PageTitle} from "@linkeo.com/ui-lib-react"
import {FC} from "react"
import {FormattedMessage, useIntl} from 'react-intl';
import {generatePath} from "react-router-dom";
import {useLocale} from "../../common/providers/intl.provider";
import {PaymentForm} from "../../payment-stripe/payment-form"
import {routeGiftCardPaymentValidation} from "../../routes";

interface GiftCardPaymentSectionProps {
    publicKey: string;
    clientSecret?: string;
    amount?: number;
    currency: string;
    giftCardId?: string;
    codeBouton: string
}

export const GiftCardPaymentSection: FC<GiftCardPaymentSectionProps> = ({
                                                                            publicKey,
                                                                            clientSecret,
                                                                            amount,
                                                                            currency,
                                                                            giftCardId,
                                                                            codeBouton
                                                                        }) => {

    const {locale} = useLocale();
    const intl = useIntl();

    return <InOut show={true} autoScroll startTriggering>
        <PageTitle
            style={{margin: '64px 0 24px'}}>
            <FormattedMessage id='GCInfoPaymentTitle' defaultMessage='4. Informations de paiement'/>
        </PageTitle>
        <Card>
            <PaymentForm
                clientSecret={clientSecret}
                publicKey={publicKey}
                labelButton={intl.formatMessage({
                    id: 'giftCardConfirmationPaymentButtonLabel',
                    defaultMessage: 'Payer le bon cadeau - {amount}'
                }, {
                    amount: intl.formatNumber(amount || 0, {
                        style: 'currency',
                        currency
                    })
                })}
                redirectUrl={`${window.location.origin}${generatePath(routeGiftCardPaymentValidation, {giftCardId, codeBouton, locale})}`}/>
        </Card>
    </InOut>
}