import { FC, useEffect, useState } from "react";
import { PageTemplate } from "../../common/components/page-template";
import { useConfigurationStore } from "../../configuration/configuration-store";
import { useAuthStore } from "../../customer/authentification/auth-store";
import { generatePath, useParams } from "react-router-dom";
import { PurchasedGiftCard } from "../gift-card.types";
import { useGiftCardStore } from "../gift-card-store";
import { Page404 } from "../../common/pages/404-page";
import { Card, Container, Flex, PageTitle, SpanBody1, SpanBody2 } from "@linkeo.com/ui-lib-react";
import { PageTitleWithCircleCount } from "../../common/components/page-title-with-circle-count";
import { FormattedMessage, useIntl } from "react-intl";
import { LinkPrimary } from "../../common/components/link-primary";
import { routeGiftCard, routeGiftCardDisplay } from "../../routes";
import { RowCard } from "../../common/components/row-card";
import { Separation } from "../../common/components/separation";
import { FormatTime } from "../../common/components/format-time";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";

export const GiftcardSummaryPage: FC = () => {
    const { giftCardId } = useParams<{ giftCardId: string }>();
    const codeBouton = useCodeBouton();
    const config = useConfigurationStore();
    const intl = useIntl();
    const { authData } = useAuthStore();
    const { fetchGiftCard } = useGiftCardStore();
    const [purchasedGiftCard, setPurchasedGiftCard] = useState<PurchasedGiftCard | null>(null);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (giftCardId) {
            fetchGiftCard(giftCardId)
                .then((result) => setPurchasedGiftCard(result))
                .catch(e => {
                    console.error(e)
                    setError(true)
                });
        }
    }, [giftCardId, fetchGiftCard])

    if (!authData) {
        return null
    }

    return !error ? <PageTemplate configuration={config} authData={authData} isLoading={!purchasedGiftCard} codeBouton={codeBouton}>
        <Container size={'lg'}>
            <div style={{ textAlign: 'center', marginBottom: '32px' }}>
                <SpanBody2>
                    <FormattedMessage id={'giftcardSummaryPageTitle1'} defaultMessage={'Merci pour votre achat !'} />
                </SpanBody2>
                <br />
                <SpanBody1>
                    <FormattedMessage id={'giftcardSummaryPageTitle2'} defaultMessage={'Nous sommes impatient de vous accueillir dans notre établissement'} />
                </SpanBody1>
            </div>
            <PageTitleWithCircleCount count={1} isGiftcard>
                <FormattedMessage id='BookingSynthesisTitle' defaultMessage='Synthèse de vos préstations' />
            </PageTitleWithCircleCount>
            <PageTitle>
                <FormattedMessage id={'giftcardListingCardTitle'} defaultMessage={'Bon cadeau'} />
            </PageTitle>
            {purchasedGiftCard && <Card style={{ padding: 24, marginBottom: '24px' }}>
                <RowCard
                    caption={<><FormattedMessage id='durationServiceLabel' defaultMessage='Durée: ' /><FormatTime
                        minutes={purchasedGiftCard.service.duration} /></>}
                    icon={'pricetags-fill'}
                    titleName={purchasedGiftCard.service.title} />
                <Separation />
                <Flex justifyContent={['center']}>
                <LinkPrimary to={generatePath(routeGiftCardDisplay, {giftCardId, codeBouton, locale:intl.locale})}>
                    <FormattedMessage id={'giftCardSummaryPageLinkDisplayLabel'} defaultMessage={'Afficher le bon cadeau'} />
                </LinkPrimary>
                </Flex>
            </Card>}
            <LinkPrimary to={routeGiftCard} icon={'plus-circle-fill'}>
                <FormattedMessage id={'GiftcardSummaryAddButtonLabel'} defaultMessage={'Offrir une nouvelle prestation'} />
            </LinkPrimary>
        </Container>

    </PageTemplate> : <Page404 />
}