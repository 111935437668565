import React, {FC, ReactNode} from "react";
import {useTheme} from "styled-components";
import {Flex, H5, Svg} from "@linkeo.com/ui-lib-react";
import { FormattedMessage } from "react-intl";

export interface LoaderProps {
  children: ReactNode;
  loading: boolean;
}

export const Loader: FC<LoaderProps> = ({children, loading}: LoaderProps) => {
  const theme = useTheme();
  return loading ? <>
    <div style={{textAlign: 'center', paddingTop: '50px'}}>
      <Flex justifyContent={['center']}>
        <div>
          <img src={"/img/loading.svg"} alt={"loading"}/>
          <div style={{position: 'absolute', top: "calc(50% - 10px)", left: "calc(50% - 50px)"}}>
            <Svg width={100} height={100} animatedIcon={"spinner"} stroke={theme.colors.grey60}/>
          </div>
        </div>
      </Flex>
      <H5>
        <FormattedMessage id="loaderTitle" defaultMessage='Chargement en cours' />
      </H5>
    </div>
  </> : <>{children}</>;
}
