import {FC} from 'react';
import {Svg} from '@linkeo.com/ui-lib-react';
import {useTheme} from 'styled-components';
import {IconSvgProps} from '@linkeo.com/ui-lib-react/lib/components/svg/svg';

export const SmallSvg: FC<IconSvgProps> = ({icon, ...props}) => {
  const theme = useTheme();

  return <Svg {...props} fill={theme.colors.grey30} icon={icon} width={20}
              height={20}/>
}