import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {PageTemplate} from "../../common/components/page-template"
import {routeSummary} from "../../routes"
import {generatePath, useNavigate} from "react-router";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {useIntl} from "react-intl";

export const BookingPaymentValidation = () => {

    const [paymentParams, setPaymentParams] = useState<{
        payment_intent: string | null,
        payment_intent_client_secret: string | null
    }>()
    const navigate = useNavigate();
    const configuration = useConfigurationStore();
    const intl = useIntl();
    const codeBouton = useCodeBouton();
    useEffect(() => {
        const search = new URLSearchParams(window.location.href)
        const payment_intent = search.get('payment_intent')
        const payment_intent_client_secret = search.get('payment_intent_client_secret')
        setPaymentParams({payment_intent, payment_intent_client_secret})
    }, [])

    const {bookingId} = useParams<{ bookingId: string }>()

    useEffect(() => {
        if (!bookingId && !paymentParams) {
            return;
        }
        navigate(generatePath(routeSummary, {bookingId, locale: intl.locale, codeBouton}));

    }, [bookingId, paymentParams, navigate, codeBouton, intl.locale])

    return <PageTemplate configuration={configuration} isLoading={true} codeBouton={codeBouton}/>
}
