import {
    Button,
    Card,
    Col,
    Container,
    Flex,
    InOut,
    PageTitle,
    Row,
    SpanBody2,
    SpanSubtitle1,
    Svg
} from "@linkeo.com/ui-lib-react"
import {FC} from "react"
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl"
import {PaymentForm} from "../../payment-stripe/payment-form"
import {InputsGiftCards} from "./inputs-giftcards"
import {useTheme} from 'styled-components';
import {generatePath} from "react-router"
import {routeBookingValidation} from "../../routes"
import {Total, TotalDetailItem} from "../../common/commons.types";
import {GiftCardStatus} from "./booking-types";
import {BookingDetailsSection} from "./booking-details-section";

interface giftCardCodesStatus {
    code: string;
    total?: Total;
    status?: GiftCardStatus;
}

interface Stripe {
    clientSecret: string,
    total: Total
}

interface PaymentOptionsProps {
    isPayment: boolean,
    loader: boolean,
    onPaymentClick: () => void,
    stripePrice?: Stripe,
    toPay?: number,
    currency?: string;
    count: number,
    bookingId: string;
    publicKey: string;
    codeBouton: string;
    total: number;
}

const PaymentOptions: FC<PaymentOptionsProps> = ({
                                                     isPayment,
                                                     loader,
                                                     onPaymentClick,
                                                     stripePrice,
                                                     toPay,
                                                     currency,
                                                     count,
                                                     bookingId,
                                                     publicKey,
                                                     codeBouton,
                                                     total
                                                 }) => {
    const intl = useIntl();

    let buttonLabel: string
    if (!stripePrice) {
        buttonLabel = intl.formatMessage({id: 'ConfirmationLabel', defaultMessage: 'Confirmer'})
    } else if (toPay === total) {
        buttonLabel = intl.formatMessage({
            id: 'PaymentButtonLabel',
            defaultMessage: 'Payer - {price}'
        }, {
            price: intl.formatNumber(toPay, {
                style: 'currency',
                currency: currency
            })
        })
    } else if (toPay && toPay <= 0) {
        buttonLabel = intl.formatMessage({id: 'NextLabel', defaultMessage: 'Suivant'})
    } else {
        buttonLabel = intl.formatMessage({
            id: 'OnlinePaymentLabel',
            defaultMessage: 'Payer l’accompte - {price}'
        }, {
            price: intl.formatNumber(stripePrice.total.toPay, {
                style: 'currency',
                currency: stripePrice.total.currency
            })
        })
    }

    if (isPayment && stripePrice) {
        return <PaymentForm
            clientSecret={stripePrice.clientSecret}
            publicKey={publicKey}
            labelButton={intl.formatMessage({
                id: 'PaymentFormLabel',
                defaultMessage: 'Payer {count,plural, =1 {ma prestation} other {mes prestations}} - {price}'
            }, {
                count: count,
                price: intl.formatNumber(stripePrice.total.toPay, {
                    style: 'currency',
                    currency: stripePrice.total.currency
                })
            })}
            redirectUrl={`${window.location.origin}${generatePath(routeBookingValidation, {bookingId,codeBouton,locale:intl.locale})}`}/>
    } else {
        return <Flex justifyContent={'center'}>
            <Button
                loading={loader}
                onClick={onPaymentClick}
                colorType={'primary'}>
                {buttonLabel}
            </Button>
        </Flex>
    }
}

interface BookPaymentCardProps {
    bookingId: string;
    count: number;
    total: number;
    isPayment: boolean;
    onPaymentClick: () => void;
    loader: boolean;
    isLoading: boolean;
    publicKey: string;
    stripePrice?: Stripe;
    giftCards: giftCardCodesStatus[];
    loading: boolean;
    onCheckingGCValidity: (value: string) => void;
    onRemovingCode: (value: giftCardCodesStatus) => void;
    codeBouton: string;
    currency: string;
    details?: TotalDetailItem[];
    onsitePayment?: number;
    paymentActive: boolean
}

export const BookPaymentCard: FC<BookPaymentCardProps> = ({
                                                              bookingId,
                                                              count,
                                                              stripePrice,
                                                              isPayment,
                                                              onPaymentClick,
                                                              loader,
                                                              publicKey,
                                                              isLoading,
                                                              giftCards,
                                                              loading,
                                                              onRemovingCode,
                                                              onCheckingGCValidity,
                                                              codeBouton,
                                                              currency,
                                                              details,
                                                              onsitePayment,
                                                              total,
                                                              paymentActive
                                                          }) => {

    const intl = useIntl();
    const theme = useTheme();

    return <Container size={"lg"}>
        <PageTitle>
            <FormattedMessage id='InfoPaymentTitle' defaultMessage='5. Informations de paiement'/>
        </PageTitle>
        <Card>
            {bookingId && !isLoading ? <>
                {stripePrice || giftCards.length > 0 ? <InputsGiftCards
                    readOnly={isPayment}
                    loading={loading}
                    onCheckingGCValidity={onCheckingGCValidity}
                    onRemovingCode={onRemovingCode}
                    giftCards={giftCards}
                /> : null}
                {details && <BookingDetailsSection details={details} currency={currency} total={total}
                                                   giftCards={giftCards}
                                                   paymentActive={paymentActive}
                                                   deposit={stripePrice?.total.toPay}/>}
                {stripePrice ?
                    <Row justifyContent={'space-between'}>
                        <Col>
                            <SpanSubtitle1>
                                <FormattedMessage id='onlineDepositSubtitle'
                                                  defaultMessage='Des acomptes sont appliqués à chaque prestation '
                                />
                            </SpanSubtitle1>
                        </Col>
                        <Col>
                            <SpanBody2>
                                <FormattedMessage id='onlineDepositPriceTitle'
                                                  defaultMessage='Acompte : {price}' values={{
                                    price: intl.formatNumber(stripePrice.total.toPay, {
                                        style: 'currency',
                                        currency: stripePrice.total.currency
                                    })
                                }}/>
                            </SpanBody2>
                        </Col>
                    </Row> : null}
                <Row justifyContent={'flex-end'} style={{marginBottom: '24px'}}>
                    {onsitePayment && <Col>
                        <SpanSubtitle1>
                            <FormattedMessage id={"bookPaymentCardRemaining"}
                                              defaultMessage={"Reste à payer sur place : {value}"}
                                              values={{
                                                  value: <FormattedNumber value={onsitePayment}
                                                                          currency={currency}
                                                                          style={"currency"}/>
                                              }}/>
                        </SpanSubtitle1>
                    </Col>}
                </Row>
                <InOut show={true} autoScroll startTriggering>
                    <PaymentOptions
                        isPayment={isPayment}
                        loader={loader}
                        onPaymentClick={onPaymentClick}
                        count={count}
                        bookingId={bookingId}
                        publicKey={publicKey}
                        stripePrice={stripePrice}
                        currency={stripePrice?.total.currency}
                        toPay={stripePrice?.total.toPay}
                        codeBouton={codeBouton}
                        total={total}
                    />
                </InOut>
            </> : <div style={{textAlign: 'center'}}><Svg animatedIcon={"spinner"}
                                                          stroke={theme.colors.grey30}/></div>}
        </Card>
    </Container>
}
