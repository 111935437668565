import styled from "styled-components";
import React, {FC, ReactNode, useState} from "react";
import {
    BaseButton,
    Button,
    Card,
    Col,
    Container,
    Flex,
    H3,
    IconName,
    PageTitle,
    Row,
    SpanBody2
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {SmallSvg} from "../../../common/components/small-svg";
import {CircledSvg} from "../../../common/components/circled-svg";

const ButtonWrapper = styled.div`
    border: 1px solid rgb(65, 77, 88);
    padding: 10px;
    display: inline-block;
    border-radius: 4px;
    vertical-align: middle;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: rgb(65, 77, 88);
    font-size: 14px;

    &:hover {

    }
`
const BackButton: FC<{ children: ReactNode }> = ({children}) => {
    return <ButtonWrapper>
        <div className="wrapper" style={{display: 'flex', justifyContent: "center"}}>
            <SmallSvg icon={'arrow-ios-back-outline'} style={{margin: '0 5px 0 0'}}/>{children}
        </div>
    </ButtonWrapper>
}

interface AccountPageProps {
    pageTitle: string,
    onLogoutClick: () => void,
    children: ReactNode
    showBackLink?: boolean
    backLinkUrl: string
}

const SimpleAccountPageWrapper: FC<AccountPageProps> = ({
                                                            pageTitle,
                                                            onLogoutClick,
                                                            children,
                                                            showBackLink,
                                                            backLinkUrl
                                                        }) => {

    const backLink = showBackLink === false ? null : <div style={{margin: '20px 0'}}>
        <Link to={backLinkUrl}><BackButton><FormattedMessage id={"accountGiftcardsBack"}
                                                             defaultMessage={'Retour'}/></BackButton></Link>
    </div>;
    return <Container size={'lg'}>
        {backLink}
        <Flex justifyContent={["space-between"]}>
            <PageTitle>{pageTitle}</PageTitle>
            <BaseButton style={{marginBottom: '15px'}}><SpanBody2
                style={{textDecorationLine: 'underline'}}
                onClick={onLogoutClick}
            ><FormattedMessage id='accountPageLogoutText' defaultMessage='Déconnexion'/></SpanBody2></BaseButton>
        </Flex>
        {children}
    </Container>
}

interface AccountPageWrapperProps {
    pageTitle: string;
    children: ReactNode;
    showBackLink?: boolean;
    onLogoutClick: () => void,
    backLinkUrl: string
}

export const AccountPageWrapper: FC<AccountPageWrapperProps> = ({
                                                                    children,
                                                                    pageTitle,
                                                                    showBackLink,
                                                                    onLogoutClick,
                                                                    backLinkUrl
                                                                }) => {

    return <SimpleAccountPageWrapper backLinkUrl={backLinkUrl} pageTitle={pageTitle} onLogoutClick={onLogoutClick}
                                     showBackLink={showBackLink}>
        {children}
    </SimpleAccountPageWrapper>
};
export const LoadMore: FC<{ total: number, count: number, loadMore: () => Promise<void>, children: ReactNode }> = ({
                                                                                                                       count,
                                                                                                                       total,
                                                                                                                       loadMore,
                                                                                                                       children
                                                                                                                   }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const doLoadMore = () => {
        setLoading(true);
        loadMore().finally(() => setLoading(false));
    }
    return <>
        <div className='content-wrapper'>
            {children}
        </div>
        <div className="button-wrapper" style={{display: "flex", justifyContent: "center"}}>
            <Button disabled={count >= total} loading={loading} onClick={() => doLoadMore()}
                    colorType={'outlineSecondary'}><FormattedMessage id={'accountpageLoadMoreItems'}
                                                                     defaultMessage={'Charger plus'}/> </Button>
        </div>
    </>
}

interface AccountCardProps {
    icon: IconName,
    titleName: string
}

const IconCol = styled(Col)`
    @media screen and (max-width: 650px) {
        display: none;
    }
`;
export const AccountCard: FC<AccountCardProps> = ({icon, titleName, children}) => {
    return <Card style={{marginBottom: '24px'}}>
        <Row alignItems={['flex-start']}>
            <IconCol columns={1.2}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}><CircledSvg icon={icon}/></div>
            </IconCol>
            <Col columns={10.8}>
                <H3 style={{marginBottom: 0}}>{titleName}</H3>
                {children}

            </Col>
        </Row>
    </Card>

};
