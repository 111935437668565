import {InOut, LoadingContent} from "@linkeo.com/ui-lib-react";
import React, {FC} from "react";
import {PageFooter, PageFooterProps} from "./footer";
import {PageHeader, PageHeaderProps} from "./header";

import {NavigationProps} from "./navigation/navigation-large";
import {PageNavigation} from "./navigation";
import styled from "styled-components";

interface PageProps {
    header: PageHeaderProps,
    navigation: NavigationProps,
    isLoading?: boolean;
    children: React.ReactNode
    footer: PageFooterProps
}
const Main = styled.main`
    min-height: calc(100vh - 390px);
`
export const Page: FC<PageProps> = ({
                                                                 header,
                                                                 navigation,
                                                                 footer,
                                                                 children,
                                                                 isLoading,


                                                             }) => {

    return <>
        <PageHeader {...header} />
        <LoadingContent isLoading={!!isLoading}/>
        <InOut show={!isLoading} keepContent>
            <PageNavigation {...navigation}/>
            <Main>{children}</Main>
            <PageFooter {...footer}/>
        </InOut>
    </>
        ;
};