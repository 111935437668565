import React, {FC} from "react";
import {Route, Routes} from "react-router-dom";
import {
    routeAppointment,
    routeBookingValidation,
    routeConfirmation,
    routeDateSelection,
    routeDetails,
    routeSummary
} from "../../routes";
import {ServiceSelectionPage} from "./service-selection-page";
import {DateSelectionPage} from "./date-selection-page";
import {BookingConfirmationPage} from "./booking-confirmation-page";
import {BookingDetailPage} from "./booking-detail-page";
import {BookingPaymentValidation} from "./booking-payment-validation";
import {BookingSummaryPage} from "./booking-summary-page";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {AuthenticationPage} from "../../customer/authentification/pages/authentication-page";
import {ServicesStore} from "../../service/services-store";
import {useCodeBouton} from "../../common/providers/code-bouton-provider";
import {RegisterPhonePage} from "../../customer/authentification/pages/register-phone-page";

const getLocalPath = (fullPath: string): string => {
    const re = new RegExp(`^${routeAppointment}`);
    return fullPath.replace(re, '');
}

export const IndexPage: FC = () => {
    const {authData} = useAuthStore();
    const config = useConfigurationStore();
    const codeBouton = useCodeBouton();
    const {appointment: {allowAnonymous}} = useConfigurationStore();

    // Si le numero de telephone s'il n'existe pas
    if (authData && (!authData.phone || !authData.birthDate)) {
        return <RegisterPhonePage/>
    }

    if (!allowAnonymous && !authData) {
        return <AuthenticationPage/>
    }
    return <ServicesStore codeBouton={codeBouton} token={authData?.accessToken.token} config={config}>
        <Routes>
            <Route path={getLocalPath(routeAppointment)} element={<ServiceSelectionPage/>}/>
            <Route path={getLocalPath(routeDateSelection)} element={<DateSelectionPage/>}/>
            <Route path={getLocalPath(routeConfirmation)} element={<BookingConfirmationPage/>}/>
            <Route path={getLocalPath(routeDetails)} element={<BookingDetailPage/>}/>
            <Route path={getLocalPath(routeBookingValidation)} element={<BookingPaymentValidation/>}/>
            <Route path={getLocalPath(routeSummary)} element={<BookingSummaryPage/>}/>
        </Routes>
    </ServicesStore>
}
