import {AuthCustomerRequest, CustomerRegister, CustomerRegisterAdditionalInformation} from './authentification.types';
import {ErrorsBuilder} from '../../common/errors-builder';

export const GetErrorsRegisterForm = (customer: CustomerRegister): ErrorsBuilder<CustomerRegister> => {
  const errors = new ErrorsBuilder<CustomerRegister>();
  if (!customer.email) {
    errors.appendError('email')
  }
  if (!customer.firstName) {
    errors.appendError('firstName')
  }
  if (!customer.lastName) {
    errors.appendError('lastName')
  }
  if (!customer.phone) {
    errors.appendError('phone')
  }
  if (!customer.password) {
    errors.appendError('password')
  }
  return errors
}
export const GetErrorsLoginForm = (customer: AuthCustomerRequest): ErrorsBuilder<AuthCustomerRequest> => {
  const errors = new ErrorsBuilder<AuthCustomerRequest>();
  if (!customer.email) {
    errors.appendError('email')
  }
  if (!customer.password) {
    errors.appendError('password')
  }
  return errors;
}
export const GetErrorsRegisterAdditionalInformationForm = (customer: CustomerRegisterAdditionalInformation): ErrorsBuilder<CustomerRegisterAdditionalInformation> => {
  const errors = new ErrorsBuilder<CustomerRegisterAdditionalInformation>();
  if(!customer.birthDate) {
    errors.appendError('birthDate')
  }
  if (!customer.phone) {
    errors.appendError('phone')
  }
  return errors;
}
