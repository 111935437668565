import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { Col, Row } from '@linkeo.com/ui-lib-react';

const Wrapper = styled.div`
  padding: 30px;
  background: white;
  margin: 30px 0;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
`;
const Anim = keyframes`
  from{
    background-position-x: 0;
  }
  to{
    background-position-x: 100%;
  }
`;
const Load = styled.div`
 
  position: relative;
  width: 100%;
  height: 18px;
  &:after{
  background: ${({ theme }) =>
        `linear-gradient(90deg, ${theme.colors.grey95} 30%, ${theme.colors.grey90} 40%, ${theme.colors.grey80} 50%, ${theme.colors.grey90} 60%, ${theme.colors.grey95} 70%) repeat`
    };
  background-size: 400%;
  background-position-x: 0;
  background-position-y: center;
  animation: ${Anim} ease-in-out infinite 1500ms reverse;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
    border-radius: 4px;
  }
`;
export const CardLoading: FC = () => {
    return <Wrapper>
        <Row>
            <Col columns={[1.5]}>
                <Load />
            </Col>
            <Col columns={[4]}>
                <Load />
            </Col>
            <Col columns={[3]}>
                <Load />
            </Col>
            <Col columns={[1]}>
                <Load />
            </Col>
        </Row>
        <Row style={{marginTop: '24px'}}>
            <Col columns={[1.5]}>
            </Col>
            <Col columns={[2]}>
                <Load />
            </Col>
            <Col columns={[2]}>
                <Load />
            </Col>
            <Col columns={[3]}>
                <Load />
            </Col>
            <Col columns={[1]}>
                <Load />
            </Col>
        </Row>
    </Wrapper>
};