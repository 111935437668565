import {PageTemplate} from "../components/page-template";
import {FormattedMessage, useIntl} from "react-intl";
import {Container, H2, H3} from "@linkeo.com/ui-lib-react";
import {useParams} from "react-router-dom";
import {useConfigurationStore} from "../../configuration/configuration-store";
import {useCodeBouton} from "../providers/code-bouton-provider";
import {useAuthStore} from "../../customer/authentification/auth-store";
import {ReactNode} from "react";
import {plannerFrontOfficeRootUri} from "../config/uri-api";

export const GlobalLegalNoticePage = () => {
    const params = useParams();
    const codeBouton = params['codeBouton'] || '';
    const locale = params['locale'];
    const intl = useIntl();
    return <PageTemplate codeBouton={codeBouton} configuration={{
        showHeader: true, website: 'https://planner-by-linkeo.com', address: {
            address: [intl.formatMessage({id: 'legalNoticeLinkeoAddress'})],
            city: intl.formatMessage({id: 'legalNoticeLinkeoCity'}),
            country: intl.formatMessage({id: 'legalNoticeLinkeoCountry'}),
            postalCode: intl.formatMessage({id: 'legalNoticeLinkeoPostalCode'})
        },
        name: intl.formatMessage({id: 'legalNoticeLinkeoName'}),
        phone: intl.formatMessage({id: 'legalNoticeLinkeoPhone'}),
    }} showNavigation={false}>
        <Container size={'lg'}>
            <H2><FormattedMessage id={"legalNoticePageTitle"}/></H2>
            <p>{locale}</p>
            <p>{codeBouton}</p>
        </Container>
    </PageTemplate>
}

export const CustomerLegalNoticePage = () => {
    const config = useConfigurationStore();
    const codeBouton = useCodeBouton();
    const authData = useAuthStore()
    const intl = useIntl();
    return <PageTemplate codeBouton={codeBouton} configuration={config} showNavigation={true}
                         authData={authData.authData || undefined}>
        <Container size={'lg'}>
            <FormattedMessage id={"legalNotice"} values={{
                h2: (chunks: ReactNode) => <H2>{chunks}</H2>,
                h3: (chunks: ReactNode) => <H3>{chunks}</H3>,
                p: (chunks: ReactNode) => <p>{chunks}</p>,
                dt: (chunks: ReactNode) => <dt>{chunks}</dt>,
                dd: (chunks: ReactNode) => <dd>{chunks}</dd>,
                dl: (chunks: ReactNode) => <dl>{chunks}</dl>,
                currentDate: intl.formatDate(new Date(),{year:"numeric"}),
                customerCompanyName:'Raison sociale',
                customerCapital:intl.formatNumber(1000,{style:'currency',currency:'EUR'}),
                customerCityRCS:'Paris',
                customerRCS:'12345TFG',
                customerAddress:'12 rue de la paix',
                customerPhone:"06 66 66 66 66",
                customerMail:'login@provider.tld',
                customerVAT:'FR12345678901',
                customerName:"Daniel Guichard",
                plannerCustomerUri:`${plannerFrontOfficeRootUri}/${codeBouton}/${intl.locale}`

            }}/>
        </Container>
    </PageTemplate>
}
