import { Container, Flex, Svg, SpanSubtitle2 } from "@linkeo.com/ui-lib-react";
import { FC } from "react";
import { useTheme } from 'styled-components';

interface ServiceSelectionSectionProps {
    title: string
}

export const ServiceSelectionSection: FC<ServiceSelectionSectionProps> = ({ children, title }) => {

    const theme = useTheme();

    return <Container size={"lg"}>
        <Flex alignItems={['center']} style={{ marginBottom: "15px" }} wraps={['nowrap']}>
            <Svg icon={"toggle-left-outline"} fill={theme.colors.grey30} width={22} height={22}
                style={{ marginRight: '5px' }} />
            <SpanSubtitle2>
                {title}
            </SpanSubtitle2>
        </Flex>
        {children}
    </Container>
}