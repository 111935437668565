import React, {FC, useEffect, useMemo, useState} from 'react';
import {useTheme} from 'styled-components';
import {CustomerAuth} from "../../customer/authentification/authentification.types";
import {Configuration} from "../../configuration/configuration.types";
import {
    routeAppointment,
    routeBookingIndex,
    routeCustomerLegalNotice,
    routeCustomerTermsOfSales,
    routeGiftCard,
    routeGiftCardIndex,
    routeGlobalLegalNotice,
    routeUserAccount
} from "../../routes";
import {matchPath} from "react-router-dom";
import {FormattedDate, FormattedMessage, useIntl} from 'react-intl';
import {generatePath, useLocation} from "react-router";
import {imageExists as checkImageExists} from "../misc-util";
import {Page} from "./page/page";

import {NavItem} from "./page/navigation/navigation-large";
import {TermsOfSalesProps} from "./page/footer";


interface PageTemplateProps {
    isLoading?: boolean;
    authData?: CustomerAuth;
    configuration?: Partial<Configuration>
    codeBouton?: string
    showNavigation?: boolean
}


export const PageTemplate: FC<PageTemplateProps> = ({
                                                        children,
                                                        configuration,
                                                        authData,
                                                        codeBouton,
                                                        showNavigation = true
                                                    }) => {
    const theme = useTheme();
    const giftCardsEnabled = configuration?.giftcard?.active || false;
    const address = configuration?.address || {address: [''], city: '', postalCode: '', country: ''};
    const coverImageUri = configuration?.coverImageFile?.url || '';
    const logoUri = configuration?.logoFile?.url || '';
    const name = configuration?.name || '';
    const website = configuration?.website || '';
    const phone = configuration?.phone || '';
    const showHeader = configuration?.showHeader || false;
    const location = useLocation();
    const intl = useIntl();
    const [imageExists, setImageExists] = useState<boolean>(false);
    let termsOfSales: TermsOfSalesProps | undefined;
    if (codeBouton) {
        termsOfSales = {
            link: generatePath(routeCustomerTermsOfSales, {codeBouton, locale: intl.locale}),
            label: <FormattedMessage id="termsOfSalesLinkLabel" defaultMessage="Conditions générales de vente"/>,
            title: intl.formatMessage({id: "termsOfSalesLinkTitle"})
        }
    }
    const navItems = useMemo((): NavItem[] => {
        const items: NavItem[] = [];
        if (!codeBouton) {
            return items;
        }
        items.push({
            path: generatePath(routeAppointment, {codeBouton, locale: intl.locale}),
            label: intl.formatMessage({id: "navAppointmentPageTitle", defaultMessage: "Prendre rdv"}),
            isActive: !!matchPath(routeBookingIndex, location.pathname)
        });
        if (giftCardsEnabled) {
            items.push({
                path: generatePath(routeGiftCard, {codeBouton, locale: intl.locale}),
                label: intl.formatMessage({id: "navGiftCardPageTitle", defaultMessage: "Offrir"}),
                isActive: !!matchPath(routeGiftCardIndex, location.pathname)
            });
        }
        return items;
    }, [giftCardsEnabled, location.pathname, codeBouton, intl]);
    useEffect(() => {
        if (logoUri) {
            checkImageExists(logoUri).then(exists => setImageExists(exists))
        }
        return () => setImageExists(false);
    }, [logoUri])

    return <Page
        header={{
            show: showHeader,
            address: address,
            coverImageUri,
            logo: {logoUri: (imageExists && logoUri) || '/img/no-logo.jpg'},
            mainTitle: name,
            websiteUri: website,
            phone,
            theme
        }}
        footer={{
            privacyPolicy: {
                link: intl.formatMessage({id: "privacyPolicyLinkUrl"}),
                label: <FormattedMessage id="privacyPolicyLinkLabel" defaultMessage="Politique de confidentialité"/>,
                title: intl.formatMessage({id: "privacyPolicyLinkTitle"})
            },
            copyright: {
                linkeoCopyrightLink: {
                    websiteUrl: intl.formatMessage({id: "linkeoCopyrightUrl"}),
                    label: intl.formatMessage({id: "linkeoCopyrightLabel", defaultMessage: "Linkeo"}),
                    title: intl.formatMessage({
                        id: "linkeoCopyrightTitle",
                        defaultMessage: "Linkeo, Leader en création de sites web, référencement local et solutions métiers"
                    })
                },
                year: <FormattedDate value={new Date()} year="numeric"/>
            },
            poweredBy: {
                plannerWebsiteLinkTitle: intl.formatMessage({id: "poweredByPlannerLinkLabel"}),
                plannerWebsiteUrl: intl.formatMessage({id: "poweredByPlannerLinkUrl"}),
                poweredByLabel: <FormattedMessage id="poweredByLabel" defaultMessage="Powered by"/>
            },
            legalNotice: {
                link: codeBouton ? generatePath(routeCustomerLegalNotice, {
                    codeBouton,
                    locale: intl.locale
                }) : routeGlobalLegalNotice,
                label: <FormattedMessage id="legalNoticeLinkLabel" defaultMessage="Mentions légales"/>,
                title: intl.formatMessage({id: "legalNoticeLinkTitle"})
            },
            termsOfSales
        }}
        navigation={{
            show: showNavigation,
            accountUrl: generatePath(routeUserAccount, {codeBouton, locale: intl.locale}),
            authButtonTitle: intl.formatMessage({id: "navAccountButtonTitle", defaultMessage: "Mon compte"}),
            navItems: navItems,
            authData
        }}
    >{children}</Page>
        ;
};
