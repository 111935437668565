import { Svg } from "@linkeo.com/ui-lib-react";
import { FC } from "react"
import styled, { useTheme } from "styled-components";

export interface Address {
    address: [
        string
    ],
    postalCode: string,
    city: string,
    country: string
}

export interface DisplayAddressProps {
    value: Address
}

const SpanAddress = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({theme}) => theme.colors.grey30};
`;

export const DisplayAddress: FC<DisplayAddressProps> = ({value}) => {
    const theme = useTheme()

    return <>
        <Svg style={{margin: '0 5px -1px 10px'}} fill={theme.colors.grey10} icon={'pin-fill'} width={14}
    height={14}></Svg>
    <SpanAddress>{value.address[0]}&nbsp;{value.postalCode}&nbsp;{value.city}</SpanAddress>
    </>
}