import {FC} from 'react';
import {Card, CardTitle, Container, PageTitle} from '@linkeo.com/ui-lib-react';
import { FormattedMessage } from 'react-intl';
import { useConfigurationStore } from '../../../configuration/configuration-store';
import { PageTemplate } from '../../../common/components/page-template';
import { RegisterPhoneForm } from '../components/register-phone-form';
import {useCodeBouton} from "../../../common/providers/code-bouton-provider";

export const RegisterPhonePage: FC = () => {

  const config = useConfigurationStore();
  const codeBouton = useCodeBouton();
  return <PageTemplate configuration={config} codeBouton={codeBouton}>
    <Container size={"lg"}>
      <PageTitle>
        <FormattedMessage id='resetPasswordPageTitle' defaultMessage='Mon compte'/>
      </PageTitle>
      <Card>
        <CardTitle style={{textAlign: 'center'}}>
          <FormattedMessage id='registerAdditionalInformationPageCardTitlle' defaultMessage='Information complémentaire'/>
        </CardTitle>
        <RegisterPhoneForm/>
      </Card>
    </Container>
  </PageTemplate>
}