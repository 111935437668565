import React from 'react';

import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ThemeProvider, ToasterProvider} from '@linkeo.com/ui-lib-react';
import {Theme} from './common/config/theme';
import {Router} from "./router";
import {ErrorBoundary} from "./common/error-boundary";
import {IntlProvider} from "./common/providers/intl.provider";

export const storageCodeBouton = 'codeBouton';

function App() {

    return (
        <BrowserRouter>
            <ThemeProvider theme={Theme}>
                <IntlProvider>
                    <ErrorBoundary>
                        <ToasterProvider>
                            <Routes>
                                <Route path={'/*'} element={<Router/>}/>
                            </Routes>
                        </ToasterProvider>
                    </ErrorBoundary>
                </IntlProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
