import React, { FC } from 'react';
import { Col, PageSubtitle, PageTitle, Row, ThemeType } from '@linkeo.com/ui-lib-react';
import { Circle } from './misc';
import { useTheme } from 'styled-components';

interface PageTitleWithCircleCountProps {
  count: number;
  isGiftcard?: boolean;
}

export const PageTitleWithCircleCount: FC<PageTitleWithCircleCountProps> = ({ count, isGiftcard, children }) => {
  const { colors } = useTheme() as ThemeType;

  return <Row alignItems={['center']} style={{ marginBottom: 24, marginTop: 15 }} gapColumn={[2, 3, 8]}>
    <Col>
      {isGiftcard
        ? <PageSubtitle style={{ margin: 0, color: colors.grey30 }} className={'giftcard_title'}>{children}</PageSubtitle>
        : <PageTitle style={{ margin: 0, color: colors.grey30 }} className={'booking_title'}>{children}</PageTitle>}
    </Col>
    <Col>
      <Circle>{count}</Circle>
    </Col>
  </Row>
}