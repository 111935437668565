import {Row, Col, SpanSubtitle1, SpanSubtitle2} from "@linkeo.com/ui-lib-react";
import {FC} from "react";
import {FormattedNumber} from "react-intl";
import {FormatTime} from "./format-time";
import {SmallSvg} from "./small-svg";
import {InfoPrice} from "./info-price";
import {useTheme} from "styled-components";

interface PriceAndTimeProps {
    duration: number,
    price: number,
    currency: string,
    discountPrice?: number
}

export const PriceAndTime: FC<PriceAndTimeProps> = ({children, duration, price, currency, discountPrice}) => {
    const {colors} = useTheme();
    return <>
        <Col>{children}</Col>
        <Col>
            <Row gapColumn={[4]} alignItems={['center']}>
                <Col style={{marginTop: 4}}>
                    <SmallSvg
                        icon={"clock-outline"}/>
                </Col>
                <Col>
                    <SpanSubtitle1><FormatTime minutes={duration}/></SpanSubtitle1>
                </Col>
                {price > 0 && <>
                    <Col style={{marginTop: 4, marginLeft: 4}}>
                        <InfoPrice currency={currency}/>
                    </Col>
                    <Col>
                        <SpanSubtitle2
                            style={{
                                color: discountPrice !== undefined ? colors.grey60 : colors.grey40,
                                textDecoration: discountPrice !== undefined ? 'line-through' : 'none'
                            }}>
                            <FormattedNumber value={price} style={`currency`} currency={currency}/>
                        </SpanSubtitle2>
                    </Col>
                    {discountPrice !== undefined && <Col>
                        <SpanSubtitle2 style={{color: colors.success}}>
                            <FormattedNumber value={discountPrice} style={`currency`} currency={currency}/>
                        </SpanSubtitle2>
                    </Col>}
                </>}
            </Row>
        </Col>
    </>
}
