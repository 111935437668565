import styled from 'styled-components';
import {getTypo} from '@linkeo.com/ui-lib-react';

export const Circle = styled.div`
  ${getTypo('subtitle2')};
  background: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.light};
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 100%;
  padding-top: 1px;
`;