import {FC, useEffect, useState} from 'react';
import {Button, Card, CardTitle, Col, Flex, InOut, Row, SpanBody2} from '@linkeo.com/ui-lib-react';
import {Separation} from '../../../common/components/separation';
import {RegisterForm} from './register-form';
import {LoginForm} from './login-form';
import {ForgotPasswordForm} from './forgot-password-form';
import {FormattedMessage} from 'react-intl';
import {customerApiUri} from '../../../common/config/uri-api';
import {useCodeBouton} from '../../../common/providers/code-bouton-provider';
import {useBookingStore} from '../../../booking/booking-store';
import {useLocale} from '../../../common/providers/intl.provider';
import {generatePath, useLocation} from 'react-router';
import {routeAuthCallback} from '../../../routes';
import {useTheme} from 'styled-components';
import {lighten} from 'polished';
import {ProviderButton} from '../../../common/components/provider-button';

export const AuthContainer: FC = () => {
  const codeBouton = useCodeBouton();
  const {locale} = useLocale()
  const {booking} = useBookingStore();
  const [getPartToShow, setPartToShow] = useState<'login' | 'register' | 'forgotPassword' | null>(null);
  const location = useLocation();
  const {colors} = useTheme();

  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const queryParams   = new URLSearchParams(window.location.search)
    const success       = queryParams.get('success')
    const error         = queryParams.get('error')

    if(success && error){
      setError(true)
      // Modifier l'URL en enlevant les paramètres en queryString
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  const handleLogin = (providerType: string) => {
    // Conserver les informations concernant la réservation en cours dans le sessionStorage
    if(booking.appointments.length > 0){
      sessionStorage.setItem(`storedBooking_${codeBouton}`, JSON.stringify(booking));
    }
    sessionStorage.setItem(`redirectUri_${codeBouton}`, location.pathname);

    const redirectUri = encodeURIComponent(`${window.location.origin}/${codeBouton}/${locale}${generatePath(routeAuthCallback,{codeBouton,locale})}`);
    window.location.href = `${customerApiUri}/${codeBouton}/customers/provider/${providerType}/auth?redirect_uri=${redirectUri}`;
  };
  
  return <>
    <Card>
      <CardTitle style={{textAlign: 'center'}}>
        <FormattedMessage id='authContainerTitleLogin' defaultMessage='Vous êtes déjà client'/>
      </CardTitle>
      <InOut show={getPartToShow !== 'login'} exit={{keyframes: []}}>
        <Flex justifyContent={['center']}>
          <Button className={'login_button'} colorType={'primary'} onClick={() => setPartToShow('login')}>
            <FormattedMessage id='authContainerButtonLogin'  defaultMessage='Me connecter'/>
          </Button>
        </Flex>
      </InOut>
      <InOut show={getPartToShow === 'login'} exit={{keyframes: []}} autoScroll={true} startTriggering>
        <LoginForm onForgotPasswordClick={() => setPartToShow('forgotPassword')}/>
      </InOut>
      <InOut show={getPartToShow === 'forgotPassword'} exit={{keyframes: []}} autoScroll={true} startTriggering>
        <ForgotPasswordForm/>
      </InOut>
      <Flex justifyContent={['center']} style={{ marginTop: "15px" }}>
        <ProviderButton
          provider='google' 
          handleClickLogin={handleLogin} 
          colorType={'primary'}
          color={colors.warning} 
        >
          <FormattedMessage id="authContainerButtonGoogleLogin"  defaultMessage="Se connecter avec google" />
        </ProviderButton>
      </Flex>
      <Flex justifyContent={['center']} style={{ marginTop: "15px" }}>
        <ProviderButton
          provider='facebook' 
          handleClickLogin={handleLogin} 
          colorType={'primary'}
        >
          <FormattedMessage id="authContainerButtonFacebookLogin"  defaultMessage="Se connecter avec facebook" />
        </ProviderButton>
      </Flex>
    </Card>
    {error ? (
      <Row justifyContent={['center']} gapColumn={[1]} style={{ marginTop: "25px", marginBottom: "25px" }}>
        <Col style={{
          backgroundColor: lighten(0.26, colors.danger),
          padding: '11px', 
          border: `2px solid ${colors.danger}`, 
          borderRadius: '6px' 
          }}>
          <div>
            <SpanBody2>
              <FormattedMessage 
                id='AuthenticationErrorMessage' 
                defaultMessage="Échec de l'authentification avec Google/Facebook. Veuillez vérifier vos informations de connexion et réessayer"
              />
            </SpanBody2>
          </div>
        </Col>
      </Row>
    ) : (
      <Separation>
        <FormattedMessage id='authContainerSeparationArg' defaultMessage='ou' />
      </Separation>
    )}
    <Card>
      <CardTitle style={{textAlign: 'center'}}>
        <FormattedMessage id='authContainerTitleRegister' defaultMessage='C’est votre premiere visite ?' />
      </CardTitle>
      <InOut show={getPartToShow !== 'register'} exit={{keyframes: []}}>
        <Flex justifyContent={['center']}>
          <Button id={'create_account_btn'} colorType={"outlineSecondary"} onClick={() => setPartToShow('register')}>
            <FormattedMessage id='authContainerButtonCreateAccount' defaultMessage='Créer un compte' />
          </Button>
        </Flex>
      </InOut>
      <InOut show={getPartToShow === 'register'} autoScroll={true} startTriggering exit={{keyframes: []}}>
        <RegisterForm/>
      </InOut>
      <Flex justifyContent={['center']} style={{ marginTop: "15px" }}>
        <ProviderButton
          provider='google' 
          handleClickLogin={handleLogin} 
          colorType={'outlineSecondary'}
        >
          <FormattedMessage id="authContainerButtonGoogleCreateAccount"  defaultMessage="Continuer avec google" />
        </ProviderButton>
      </Flex>
      <Flex justifyContent={['center']} style={{ marginTop: "15px" }}>
        <ProviderButton
          provider='facebook' 
          handleClickLogin={handleLogin} 
          colorType={'outlineSecondary'}
        >
          <FormattedMessage id="authContainerButtonFacebookCreateAccount"  defaultMessage="Continuer avec facebook" />
        </ProviderButton>
      </Flex>
    </Card>
  </>
}
