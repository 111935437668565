import React, {FC} from "react";
import {Col, Container, Flex, Row, Shapes, SpanLink, Svg} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";
import {Address, DisplayAddress} from "../display-address";
import {DisplayPhone} from "../display-phone-number";
import styled, {DefaultTheme} from "styled-components";

export const Header = styled.header`
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    background: ${({theme}) => theme.colors.light};
`;
export const ImageHeaderBackground = styled.div<{ uri?: string }>`
    background: ${({uri}) => uri ? `#A7B2BE url("${uri}") no-repeat center center` : "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, #A7B2BE, #A7B2BE)"};
    background-size: cover;
    height: max(80px, min(${2048 / 80}vw, 170px));
    overflow: visible;
`;
export const ImageHeaderLogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: calc(max(75px, min(20vw, 125px)) * -1);
`;
export const ImageHeaderLogo = styled.img`
    ${Shapes.shadow1};
    border-radius: 100%;
    width: max(100px, min(27vw, 150px));
    height: max(100px, min(27vw, 150px));
    object-fit: cover;
    margin: 0 auto;
`;
const H1 = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
    color: ${({theme}) => theme.colors.grey20};
`

interface LogoProps {
    logoUri: string
}

const Logo: FC<LogoProps> = ({logoUri}) => {
    return <ImageHeaderLogoWrapper>
        <ImageHeaderLogo src={logoUri || '/img/no-logo.jpg'} alt={"logo"}/>
    </ImageHeaderLogoWrapper>
}

export interface PageHeaderProps {
    show: boolean;
    address: Address;
    coverImageUri: string;
    logo: LogoProps;
    mainTitle: string;
    websiteUri: string;
    phone: string;
    theme: DefaultTheme;
}

export const PageHeader: FC<PageHeaderProps> = ({
                                                    show,
                                                    coverImageUri,
                                                    logo,
                                                    mainTitle,
                                                    websiteUri,
                                                    address,
                                                    phone,
                                                    theme
                                                }) => {
    return <>{(show && <Header>
        <ImageHeaderBackground uri={coverImageUri}/>
        <Logo {...logo} />
        <Container size={"lg"} style={{paddingTop: `10px`, paddingBottom: `10px`, textAlign: 'center'}}>
            <Row justifyContent={['center', 'center', 'space-between']} gapColumn={[4, 6, 12]}>
                <Col style={{marginBottom: "10px"}}>
                    <Flex alignItems={['center']}>
                        <H1>{mainTitle}</H1>
                        {websiteUri &&<><Svg style={{marginLeft: '10px'}} fill={theme.colors.grey60}
                                             icon={'external-link-outline'}
                                             width={16}
                                             height={16}></Svg><a href={websiteUri}
                                          target={'_blank'} rel="noreferrer"><SpanLink>
                            <FormattedMessage id='pageWrapperWebsiteLink' defaultMessage='Visiter notre site'/>
                        </SpanLink>
                        </a></>}
                    </Flex>
                </Col>
                <Col style={{marginTop: '15px'}}>
                    <DisplayAddress value={address}/>&#32;<DisplayPhone phone={phone}/>
                </Col>
            </Row>
        </Container>
    </Header>) || null}</>
}
